import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { VERSION, REPORTING_ENABLED } from "./config";

export function setupReporting() {
    if (REPORTING_ENABLED) {
        Sentry.init({
            dsn: "https://77b8f609e915465ea6146c670b87fb94@o1167385.ingest.sentry.io/6258394",
            integrations: [new BrowserTracing()],
            environment: process.env.NODE_ENV,
            release: `dashboard@${VERSION}`,
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
        });
    }
}
