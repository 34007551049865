export const Courses = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="21" height="26" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg" color="#2C2B5E" {...props}>
        <path
            d="M19.5027 0.166504H3.39006C1.68035 0.166504 0.333313 1.56535 0.333313 3.27506V21.9264C0.333313 23.6361 1.73216 25.0349 3.44187 25.0349H19.5027C19.8136 25.0349 20.0208 24.8277 20.0208 24.5168V0.684596C20.0208 0.373741 19.8136 0.166504 19.5027 0.166504ZM3.39006 1.20269H18.9846V5.29562H3.39006C2.25025 5.29562 1.31769 4.36305 1.31769 3.22325C1.3695 2.13525 2.30206 1.20269 3.39006 1.20269ZM1.3695 21.9264V5.55466C1.78397 5.91733 2.30206 6.17637 2.92377 6.27999V23.8951C1.99121 23.6879 1.3695 22.9107 1.3695 21.9264ZM3.90815 23.9987V6.3318H18.9846V23.9987H3.90815Z"
            fill="currentColor"
        />
        <path
            d="M15.5134 12.3934L12.7157 11.9789L11.4723 9.44025C11.3687 9.28482 11.2132 9.12939 11.006 9.12939C10.7988 9.12939 10.6433 9.23301 10.5397 9.44025L9.2963 11.9789L6.4986 12.3934C6.29136 12.4452 6.13593 12.5488 6.08413 12.756C6.03232 12.9633 6.08413 13.1705 6.23955 13.2741L8.26011 15.2429L7.79383 18.0406C7.74202 18.2478 7.84564 18.4551 8.00107 18.5587C8.15649 18.6623 8.36373 18.7141 8.57097 18.6105L11.1096 17.2634L13.6483 18.6105C13.7001 18.6623 13.8037 18.6623 13.9073 18.6623C14.0109 18.6623 14.1146 18.6105 14.2182 18.5587C14.3736 18.4551 14.4772 18.2478 14.4254 18.0406L13.9591 15.2429L15.9797 13.2741C16.1351 13.1187 16.1869 12.9115 16.1351 12.756C15.8761 12.5488 15.7206 12.3934 15.5134 12.3934ZM12.8711 14.673C12.7675 14.7766 12.7157 14.9838 12.7157 15.1393L13.0784 17.1598L11.2132 16.2273C11.0578 16.1236 10.9024 16.1236 10.747 16.2273L8.93363 17.2116L9.2963 15.1911C9.34811 15.0356 9.24449 14.8284 9.14087 14.7248L7.6384 13.2741L9.65896 12.9633C9.81439 12.9633 9.96982 12.8078 10.0734 12.7042L11.006 10.8391L11.9386 12.7042C11.9904 12.8597 12.1458 12.9633 12.353 12.9633L14.3736 13.2741L12.8711 14.673Z"
            fill="currentColor"
        />
    </svg>
);
