import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "../../utils/hooks";
import AccessDenied from "../AccessDenied";
import { CoursePage } from "../CoursePage/CoursePage";
import { EmbedPage } from "../EmbedPage/EmbedPage";
import Extension from "../Extension";
import { FeedPage } from "../FeedPage/FeedPage";
import FilteredListPage from "../FilteredListPage";
import { LandingPage } from "../LandingPage";
import { LibraryPage } from "../LibraryPage/LibraryPage";
import { JoinWaitinglistPage } from "../Login/JoinWaitinglistPage";
import { LoginPage } from "../Login/LoginPage";
import { RecoverPasswordPage } from "../Login/RecoverPasswordPage";
import { ResetPasswordPage } from "../Login/ResetPasswordPage";
import { SignUpPage } from "../Login/SignUpPage";
import { VerifyEmailPage } from "../Login/VerifyEmailPage";
import ManagePage from "../ManagePage";
import { MediaPage } from "../MediaPage/MediaPage";
import Policy from "../Policy";
import BetaPolicy from "../Policy/BetaPolicy";
import Publishers from "../Publishers";
import { QuizPage } from "../QuizPage/QuizPage";
import { ReviewPage } from "../ReviewPage/ReviewPage";
import SuggestionsPage from "../SuggestionsPage";
import { UserBadgesPage } from "../UserBadgesPage/UserBadgesPage";
import { UserCoursesPage } from "../UserCoursesPage/UserCoursesPage";
import {
    courseBlockPathFormat,
    coursePathFormat,
    embeddedQuizPagePathFormat,
    joinWaitingListPathFormat,
    landingPagePathFormat,
    libraryPagePathFormat,
    loginPagePathFormat,
    quizPagePathFormat,
    signUpPagePathFormat,
    userBadgesPath,
    userCoursesPath,
} from "./routes";

const EditorPage = lazy(() => import("../Editor"));
const AdminPage = lazy(() => import("../Admin"));

function PrivateRoute({ role, children }: any) {
    const [auth, finished] = useAuth();
    return auth?.user && auth?.user?.id ? (
        !role || (role && role == auth?.user?.role) ? (
            children
        ) : (
            <AccessDenied />
        )
    ) : (
        finished && <Navigate to={landingPagePathFormat} state={{ url: new URL(window.location.href).pathname }} />
    );
}

function AppRoutes() {
    return (
        <>
            <Routes>
                <Route
                    path={"/editor"}
                    element={
                        <Suspense fallback={null}>
                            <PrivateRoute role="admin">
                                <EditorPage />
                            </PrivateRoute>
                        </Suspense>
                    }
                >
                    <Route
                        path={":mediaId"}
                        element={
                            <Suspense fallback={null}>
                                <PrivateRoute role="admin">
                                    <EditorPage />
                                </PrivateRoute>
                            </Suspense>
                        }
                    />
                </Route>
                {/*<Route
                    path={workspacePathFormat}
                    element={
                        <PrivateRoute>
                            <Workspace />
                        </PrivateRoute>
                    }
                />*/}
                <Route
                    path={libraryPagePathFormat}
                    element={
                        <PrivateRoute>
                            <LibraryPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/admin/*"
                    element={
                        <Suspense fallback={null}>
                            <PrivateRoute role="admin">
                                <AdminPage />
                            </PrivateRoute>
                        </Suspense>
                    }
                ></Route>
                {/* <Route
                    path="/requests"
                    element={
                        <PrivateRoute>
                            <UserRequests />
                        </PrivateRoute>
                    }
                /> */}
                <Route
                    path="/watch/:mediaId"
                    element={
                        <PrivateRoute>
                            <MediaPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={coursePathFormat}
                    element={
                        <PrivateRoute>
                            <CoursePage />
                        </PrivateRoute>
                    }
                >
                    <Route
                        path={courseBlockPathFormat}
                        element={
                            <PrivateRoute>
                                <CoursePage />
                            </PrivateRoute>
                        }
                    />
                </Route>

                <Route
                    path="/:category/manage"
                    element={
                        <PrivateRoute>
                            <ManagePage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/:category/:filter"
                    element={
                        <PrivateRoute>
                            <FilteredListPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/feed"
                    element={
                        <PrivateRoute>
                            <FeedPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/review"
                    element={
                        <PrivateRoute>
                            <ReviewPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/publishers"
                    element={
                        <PrivateRoute>
                            <Publishers />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/suggestions"
                    element={
                        <PrivateRoute>
                            <SuggestionsPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={userBadgesPath}
                    element={
                        <PrivateRoute>
                            <UserBadgesPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={userCoursesPath}
                    element={
                        <PrivateRoute>
                            <UserCoursesPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={quizPagePathFormat}
                    element={
                        <PrivateRoute>
                            <QuizPage />
                        </PrivateRoute>
                    }
                />
                <Route path={joinWaitingListPathFormat} element={<JoinWaitinglistPage />} />
                <Route path={signUpPagePathFormat} element={<SignUpPage />} />
                <Route path="/policy" element={<Policy />} />
                <Route path="/beta-policy" element={<BetaPolicy />} />
                <Route path="/extension/:uniqueKey" element={<Extension />} />
                <Route path="/reset" element={<ResetPasswordPage />} />
                <Route path="/verify" element={<VerifyEmailPage />} />
                <Route path="/verified" element={<VerifyEmailPage verified={true} />} />
                <Route path="/recover/:uniqueKey" element={<RecoverPasswordPage />} />
                <Route path={loginPagePathFormat} element={<LoginPage />} />
                <Route path={landingPagePathFormat} element={<LandingPage />} />
                <Route path={embeddedQuizPagePathFormat} element={<EmbedPage />} />
            </Routes>
        </>
    );
}

export default AppRoutes;
