import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import classnames from "classnames";
import styles from "./ManagePage.module.scss";
import { getTopics, getMyTopics, getPublishers, getUserPublishers, followPublisher, followTopic } from "../../store/requests";

function ManagePage(props: any) {
    const acceptedCategories = ["topics", "channels"];
    const [allItems, setAllItems] = useState<any[]>([]);
    const [myItems, setMyItems] = useState<any[]>([]);

    const { category } = useParams();

    useEffect(() => {
        console.log("category", category);
        if (category === "topics") {
            getTopics(setAllItems);
            getMyTopics(setMyItems);
        } else if (category === "channels") {
            getPublishers({}, setAllItems);
            getUserPublishers({}, setMyItems);
        }
    }, [category]);

    const handleChange = async (item: any, index: number) => {
        const requestType = category === "topics" ? followTopic : followPublisher;

        const response = await requestType(item.id, index === -1);
        if (response) {
            if (index > -1) {
                setMyItems([...myItems.slice(0, index), ...myItems.slice(index + 1)]);
            } else {
                setMyItems([...myItems, item]);
            }
        }
    };

    const handleConfirm = () => {};

    if (!category || !acceptedCategories.includes(category)) {
        return <div>Not found</div>;
    }

    const items = allItems.map((item: any) => {
        const foundIndex = myItems.findIndex((it: any) => it.id === item.id);
        return (
            <div
                className={classnames(styles["item"], {
                    [styles["selected"]]: foundIndex > -1,
                })}
                key={item.id}
                onClick={() => handleChange(item, foundIndex)}
            >
                <div className={styles["item-title"]}>{item.name}</div>
                <div className={styles["item-check"]}>
                    <Form.Check type="checkbox" checked={foundIndex > -1}></Form.Check>
                </div>
            </div>
        );
    });

    return (
        <div className={styles["page"]}>
            <div className={styles["page-title"]}>Manage {category}</div>
            <div className={styles["page-subtitle"]}>
                Tick the boxes to choose which {category} you want to {category === "channels" ? "follow" : "subscribe to"}.
            </div>
            <div className={styles["page-content"]}>
                <div className={styles["list-container"]}>{items}</div>
                {/* <div className={styles["actions"]}> */}
                {/* <div className={classnames(styles["list-actions-button"], styles["cancel"])}>Cancel</div> */}
                {/* <div
                        className={classnames(
                            styles["actions-button"],
                            styles["confirm"]
                        )}
                        onClick={handleConfirm}
                    >
                        Confirm
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export default ManagePage;
