import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
export interface ILibraryState {
    userId: string;
    lastUpdate: Date | null;
    quizzes: ILibraryItem[];
}

export interface ILibraryItem {
    quizId: string;
    addedAt: string;
    publisher: {
        name: string;
        logo: string;
    };
    media: {
        id: string;
        title: string;
        link: string;
        image: string;
    };
    review?: {
        id: string;
        interval: number;
        media: string;
        quiz: string;
        scheduledAt: "string";
    };
}

const initialState: ILibraryState = {
    userId: "",
    lastUpdate: null,
    quizzes: [],
};

const removeItemFromList = (item: ILibraryItem, list: ILibraryItem[], compareField: string) => {
    return list.filter((value: ILibraryItem) => {
        return value[compareField as keyof ILibraryItem] !== item[compareField as keyof ILibraryItem];
    });
};

export const librarySlice: any = createSlice({
    name: "libraryData",
    initialState,
    reducers: {
        get: (state, action: PayloadAction<ILibraryState>) => {
            return action.payload;
        },
        cancel: (state, action: PayloadAction<ILibraryItem>) => {
            const leftItems = removeItemFromList(action.payload, state.quizzes, "quizId");
            state.quizzes = [...leftItems];
        },
        accept: (state, action: PayloadAction<ILibraryItem>) => {
            const leftItems = removeItemFromList(action.payload, state.quizzes, "quizId");
            state.quizzes = [...leftItems];
        },
        default: (state) => {
            return state;
        },
    },
});

export const { get, accept, cancel } = librarySlice.actions;

export default librarySlice.reducer;
