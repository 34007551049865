import { getFilteredMedia } from "@retainit/shared";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { followPublisher, followTopic } from "../../store/requests";
import LightCard from "../common/FreeCarousel/LightCard";
import styles from "./FilteredListPage.module.scss";

const FILTER_CONFIG = {
    topics: { type: "topic", limit: 30 },
    pubs: { type: "publisher", limit: 30 },
    library: { type: "library", limit: 30 },
    recent: { type: "last", limit: 30 },
};

type FilteredListPageProps =
    | {
          category: "topics" | "pubs" | "library" | "recent";
          filter: string;
      }
    | {
          category?: undefined;
          filter?: undefined;
      };

function FilteredListPage(props: FilteredListPageProps) {
    const [data, setData] = useState<any>({});
    const params = useParams();
    const { category, filter } = ((): { category: keyof typeof FILTER_CONFIG; filter: string } => {
        if (props.category) {
            return {
                category: props.category,
                filter: props.filter,
            };
        } else {
            return params as any; // todo resolve this
        }
    })();

    useEffect(() => {
        if (!category || !filter) {
            return;
        }
        const config: any = FILTER_CONFIG[category as keyof typeof FILTER_CONFIG];
        getFilteredMedia(filter, config).then(setData);
    }, [category, filter]);

    // const navigate = useNavigate();
    // const handleVideoClick = (media: any) => {
    //     navigate(`/watch/${media.id}`, { replace: false });
    // };

    const videoCards = data.mediaList?.map((media: any) => {
        return (
            <>
                {/* {category !== "topics" && (
                    <div className={styles["item"]} key={media.id}>
            <VideoCard
                    customClass={styles["card"]}
                    key={media.id}
                    {...media}
                    handleVideoClick={() => {
                        handleVideoClick(media);
                    }}
                /></div>)} */}
                <div className={styles["light-item"]} key={media.id}>
                    <LightCard media={media} key={media.id} />
                </div>
            </>
        );
    });
    const handleFollowClick = async () => {
        if (category === "pubs") {
            const response = await followPublisher(data.filterMeta?.id, !data.filterMeta?.followed);
            setData({
                ...data,
                filterMeta: {
                    ...data.filterMeta,
                    followed: response.followed,
                },
            });
        } else {
            const response = await followTopic(data.filterMeta?.id, !data.filterMeta?.followed);
            setData({
                ...data,
                filterMeta: {
                    ...data.filterMeta,
                    followed: response.followed,
                },
            });
        }
    };
    return (
        <>
            <div className={styles["page"]}>
                <div className={styles["header"]}>
                    {data.filterMeta?.logo && <img src={data.filterMeta?.logo} alt="" />}
                    <div className={styles["title-wrapper"]}>
                        <div className={styles["title"]}>
                            {["pubs", "topics", "library"].includes(category || "")
                                ? data.filterMeta?.name
                                : category === "recent"
                                ? "Recently added"
                                : ""}
                        </div>
                        <div className={styles["instructions"]}>
                            {category === "topics" && "Explore all videos for this topic."}
                            {category === "library" && "All of your quizzes in one place."}
                            {category === "pubs" && "Explore all videos for this channel."}
                        </div>
                    </div>
                    {category === "pubs" && (
                        <span>
                            <div
                                className={classnames(styles["follow"], {
                                    [styles["following"]]: data.filterMeta?.followed,
                                })}
                                onClick={handleFollowClick}
                            >
                                {" "}
                                {data.filterMeta?.followed ? "Unfollow" : "Follow"}{" "}
                            </div>
                        </span>
                    )}
                    {category === "topics" && (
                        <span>
                            <div
                                className={classnames(styles["follow"], {
                                    [styles["following"]]: data.filterMeta?.followed,
                                })}
                                onClick={handleFollowClick}
                            >
                                {" "}
                                {data.filterMeta?.followed ? "Unsubscribe" : "Subscribe"}{" "}
                            </div>
                        </span>
                    )}
                    <hr />
                </div>
                <div className={styles["media-container"]}>{videoCards}</div>
            </div>
        </>
    );
}

export default FilteredListPage;
