import { IUserStats } from "@retainit/shared";
import * as Sentry from "@sentry/react";
import { buildUseFetch } from "../buildUseFetch";
import { BASE_URL } from "../config";
import { ILibraryState } from "./librarySlice";

const headers: HeadersInit = new Headers({
    "X-Retainit-Origin": "dashboard",
});
const headersJson: HeadersInit = new Headers({
    "Content-Type": "application/json",
    "X-Retainit-Origin": "dashboard",
});

export const setupRequests = (otherHeaders: any) => {
    Object.keys(otherHeaders).forEach((key) => {
        headers.set(key, otherHeaders[key]);
    });
};

export const fetchData = async (path = "/", method = "GET", body: any = undefined, query: any = {}) => {
    const searchParams = new URLSearchParams(query);
    const queryString = searchParams.toString() ? `?${searchParams.toString()}` : "";
    const url = `${BASE_URL}${path}${queryString}`;
    const config: RequestInit = {
        method,
        credentials: "include",
        headers: body instanceof FormData ? headers : headersJson,
        body: body instanceof FormData ? body : JSON.stringify(body),
    };

    try {
        const response = await fetch(url, config);
        return response.ok ? await response.json() : null;
    } catch (err) {
        Sentry.captureException(err);
        return null;
    }
};

export const getWorkspaceItem = async (cb?: Function) => {
    const response = await fetchData("/workspace");
    cb && cb(response);
};

export type LibrarySortBy = "addedAt" | "lastReviewed" | "name" | "publisher";
export const getLibraryItems = async (sortBy: LibrarySortBy, search?: string, invert?: boolean, page?: number): Promise<ILibraryState> =>
    await fetchData("/library", "GET", undefined, {
        search,
        sortBy,
        invert,
        page,
    });

export const getRecommendations = async (topic: string, limit?: number, cb?: Function) => {
    const response = await fetchData("/media/recommendations", "GET", undefined, { topic, limit });
    cb && cb(response);
    return response;
};

export const saveUserTopics = async (topics: any, cb?: Function) => {
    const response = await fetchData("/user/topics", "POST", topics);
    cb && cb(response);
};

export const getMyTopics = async (cb?: Function) => {
    const response = await fetchData("/user/topics");
    cb && cb(response);
};

export const getTopics = async (cb?: Function) => {
    const response = await fetchData("/media/tags");
    cb && cb(response);
};

// todo, use libs
export const deleteReview = async (mediaId: string, cb?: Function) => {
    const response = await fetchData(`/user/reviews/${mediaId}`, "DELETE");
    cb && cb(response);
    return response;
};

export const getReviewStatus = async (mediaId: string, cb?: Function) => {
    const response = await fetchData(`/media/${mediaId}/review`);
    cb && cb(response);
    return response;
};

export const getGUserData = async (token: string, cb?: Function) => {
    const userData = await fetchData("/auth/login", "POST", { token });
    cb && cb(userData);
    return userData;
};

export const getUserData = async (user: any, cb?: Function) => {
    const userData = await fetchData("/auth/login", "POST", user);
    //  const userData = user
    cb && cb(userData);
    return userData;
};

export const signUserOut = async (cb?: Function) => {
    const response = await fetchData(`/auth/logout`, "POST");
    cb && cb(response);
    return response;
};

export const joinUserUp = async (userData: any, referral?: string, cb?: Function) => {
    const response = await fetchData(`/auth/join`, "POST", {
        ...userData,
        referral,
    });
    cb && cb(response);
    return response;
};

export const signUserUp = async (
    userData: {
        token?: string;
        email: string;
        firstName: string;
        lastName: string;
        picture: string;
        password: string;
    },
    cb?: Function
) => {
    const response = await fetchData(`/auth/signup`, "POST", userData);
    cb && cb(response);
    return response;
};

export const signGUserUp = async (token: any, cb?: Function) => {
    const response = await fetchData(`/auth/signup`, "POST", { token: token });
    cb && cb(response);
    return response;
};

export const requestNewPassword = async () => {
    const response = await fetchData("/auth/forgotpass", "POST");
    return response;
};

export const requestAccountValidation = async (data: any) => {
    const response = await fetchData("/auth/accountvalidation", "POST", data);
    return response;
};

export const requestPassChange = async (userEmail: any) => {
    const response = await fetchData("/auth/recover", "POST", userEmail);
    return response;
};

export const saveNewPassword = async (key: string, newPassword: any) => {
    const response = await fetchData("/auth/change", "POST", {
        key,
        ...newPassword,
    });
    return response;
};

// todo, use from @retainit/shared
export const refreshToken = async () => {
    const response = await fetchData("/auth/refresh");
    return response;
};

export const getMyRequests = async (cb?: Function) => {
    const response = await fetchData("/requests");
    cb && cb(response);
    return response;
};

export type INotification = {
    user: string;
    status: "sent" | "dismissed" | "archived";
    title: string;
    body: string;
    link: string;
    createdAt: string;
    updatedAt: string;
} & (
    | {
          type: "quiz-ready";
          media: string;
          image: string;
          mediaTitle: string;
      }
    | {
          type: "quiz-failed" | "review-ready" | "generic";
      }
);

export const getMyNotifications = (): Promise<INotification[]> => fetchData("/user/notifications");
export const useMyNotifications = buildUseFetch<undefined, undefined, INotification[]>("/user/notifications", "GET");

export type IPartner = {
    id: string;
    createdAt: string;
    logo: string;
    name: string;
    referral: string;
    defaultCourses: string[];
    publisher: string;
    tags: string[];
    tokensUsed: number;
};

export const requestQuiz = async (url: string) => {
    const body = { url };
    const data = await fetchData(`/requests`, "POST", body);
    return data;
};

// export const saveQuizToLibrary = async (id: string) => {
//     const body = { quiz: id };
//     const data = await fetchData(`/library/quizzes`, "POST", body);
//     return data;
// };

export const getUserRecommendations = async (cb?: Function) => {
    const response = await fetchData(`/user/explore`);
    cb && cb(response);
    return response;
};

export const getMediaForLink = async (url: string, cb?: Function) => {
    const response = await fetchData(`/media/url`, "GET", undefined, { url });
    cb && cb(response);
    return response;
};

export const updateTutorial = async (cb?: Function) => {
    const response = await fetchData(`/user/tutorial`, "POST");
    cb && cb(response);
    return response;
};

export const getRecommendationsForMedia = async (mediaId: string, opts?: any, cb?: Function) => {
    const response = await fetchData(`/media/${mediaId}/recommendations`, "GET", undefined, opts);
    cb && cb(response);
    return response;
};

export const getRandomVideo = async (cb?: Function) => {
    const response = await getRecommendations("", 1, cb);
    cb && cb(response);
    return response;
};

export type ReviewItem = {
    id: string;
    interval: number;
    media: {
        createdAt: string;
        extraLinks: string[];
        fullLink: string;
        hasActiveQuizzes: boolean;
        id: string;
        image: string;
        isPrivate: false;
        linkedItem: string;
        ytbId: string;
        publisher: {
            channelId: null | string;
            createdAt: string;
            host: string;
            id: string;
            logo: string;
            name: string;
            popularity: number;
            sponsored: boolean;
        };
        tags: string[];
        title: string;
        type: "article" | "video";
        updatedAt: string;
    };
    quiz: string;
    scheduledAt: string;
    score: any[];
};
export const getReviews = async (): Promise<ReviewItem[]> => await fetchData(`/user/reviews`);

export type IPublisher = {
    id: string;
    name: string;
};
export const getPublishers = async (query: any = {}, cb?: Function): Promise<IPublisher[]> => {
    const response = await fetchData(`/publishers`, "GET", undefined, query);
    cb && cb(response);
    return response;
};

export const getUserPublishers = async (query: any = {}, cb?: Function) => {
    const response = await fetchData(`/user/publishers`);
    cb && cb(response);
    return response;
};
export const followPublisher = async (id: string, toFollow: boolean, cb?: Function) => {
    const response = await fetchData(`/user/publishers/${id}/follow`, "POST", {
        follow: toFollow,
    }); //full list updated
    cb && cb(response);
    return response;
};

export const followTopic = async (id: string, toFollow: boolean, cb?: Function) => {
    // TODO: This can be compressed with the above
    const response = await fetchData(`/user/topics/${id}/follow`, "POST", {
        follow: toFollow,
    }); //full list updated
    cb && cb(response);
    return response;
};

export const requestReminder = async (selection: Date | null, mediaId: string, next?: boolean) => {
    const body = {
        dateTime: selection?.toISOString(),
        timezone: selection?.getTimezoneOffset(),
        next: next || false,
    };
    const data = await fetchData(`/media/${mediaId}/review`, "POST", body);
    return data;
};

// todo use from libs
export const getUserStats = async (cb?: (value: IUserStats) => void): Promise<IUserStats> => {
    const response = await fetchData(`/user/stats`);
    cb && cb(response);
    return response;
};

export const logMetrics = async (data: any, method = "POST") => {
    const nav: any = window.navigator;
    const navigator = {
        userAgentData: nav.userAgentData,
        languages: nav.languages,
        platform: nav.platform,
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
    };
    fetchData(`/metrics`, method, { ...data, navigator });
};

export const useGetQuestionComparison = buildUseFetch<
    undefined,
    undefined,
    {
        a: { question: any; media: any };
        b: { question: any; media: any };
    }
>("/label/compareQuestions/comparison", "GET");

export const useSubmitQuestionComparison = buildUseFetch<{ aId: string; bId: string; winner: "A" | "B"; reason: string }, undefined, boolean>(
    "/label/compareQuestions/comparison",
    "POST"
);

export const useRefreshQuestionComparison = buildUseFetch<undefined, undefined, undefined>("/label/compareQuestions/refresh", "POST");
export const useSampleQuestionComparison = buildUseFetch<undefined, undefined, { question: string; elo: number }[]>(
    "/label/compareQuestions/sample",
    "GET"
);

export type GenerateQuizLengths = "short" | "medium" | "long";
export type GenerateOptions =
    | {
          length: GenerateQuizLengths;
          highlights?: undefined;
      }
    | {
          length?: undefined;
          highlights: string[];
      };

export type GeneratedResponse = { success: true; mediaId: string; quizId: string } | { success: false; errors?: string[] | undefined };

export const generateQuiz = async (url: string, options: GenerateOptions, reviewAt?: string | undefined): Promise<GeneratedResponse> => {
    const body = {
        url,
        ...(reviewAt ? { reviewAt } : {}),
        ...options,
    };
    return await fetchData(`/media/generate`, "POST", body);
};

export type Partner = {
    name: string;
    logo?: string;
    referral: string;
};

export const getPartnerByReferral = async (referral: string): Promise<Partner> => await fetchData(`/partner/?referral=${referral}`, "GET");
