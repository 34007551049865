import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
export interface IWorkspaceState {
    userId: string;
    lastUpdate: Date | null;
    quizzes: IWorkspaceItem[];
    tutorial?: boolean;
}

export interface IWorkspaceItem {
    quizId: string;
    publisher: {
        name: string;
        logo: string;
    };
    media: {
        title: string;
        link: string;
        image: string;
    };
}

const initialState: IWorkspaceState = {
    userId: "",
    lastUpdate: null,
    quizzes: [],
};

const removeItemFromList = (item: IWorkspaceItem, list: IWorkspaceItem[], compareField: string) => {
    return list.filter((value: IWorkspaceItem) => {
        return value[compareField as keyof IWorkspaceItem] !== item[compareField as keyof IWorkspaceItem];
    });
};

export const workspaceSlice: any = createSlice({
    name: "workspaceData",
    initialState,
    reducers: {
        get: (state, action: PayloadAction<IWorkspaceState>) => {
            return action.payload;
        },
        cancel: (state, action: PayloadAction<IWorkspaceItem>) => {
            const leftItems = removeItemFromList(action.payload, state.quizzes, "quizId");
            state.quizzes = [...leftItems];
        },
        accept: (state, action: PayloadAction<IWorkspaceItem>) => {
            const leftItems = removeItemFromList(action.payload, state.quizzes, "quizId");
            state.quizzes = [...leftItems];
        },
        default: (state) => {
            return state;
        },
    },
});

export const { get, accept, cancel } = workspaceSlice.actions;

export default workspaceSlice.reducer;
