import { animated, useSpring } from "@react-spring/web";
import { getUserCourses, PopulatedICourseBlock, PopulatedIUserCourse, useColourSample, useTitle } from "@retainit/shared";
import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { coursePath } from "../AppRoutes/routes";
import { _throw } from "../Editor/editorUtils";
import styles from "./UserCoursesPage.module.scss";

type UserCoursesPageProps = {};

export const UserCoursesPage = (__: UserCoursesPageProps) => {
    const { data, isLoading } = useQuery(["user.courses"], () => getUserCourses());

    useTitle(`Retainit - Courses`);

    const colors = useColourSample();

    const [selectedCourse, setSelectedCourse] = useState<PopulatedIUserCourse | null>(null);

    return (
        <div className={styles.inner}>
            <div className={styles.top}>
                <div className={styles.title}>{selectedCourse?.course?.title ?? "Your Courses"}</div>
            </div>
            <div className={styles.list}>
                {isLoading && <div className={classNames(styles.course, styles.loading)} />}

                {data &&
                    data.length !== 0 &&
                    data.map((userCourse, index) => (
                        <Course
                            key={userCourse.id}
                            color={colors[index % colors.length]}
                            userCourse={userCourse}
                            selection={selectedCourse}
                            toggleSelection={() => {
                                if (selectedCourse === userCourse) {
                                    setSelectedCourse(null);
                                } else {
                                    setSelectedCourse(userCourse);
                                }
                            }}
                        />
                    ))}

                {data && data.length === 0 && <>You're not currently taking any courses</>}
            </div>
        </div>
    );
};

export type CourseProps = {
    color: string;
    userCourse: PopulatedIUserCourse;
    selection: PopulatedIUserCourse | null;
    toggleSelection: () => void;
};
const Course = ({ color, userCourse, toggleSelection, selection }: CourseProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [height, animate] = useSpring(() => ({
        height: ref.current?.offsetHeight,
    }));

    const startHeight = useMemo(() => ref.current?.offsetHeight, [ref.current?.offsetHeight !== undefined]);

    useEffect(() => {
        if (ref?.current) {
            animate.start({ height: selection === userCourse ? ref.current?.offsetHeight : selection === null ? startHeight : 0 });
        }
    }, [animate, ref.current, selection]);

    type Stats = { done: number; total: number };
    const findStats = (blocks: PopulatedICourseBlock[]): Stats =>
        blocks
            .map((it) =>
                it.type === "media"
                    ? {
                          total: 1,
                          done: it.done ? 1 : 0,
                      }
                    : it.type === "blocks"
                    ? findStats(it.blocks)
                    : _throw("Unknown block type")
            )
            .reduce<Stats>((acc, cur) => ({ done: acc.done + cur.done, total: acc.total + cur.total }), { done: 0, total: 0 });

    const { done, total } = findStats(userCourse.course.blocks);

    const isSelected = selection === userCourse;
    return (
        <animated.div className={styles.course} style={{ backgroundColor: color, ...height }} onClick={() => toggleSelection()}>
            <div className={styles.inner} ref={ref}>
                <div className={styles.upper}>
                    <img className={styles.image} src={userCourse.course.logo} alt={userCourse.course.title} />
                    <div className={styles.details}>
                        <div className={styles.title}>{userCourse.course.title}</div>
                        <div className={styles.bottom}>
                            <div className={styles.left}>
                                <div className={styles.publisherChip}>
                                    <img
                                        className={styles.publisherImage}
                                        src={userCourse.course.publisher.logo}
                                        alt={userCourse.course.publisher.name}
                                    />
                                    <div className={styles.publisherName}>{userCourse.course.publisher.name}</div>
                                    {!isSelected && <div className={styles.streak}></div>}
                                </div>
                                {isSelected && <div className={styles.description}>{userCourse.course.description}</div>}
                            </div>
                            {!isSelected && (
                                <div className={styles.right}>
                                    <div className={styles.progressIndicator}>
                                        <svg width={70} height={70}>
                                            <circle cx={35} cy={35} r={31.5} fill="none" stroke="#8B5839" strokeWidth={7} />
                                            <circle
                                                cx={35}
                                                cy={35}
                                                r={31.5}
                                                fill="none"
                                                stroke="#fff"
                                                strokeWidth={7}
                                                strokeDasharray={`${(done / total) * 100} 1000`}
                                                strokeLinecap={"round"}
                                                style={{
                                                    transform: "rotate(-90deg)",
                                                    transformOrigin: "center",
                                                }}
                                            />
                                        </svg>
                                        <span>{((100 * done) / total).toFixed(2)}</span>
                                    </div>
                                    completed
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {isSelected && (
                    <div className={styles.blocks}>
                        {userCourse.course.blocks.map((block, index) => {
                            const { done, total } = findStats([block]);
                            return (
                                <Link
                                    className={classNames(styles.block, {
                                        [styles.complete]: done === total,
                                    })}
                                    key={index}
                                    to={coursePath({ courseId: userCourse.course.id, blockId: block.id })}
                                >
                                    <div className={styles.description}>
                                        <span>
                                            {index + 1}. {block.title}
                                        </span>
                                        <span>
                                            {done}/{total} quizzes
                                        </span>
                                    </div>
                                    <div className={styles.progressBar}>
                                        <div className={styles.fill} style={{ right: `${100 - (100 * done) / total}%` }} />
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                )}
            </div>
        </animated.div>
    );
};
