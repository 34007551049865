import { animated, useSpring } from "@react-spring/web";
import { getMedia, getUserCourse, PopulatedICourse, PopulatedICourseBlock, PopulatedMediaICourseBlock, QuizModal, useTitle } from "@retainit/shared";
import classNames from "classnames";
import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import closeIcon from "../../assets/close-button.svg";
import { VERSION } from "../../config";
import { coursePath } from "../AppRoutes/routes";
import { _throw } from "../Editor/editorUtils";
import styles from "./CoursePage.module.scss";

export const CoursePage = () => {
    const { courseId, blockId } = useParams<{ courseId: string; blockId?: string }>();

    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const { data: course } = useQuery(["course", courseId], () => getUserCourse(courseId ?? _throw("Error")), {
        enabled: !!courseId,
        onSuccess: (data) => {
            if (!data) {
                _throw("Failed to load");
            }
            const location =
                (() => {
                    if (blockId) {
                        const found = findBlock(data.course.blocks, blockId);
                        if (!found) {
                            return firstUnfinishedBlock(data.course.blocks);
                        } else if (found.type === "media") {
                            return found;
                        } else {
                            return firstUnfinishedBlock([found]);
                        }
                    } else {
                        return firstUnfinishedBlock(data.course.blocks) ?? firstBlock(data.course.blocks);
                    }
                })() ?? firstBlock(data.course.blocks);

            updateLocation(location);
        },
    });

    useTitle(`Retainit - ${course?.course.title ?? "Course"}`);

    const [location, setLocation] = useState<PopulatedMediaICourseBlock | null>(null);

    const { data: media, isLoading: mediaIsLoading } = useQuery(["media", location?.media], () => getMedia(location?.media ?? _throw("Error")), {
        enabled: !!location?.media,
    });
    // const { data: tags } = useQuery(["userTags", location?.media], () => getUserMediaTags(location?.media ?? _throw("Error")), {
    //     enabled: !!location?.media,
    // });

    const parent = course && location ? findParent(course.course, location) : null;

    const [progressOpen, setProgressOpen] = useState(true);

    const progressWidth = useSpring({
        left: progressOpen ? 0 : -245,
        messageOpacity: progressOpen ? 0 : 1,
        contentsOpacity: progressOpen ? 1 : 0,
        transform: progressOpen ? "rotateZ(0deg)" : "rotateZ(180deg)",
    });

    const next = location && course?.course?.blocks ? nextBlock(course.course, location) : null;

    const handleSkip = () => {
        if (location && course?.course?.blocks && next) {
            updateLocation(next);
        }
    };

    const updateLocation = (block: PopulatedMediaICourseBlock) => {
        setLocation(block);
        if (courseId && location?.id) {
            navigate(
                coursePath({
                    courseId: courseId,
                    blockId: block.id,
                }),
                { replace: true }
            );
        }
    };

    const [showAbout, setShowAbout] = useState(false);
    const [showQuiz, setShowQuiz] = useState(false);

    const handleLaunchQuiz = () => {
        if (media) {
            setShowQuiz(true);
            // navigate(quizPagePath({ quizId: media.quiz }));
        }
    };

    return (
        <div className={styles.outer}>
            <img
                className={styles.back}
                src={closeIcon}
                onClick={() => {
                    navigate(-1);
                }}
            />
            {course && location && (
                <>
                    <animated.div className={classNames(styles.progress, { [styles.open]: progressOpen })} style={{ left: progressWidth.left }}>
                        <animated.img
                            className={styles.closeButton}
                            src={closeIcon}
                            alt="close"
                            onClick={() => setProgressOpen((it) => !it)}
                            style={{ transform: progressWidth.transform }}
                        />
                        <animated.div className={styles.message} style={{ opacity: progressWidth.messageOpacity }}>
                            Unit Quizzes
                        </animated.div>
                        <animated.div style={{ opacity: progressWidth.contentsOpacity }}>
                            <div className={styles.courseTitle}>{course.course.title}</div>
                            {parent && (
                                <>
                                    <div className={styles.subtitle}>{parent.title}</div>
                                    <div className={styles.items}>
                                        {parent.type === "course" || parent.type === "blocks"
                                            ? parent.blocks.map((block) => (
                                                  <div
                                                      className={classNames(styles.item, {
                                                          [styles.active]: block.id === location.id,
                                                          [styles.done]: isDone(block),
                                                      })}
                                                      onClick={() => (block.type === "media" ? updateLocation(block) : null)}
                                                      key={block.id}
                                                  >
                                                      {isDone(block) ? (
                                                          <svg
                                                              width="10"
                                                              height="10"
                                                              viewBox="0 0 10 10"
                                                              fill="none"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                          >
                                                              <circle cx="5" cy="5" r="5" fill="#FFF597" />
                                                              <path
                                                                  d="M7.1045 2.98258C6.97335 2.98695 6.84909 3.04232 6.75814 3.13684C5.79309 4.10398 5.05601 4.91178 4.16041 5.82591L3.20629 5.01982V5.01987C3.10405 4.93341 2.97159 4.89117 2.83817 4.90252C2.70478 4.91388 2.58134 4.97789 2.49519 5.08034C2.40899 5.18284 2.36717 5.31539 2.37889 5.44878C2.39061 5.58216 2.45493 5.70539 2.55764 5.79132L3.86753 6.89971V6.89966C3.96394 6.98086 4.08722 7.02299 4.2131 7.01773C4.33904 7.01252 4.45841 6.96028 4.54769 6.87133C5.6329 5.78378 6.40542 4.9152 7.46982 3.84853V3.84858C7.5679 3.75379 7.62337 3.62332 7.62352 3.48697C7.62372 3.35057 7.56862 3.22 7.47081 3.12494C7.373 3.02989 7.24086 2.97854 7.10456 2.98265L7.1045 2.98258Z"
                                                                  fill="#2C2A5D"
                                                              />
                                                          </svg>
                                                      ) : (
                                                          <svg
                                                              width="10"
                                                              height="10"
                                                              viewBox="0 0 10 10"
                                                              fill="none"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                          >
                                                              <circle cx="5" cy="5" r="4.5" stroke={block.id === location.id ? "white" : "#908FAC"} />
                                                          </svg>
                                                      )}

                                                      {block.title}
                                                  </div>
                                              ))
                                            : _throw("This should never happen, todo, edit types")}
                                    </div>
                                </>
                            )}
                        </animated.div>
                    </animated.div>
                    {location && (
                        <div className={styles.inner}>
                            {media && (
                                <div className={styles.media}>
                                    <div className={styles.title}>{location.title}</div>
                                    <div className={styles.content}>
                                        {media?.type === "video" ? (
                                            <iframe title={media.title} src={`https://www.youtube.com/embed/${media.ytbId}`} />
                                        ) : media?.type === "article" ? (
                                            <Link to={media.fullLink}>
                                                <img src={media.image} alt="media" />
                                            </Link>
                                        ) : (
                                            <div>Unimplemented</div>
                                        )}
                                    </div>
                                    <div className={styles.details}>
                                        <Link className={styles.publisher} to={`/pubs/${media.publisher.id}`}>
                                            <img className={styles.logo} src={media.publisher.logo} alt={media.publisher.name} />
                                            <div className={styles.name}>{media.publisher.name}</div>
                                        </Link>
                                        {/*<div className={styles.tags}>*/}
                                        {/*    <div className={styles.list}>*/}
                                        {/*        {tags?.map((tag) => (*/}
                                        {/*            <div className={styles.tag}>{tag.name}</div>*/}
                                        {/*        ))}*/}
                                        {/*    </div>*/}
                                        {/*    <div className={styles.add}>*/}
                                        {/*        <img src={tag} alt="tag" />*/}
                                        {/*        Add*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className={styles.about} onClick={() => setShowAbout(true)}>
                                        About this Course
                                    </div>
                                </div>
                            )}
                            {mediaIsLoading && <div className={classNames(styles.media, styles.loading)}></div>}

                            <div className={styles.actions}>
                                <div className={styles.takeQuiz} onClick={handleLaunchQuiz}>
                                    Take Quiz
                                </div>
                                {next && (
                                    <div className={styles.skip} onClick={handleSkip}>
                                        Skip
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </>
            )}
            {showAbout && location && (
                <div className={styles.aboutOuter} onClick={() => setShowAbout(false)}>
                    <div className={styles.aboutInner} onClick={(e) => e.stopPropagation()}>
                        <div className={styles.close} onClick={() => setShowAbout(false)}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="8" cy="8" r="8" fill="#2C2B5E" />
                                <rect
                                    x="3.84326"
                                    y="5.41895"
                                    width="1.69577"
                                    height="10.1746"
                                    rx="0.847887"
                                    transform="rotate(-44.2537 3.84326 5.41895)"
                                    fill="white"
                                />
                                <rect
                                    x="4.94873"
                                    y="12.6289"
                                    width="1.69577"
                                    height="10.1746"
                                    rx="0.847887"
                                    transform="rotate(-134.254 4.94873 12.6289)"
                                    fill="white"
                                />
                            </svg>
                        </div>
                        <div className={styles.title}>{location.title}</div>
                        <div className={styles.description}>{location.description}</div>
                    </div>
                </div>
            )}
            {showQuiz && media && (
                <QuizModal
                    key={media.quiz}
                    quizId={media.quiz}
                    show={true}
                    meta={{ title: "", pubLogo: "" }}
                    origin={`dashboard/course@${VERSION}`}
                    handleClose={(result) => {
                        setShowQuiz(false);
                        if (next && result.completed) {
                            updateLocation(next);
                            // invalidate cache
                            queryClient.invalidateQueries(["course", courseId]);
                        }
                    }}
                />
            )}
        </div>
    );
};

const flattenAllMedia = (blocks: PopulatedICourseBlock[]): PopulatedMediaICourseBlock[] =>
    blocks
        .map((block) => (block.type === "media" ? [block] : block.type === "blocks" ? flattenAllMedia(block.blocks) : _throw("Unimplemented")))
        .flat();

const flattenAllBlocks = (blocks: PopulatedICourseBlock[]): PopulatedICourseBlock[] =>
    blocks
        .map((block) =>
            block.type === "media" ? [block] : block.type === "blocks" ? [block, ...flattenAllBlocks(block.blocks)] : _throw("Unimplemented")
        )
        .flat();

const findBlock = (blocks: PopulatedICourseBlock[], id: string): PopulatedICourseBlock | null => {
    const flattened = flattenAllBlocks(blocks);
    return flattened.find((block) => block.id === id) ?? null;
};
const firstUnfinishedBlock = (blocks: PopulatedICourseBlock[]): PopulatedMediaICourseBlock | null => {
    const flattened = flattenAllMedia(blocks);
    const firstUnfinished = flattened.find((block) => !block.done);
    return firstUnfinished ?? null;
};

const firstBlock = (blocks: PopulatedICourseBlock[]): PopulatedMediaICourseBlock => {
    const flattened = flattenAllMedia(blocks);
    return flattened[0];
};

const nextBlock = (course: PopulatedICourse, block: PopulatedICourseBlock): PopulatedMediaICourseBlock | null => {
    const blockToParentMap = new Map<PopulatedICourseBlock, PopulatedICourseBlock | PopulatedICourse | null>();
    const buildMap = (parent: PopulatedICourseBlock | PopulatedICourse): void => {
        if (parent.type === "media") {
            return;
        }
        parent.blocks.forEach((block) => {
            blockToParentMap.set(block, parent);
            buildMap(block);
        });
    };
    buildMap(course);

    const findNext = (block: PopulatedICourseBlock): PopulatedMediaICourseBlock | null => {
        const parent = blockToParentMap.get(block);
        if (!parent || parent.type === "media") {
            return null;
        }
        const currentIndex = parent.blocks.indexOf(block);
        const next = parent.blocks?.[currentIndex + 1];
        if (next && next.type === "media") {
            return next;
        } else if (next) {
            return firstBlock([next]);
        } else if (parent.type === "blocks") {
            return findNext(parent);
        } else {
            return null;
        }
    };

    return findNext(block);
};

const findParent = (
    course: PopulatedICourseBlock | PopulatedICourse,
    block: PopulatedICourseBlock
): PopulatedICourseBlock | null | PopulatedICourse => {
    if (course.type === "media") {
        return null;
    }

    if (course.blocks.includes(block)) {
        return course;
    }

    return course.blocks
        .filter((it) => it.type === "blocks")
        .reduce<PopulatedICourseBlock | PopulatedICourse | null>((acc, current) => findParent(current, block) ?? acc, null);
};

const isDone = (block: PopulatedICourseBlock): boolean => {
    if (block.type === "media") {
        return block.done;
    } else {
        return flattenAllMedia(block.blocks)
            .filter((it) => it.type === "media")
            .every((it) => it.done);
    }
};
