import React from "react";

export const Library = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g>
            <path
                d="m760 342.5c-2.5-12.5-17.5-22.5-30-17.5-12.5 2.5-20 17.5-17.5 30l150 550c2.5 10 12.5 17.5 25 17.5h7.5c12.5-2.5 20-17.5 17.5-30z"
                fill="currentColor"
            />
            <path d="m610 900v-600c0-15-10-25-25-25s-25 10-25 25v600c0 15 10 25 25 25s25-10 25-25z" fill="currentColor" />
            <path d="m385 900v-600c0-15-10-25-25-25s-25 10-25 25v600c0 15 10 25 25 25s25-10 25-25z" fill="currentColor" />
        </g>
    </svg>
);
