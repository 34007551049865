import classnames from "classnames";
import React from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import logo_black from "../../assets/retainit-logo-white.svg";
import { saveNewPassword } from "../../store/requests";
import { loginPagePathFormat } from "../AppRoutes/routes";
import { Footer } from "./Footer";
import styles from "./Login.module.scss";

export const RecoverPasswordPage = () => {
    const { uniqueKey } = useParams();
    const navigate = useNavigate();

    const handleSaveNewPass = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.target as HTMLFormElement);
        const formDataObj = Object.fromEntries(formData.entries());
        const response = await saveNewPassword(uniqueKey || "", formDataObj);
        if (response) {
            navigate(loginPagePathFormat);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                <div className={styles.logosCotainer}>
                    <img className={styles.logo} src={logo_black} height={35} alt="retainit" />
                </div>

                <div className={styles.title}>New password</div>

                <div className={styles["content"]}>
                    <div className={styles.instructions}>Enter your new password in the form below</div>
                    <Form onSubmit={handleSaveNewPass}>
                        <Form.Group className={classnames("mb-3", styles["control-group"])} controlId="formBasicEmail">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" name="password" />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            <span>Save</span>
                            <span className={styles["arrow"]}></span>
                        </Button>
                    </Form>
                </div>
            </div>
            <Footer small={true} />
        </div>
    );
};
