import { LayoutContext, useColourSample } from "@retainit/shared";
import classnames from "classnames";
import React, { useContext } from "react";
import archive from "../../assets/archive.png";
import article from "../../assets/article.svg";

import clock from "../../assets/clock.svg";
import playQuiz from "../../assets/play-quiz.svg";
import launch from "../../assets/rocket.png";
import skip from "../../assets/skip.png";
import upChevron from "../../assets/up-chevron.svg";
import { ReviewItem } from "../../store/requests";

import styles from "./ReviewCard.module.scss";

type ReviewCardProps = {
    media: ReviewItem["media"];
    handleSkip: () => void;
    handleLaunch: () => void;
    handleDelete: () => void;
};

function ReviewCard(props: ReviewCardProps) {
    const { isMobile } = useContext(LayoutContext);
    const [color] = useColourSample();
    return (
        <div className={styles.outer}>
            <div className={styles.inner} style={{ backgroundColor: color }}>
                {props.media.type === "video" ? (
                    <iframe
                        title={props.media.title}
                        className={styles.image}
                        key={props.media.ytbId}
                        width="745"
                        height="380"
                        src={`https://www.youtube.com/embed/${props.media.ytbId}/?autoplay='0'&&enablejsapi=1&modestbranding=1&showinfo=0`}
                        allow="autoplay"
                        allowFullScreen
                    />
                ) : props.media.image ? (
                    <img
                        className={styles.image}
                        src={props.media.image}
                        alt={"article"}
                        onClick={() => {
                            window.open(props.media.fullLink, "_blank", "noopener,noreferrer");
                        }}
                    />
                ) : (
                    <div className={styles["article-container"]}>
                        <img src={article} alt={"article"} />
                        <div
                            className={styles["article-button"]}
                            onClick={() => {
                                window.open(props.media.fullLink, "_blank", "noopener,noreferrer");
                            }}
                        >
                            Open article
                            <img src={upChevron} alt={"Go"} />
                        </div>
                    </div>
                )}
                <div className={styles.description}>
                    <div className={styles.title}>{props.media.title}</div>
                    <div className={styles.publisherChip}>
                        <img className={styles.image} src={props.media.publisher.logo} />
                        <div className={styles.name}>{props.media.publisher.name}</div>
                    </div>
                </div>
            </div>
            <div className={styles.actions}>
                <div className={styles.doLater} onClick={props.handleSkip}>
                    <div className={styles.top}>
                        <img src={clock} alt="later" />
                    </div>
                    <div className={styles.bottom}>Do Later</div>
                </div>
                <div className={styles.reivew} onClick={props.handleLaunch}>
                    <div className={styles.top}>
                        <img src={playQuiz} alt="play" />
                    </div>
                    <div className={styles.bottom}>Review</div>
                </div>
            </div>
        </div>
    );
}

export default ReviewCard;
