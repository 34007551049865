import {
    assets,
    GenericBadgeIcons,
    getUserAchievements,
    IAchievement,
    IUserPublisher,
    IUserTopic,
    setUserPublisher,
    setUserTopic,
    UserAchievementsQuery,
    UserAchievementsSortBy,
    useTitle,
} from "@retainit/shared";
import classNames from "classnames";
import React, { useCallback, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import arrowsIcon from "../../assets/arrows.svg";
import searchIcon from "../../assets/search.svg";
import tagImage from "../../assets/tag.svg";
import styles from "./UserBadgesPage.module.scss";

const sortOptionsText: Record<UserAchievementsSortBy, string> = {
    mostComplete: "Most Complete",
    // lastReviewed: "Last Reviewed",
    // name: "Name",
    // publisher: "Publisher Name",
};

const badgeIconMap: Record<GenericBadgeIcons, React.FunctionComponent<any>> = {
    wand: assets.Wand,
    shiled: assets.Shield,
    trendsetter: assets.Trendsetter,
    // marathoner: marathoner,
};

type UserBadgesPageProps = {};
export const UserBadgesPage = (__: UserBadgesPageProps) => {
    useTitle(`Retainit - Badges`);

    const [queryState, setQueryState] = useState<UserAchievementsQuery>({
        search: "",
        sortBy: "mostComplete",
        invertSort: false,
        page: 0,
    });

    const queryClient = useQueryClient();
    const badges = useQuery(["user.achievements", queryState], () => getUserAchievements(queryState));
    const setPublisherPinned = useMutation<IUserPublisher, unknown, { publisherId: string; pinned: boolean }, { previous: IAchievement[] }>(
        [],
        ({ pinned, publisherId }) => setUserPublisher(publisherId, { isPinned: pinned }),
        {
            onMutate: async (updatedCourse) => {
                await queryClient.cancelQueries(["user.achievements", queryState]);
                const previous = queryClient.getQueryData(["user.achievements", queryState]) as IAchievement[];

                queryClient.setQueryData<IAchievement[]>(["user.achievements", queryState], (old) =>
                    (old ?? []).map((a) =>
                        a.type === "publisher" && a.publisher === updatedCourse.publisherId
                            ? {
                                  ...a,
                                  isPinned: updatedCourse.pinned,
                              }
                            : a
                    )
                );
                return { previous };
            },
            onError: (err, newTodo, context) => {
                if (context) {
                    queryClient.setQueryData(["user.achievements", queryState], context.previous);
                }
            },
            onSettled: () => {
                queryClient.invalidateQueries(["user.achievements", queryState]);
            },
        }
    );
    const setTopicPinned = useMutation<IUserTopic, unknown, { topicId: string; pinned: boolean }, { previous: IAchievement[] }>(
        [],
        ({ pinned, topicId }) => setUserTopic(topicId, { isPinned: pinned }),
        {
            onMutate: async (updatedCourse) => {
                await queryClient.cancelQueries(["user.achievements", queryState]);
                const previous = queryClient.getQueryData(["user.achievements", queryState]) as IAchievement[];
                queryClient.setQueryData<IAchievement[]>(["user.achievements", queryState], (old) =>
                    (old ?? []).map((a) =>
                        a.type === "topic" && a.topic === updatedCourse.topicId
                            ? {
                                  ...a,
                                  isPinned: updatedCourse.pinned,
                              }
                            : a
                    )
                );
                return { previous };
            },
            onError: (err, newTodo, context) => {
                if (context) {
                    queryClient.setQueryData(["user.achievements", queryState], context.previous);
                }
            },
            onSettled: () => {
                queryClient.invalidateQueries(["user.achievements", queryState]);
            },
        }
    );

    const [showSearchOptions, setShowSearchOptions] = useState(false);
    const toggleShowSerachOptions = useCallback(() => setShowSearchOptions((showSearchOptions) => !showSearchOptions), []);

    const setSortBy = useCallback(
        (sortBy: UserAchievementsSortBy) => {
            if (queryState.sortBy !== sortBy) {
                const newQueryState: UserAchievementsQuery = {
                    ...queryState,
                    sortBy,
                };
                setQueryState(newQueryState);
            }
        },
        [queryState]
    );

    const setSearchTerm = useCallback(
        (search: string) => {
            const newQueryState = {
                ...queryState,
                page: 0,
                search,
            };
            setQueryState(newQueryState);
        },
        [queryState]
    );

    const toggleInvertedSort = useCallback(() => {
        const newQueryState = {
            ...queryState,
            page: 0,
            invertSort: !queryState.invertSort,
        };
        setQueryState(newQueryState);
    }, [queryState]);

    return (
        <div className={styles.inner}>
            <div className={styles.top}>
                <div className={styles.title}>Badge Progress</div>
                <div className={styles.searchContainer}>
                    <input placeholder="Search" type={"text"} value={queryState.search} onChange={(e) => setSearchTerm(e.target.value)} />
                    <img src={searchIcon} alt={"search"} />
                </div>
                <div className={styles.sort}>
                    <span onClick={Object.keys(sortOptionsText).length > 1 ? toggleShowSerachOptions : undefined} className={styles.current}>
                        {sortOptionsText[queryState.sortBy]}
                        <div className={classNames(styles.options, { [styles.hidden]: !showSearchOptions })}>
                            {(Object.keys(sortOptionsText) as (keyof typeof sortOptionsText)[]).map((sortBy) => (
                                <div key={sortBy} onClick={() => setSortBy(sortBy)}>
                                    {sortOptionsText[sortBy]}
                                </div>
                            ))}
                        </div>
                    </span>
                    <img
                        src={arrowsIcon}
                        className={classNames(styles.arrow, {
                            [styles.flipped]: queryState.invertSort,
                        })}
                        alt="arrows"
                        onClick={toggleInvertedSort}
                    />
                </div>
            </div>

            <div className={styles.list}>
                {badges?.data?.map((badge, i) => (
                    <Badge
                        badge={badge}
                        key={i}
                        isPinned={badge.isPinned}
                        setPinned={
                            badge.type === "publisher"
                                ? (newValue) => setPublisherPinned.mutate({ pinned: newValue, publisherId: badge.publisher })
                                : badge.type === "topic"
                                ? (newValue) => setTopicPinned.mutate({ pinned: newValue, topicId: badge.topic })
                                : undefined
                        }
                    />
                ))}
            </div>
        </div>
    );
};

type BadgeProps = {
    badge: IAchievement;
    isPinned: boolean;
    setPinned?: (pinned: boolean) => void;
};
export const Badge = ({ badge, isPinned, setPinned }: BadgeProps) => {
    const invertPinning = useCallback(() => {
        setPinned?.(!isPinned);
    }, [isPinned, setPinned]);
    return (
        <div className={classNames(styles.badge)} style={{ borderColor: badge.isPinned ? badge.color : "transparent" }}>
            {badge.icon.type === "generic" ? (
                (() => {
                    const BadgeImage = badgeIconMap[badge.icon.name];
                    return <BadgeImage className={styles.icon} color={badge.color} />;
                })()
            ) : (
                <img className={styles.icon} src={badge.icon.type === "tag" && badge.icon.url === undefined ? tagImage : badge.icon.url} alt={""} />
            )}

            <div className={styles.content}>
                <div className={styles.name}>
                    {badge.name}{" "}
                    <span>
                        Lv.{badge.level + 1}
                        {!setPinned ? null : isPinned ? (
                            <svg
                                className={styles.pin}
                                width="15"
                                height="12"
                                viewBox="0 0 15 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                color={badge.color}
                                onClick={invertPinning}
                            >
                                <path
                                    d="M13.5161 0.000451349C13.1412 0.0132887 12.7859 0.175747 12.5259 0.453117C9.76677 3.29098 7.65945 5.66131 5.09892 8.34365L2.37107 5.97833V5.97848C2.07877 5.72479 1.70008 5.60084 1.31861 5.63416C0.937257 5.66747 0.584341 5.8553 0.338046 6.15591C0.0916067 6.45668 -0.0279694 6.84563 0.00553446 7.23703C0.0390383 7.62843 0.222938 7.99001 0.516574 8.24216L4.26158 11.4945V11.4944C4.53721 11.7326 4.88968 11.8562 5.24958 11.8408C5.60963 11.8255 5.95091 11.6722 6.20617 11.4112C9.30881 8.22004 11.5175 5.67135 14.5606 2.54143V2.54158C14.841 2.26343 14.9996 1.88058 15 1.4805C15.0006 1.08026 14.843 0.697118 14.5634 0.418202C14.2838 0.139291 13.906 -0.0113792 13.5163 0.00067104L13.5161 0.000451349Z"
                                    fill="currentColor"
                                />
                            </svg>
                        ) : (
                            <svg
                                className={styles.pin}
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                color={badge.color}
                                onClick={invertPinning}
                            >
                                <path
                                    d="M7.71161 15.1666C7.5071 15.1666 7.31096 15.0853 7.16628 14.9407C7.02168 14.7961 6.94043 14.5999 6.94043 14.3954V0.771206C6.94043 0.495659 7.08745 0.241105 7.32602 0.103364C7.56459 -0.0344546 7.85863 -0.0344546 8.0972 0.103364C8.33577 0.241099 8.48279 0.495638 8.48279 0.771206V14.3954C8.48279 14.5999 8.40154 14.7961 8.25694 14.9407C8.11226 15.0853 7.91612 15.1666 7.71161 15.1666Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M14.6525 8.48279H0.771206C0.495659 8.48279 0.241105 8.33577 0.103364 8.0972C-0.0344546 7.85863 -0.0344546 7.56459 0.103364 7.32602C0.241099 7.08745 0.495638 6.94043 0.771206 6.94043H14.6525C14.928 6.94043 15.1826 7.08745 15.3203 7.32602C15.4581 7.56459 15.4581 7.85863 15.3203 8.0972C15.1826 8.33577 14.928 8.48279 14.6525 8.48279Z"
                                    fill="currentColor"
                                />
                            </svg>
                        )}
                    </span>
                </div>
                <div className={styles.subtext}>{badge.description}</div>
                <div className={styles.progressBarOuter}>
                    <div
                        className={styles.progressBar}
                        style={{
                            borderColor: badge.color,
                        }}
                    >
                        <div
                            className={styles.progressBarInner}
                            style={{
                                width: `${(badge.progress / badge.outOf) * 100}%`,
                                backgroundColor: badge.color,
                            }}
                        />
                    </div>
                    <div className={styles.text}>
                        {badge.progress}/<span>{badge.outOf}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
