export const MOBILE_BREAKPOINT = 992;
export const MIDDLE_BREAKPOINT = 1260;
export const BASE_URL = process.env.REACT_APP_BASE ? `https://api.${process.env.REACT_APP_BASE}/api` : "http://localhost:4000/api"; //"https://192.168.0.122:4000/api";
export const WEB_BASE_URL = process.env.REACT_APP_BASE ? `https://${process.env.REACT_APP_BASE}` : "http://localhost:3000";
// TODO: to read this from an env var
export const GOOGLE_CLIENT_ID = "651290814956-62jvlk1pod5lleotrr7f0fjj0n7p8acr.apps.googleusercontent.com";
export const VERSION = process.env.REACT_APP_VERSION;
export const REPORTING_ENABLED = process.env.NODE_ENV === "production";

export const EXTENSION_PATH = "/retainit-extension.zip";
