import { _throw, useColourSample, useTitle } from "@retainit/shared";
import classNames from "classnames";
import debounce from "lodash/debounce";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";

import React, { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import arrowsIcon from "../../assets/arrows.svg";
import searchIcon from "../../assets/search.svg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { get, ILibraryState } from "../../store/librarySlice";
import { getLibraryItems, LibrarySortBy } from "../../store/requests";
import { ListCard } from "../common/FreeCarousel/ListCard";
import styles from "./Library.module.scss";
import { NoResultsCard, WelcomeCard } from "./WelcomeCard";

type LibraryPageProps = {};

const searchOptionsText: Record<LibrarySortBy, string> = {
    addedAt: "Date Added",
    lastReviewed: "Last Reviewed",
    name: "Name",
    publisher: "Publisher Name",
};

type QueryState = {
    search: string;
    sortBy: LibrarySortBy;
    invertSort: boolean;
    page: number | null;
};
export const LibraryPage = (__: LibraryPageProps) => {
    useTitle("Retainit - Library");

    const library = useAppSelector((state) => state.library) as ILibraryState;
    const dispatch = useAppDispatch();
    const [mode, setMode] = useState<"loading" | "loading-more" | "done">("done");

    const [queryState, setQueryState] = useState<QueryState>({
        search: "",
        sortBy: "addedAt",
        invertSort: false,
        page: 0,
    });
    const [showSearchOptions, setShowSearchOptions] = useState(false);

    const load = useCallback(
        debounce(
            (queryState: QueryState) => {
                setMode("loading");
                getLibraryItems(queryState.sortBy, queryState.search === "" ? undefined : queryState.search, queryState.invertSort, 0).then(
                    (data) => {
                        dispatch(get(data));
                        setMode("done");
                    }
                );
            },
            750,
            { leading: true, trailing: true }
        ),
        []
    );

    useEffect(() => {
        load(queryState);
    }, []);

    const loadMore = useCallback(() => {
        if (queryState.page !== null && mode !== "loading-more") {
            const pageToLoad = queryState.page + 1;

            setMode("loading-more");

            getLibraryItems(queryState.sortBy, queryState.search === "" ? undefined : queryState.search, queryState.invertSort, pageToLoad).then(
                (data) => {
                    if (data.quizzes.length === 0) {
                        setQueryState({
                            ...queryState,
                            page: null,
                        });
                    } else {
                        setQueryState({
                            ...queryState,
                            page: pageToLoad,
                        });
                        dispatch(
                            get({
                                ...library,
                                quizzes: [...library.quizzes, ...data.quizzes],
                            })
                        );
                    }
                    setMode("done");
                }
            );
        }
    }, [dispatch, library, mode, queryState]);

    const setSortBy = useCallback(
        (sortBy: LibrarySortBy) => {
            if (queryState.sortBy !== sortBy) {
                const newQueryState: QueryState = {
                    ...queryState,
                    sortBy,
                };
                setQueryState(newQueryState);
                load(newQueryState);
            }
        },
        [load, queryState]
    );

    const setSearchTerm = useCallback(
        (search: string) => {
            const newQueryState = {
                ...queryState,
                page: 0,
                search,
            };
            setQueryState(newQueryState);
            load(newQueryState);
        },
        [load, queryState]
    );

    const toggleInvertedSort = useCallback(() => {
        const newQueryState = {
            ...queryState,
            page: 0,
            invertSort: !queryState.invertSort,
        };
        setQueryState(newQueryState);
        load(newQueryState);
    }, [load, queryState]);

    const colors = useColourSample();

    const toggleShowSerachOptions = useCallback(() => {
        setShowSearchOptions((showSearchOptions) => !showSearchOptions);
    }, []);

    useOnScrollToButton(loadMore);

    const now = new Date();

    const withColor = library.quizzes.map((it, index) => ({ ...it, color: colors[index % colors.length] }));

    const grouped = (() => {
        if (queryState.sortBy === "addedAt") {
            const sortedQuizzes = sortBy(withColor, (it) => it.addedAt);
            return groupBy(sortedQuizzes, (item) => {
                const yearsAgo = Math.floor((now.getTime() - new Date(item.addedAt).getTime()) / (1000 * 60 * 60 * 24 * 365));
                if (yearsAgo !== 0) {
                    return `5-years-${yearsAgo}`;
                }
                const monthsAgo = Math.floor((now.getTime() - new Date(item.addedAt).getTime()) / (1000 * 60 * 60 * 24 * 30));
                if (monthsAgo !== 0) {
                    return `4-months-${monthsAgo}`;
                }
                const weeksAgo = Math.floor((now.getTime() - new Date(item.addedAt).getTime()) / (1000 * 60 * 60 * 24 * 7));
                if (weeksAgo !== 0) {
                    return `3-weeks-${weeksAgo}`;
                }
                const daysAgo = Math.floor((now.getTime() - new Date(item.addedAt).getTime()) / (1000 * 60 * 60 * 24));
                if (daysAgo === 0) {
                    return `0-today`;
                } else if (daysAgo === 1) {
                    return `1-yesterday`;
                } else {
                    return `2-days-${daysAgo}`;
                }
            });
        } else if (queryState.sortBy === "name") {
            return groupBy(withColor, (item) => item.media.title[0].toUpperCase());
        } else if (queryState.sortBy === "publisher") {
            return groupBy(withColor, (item) => item.publisher.name[0].toUpperCase());
        } else {
            return groupBy(withColor, (item) => "");
        }
    })();

    const sorted = Object.keys(grouped)
        .sort((a, b) => (a > b ? 1 : a === b ? 0 : -1) * (queryState.invertSort ? -1 : 1))
        .map((key) => {
            const [first, interval, ago] = key.split("-");
            return {
                first,
                interval,
                ago,
                items: grouped[key]!,
                hidden: interval === undefined,
            };
        });

    return (
        <div className={styles.inner}>
            <div className={styles.top}>
                <div className={styles.title}>Library</div>
                <div className={styles.searchContainer}>
                    <input placeholder="Search" type={"text"} value={queryState.search} onChange={(e) => setSearchTerm(e.target.value)} />
                    <img src={searchIcon} alt={"search"} />
                </div>
                <div className={styles.sort}>
                    <span onClick={toggleShowSerachOptions} className={styles.current}>
                        {searchOptionsText[queryState.sortBy]}
                        <div className={classNames(styles.options, { [styles.hidden]: !showSearchOptions })}>
                            {(Object.keys(searchOptionsText) as (keyof typeof searchOptionsText)[]).map((sortBy) => (
                                <div key={sortBy} onClick={() => setSortBy(sortBy)}>
                                    {searchOptionsText[sortBy]}
                                </div>
                            ))}
                        </div>
                    </span>
                    <img
                        src={arrowsIcon}
                        className={classNames(styles.arrow, {
                            [styles.flipped]: queryState.invertSort,
                        })}
                        alt="arrows"
                        onClick={toggleInvertedSort}
                    />
                </div>
            </div>

            {mode === "loading" && <Spinner animation="border" variant="primary" style={{ margin: "auto" }} />}
            {mode !== "loading" && library.quizzes.length === 0 && queryState.search === "" && <WelcomeCard />}
            {mode !== "loading" && library.quizzes.length === 0 && queryState.search !== "" && <NoResultsCard />}
            {mode !== "loading" && library.quizzes.length !== 0 && (
                <div className={styles.items}>
                    {sorted.map((group, i) => (
                        <div className={styles.itemGroup} key={i}>
                            <div className={styles.left}>
                                {queryState.sortBy === "addedAt" ? (
                                    <>
                                        {group.hidden ? (
                                            <></>
                                        ) : group.interval === "today" ? (
                                            <div className={styles.days}>Today</div>
                                        ) : group.interval === "yesterday" ? (
                                            <div className={styles.days}>Yesterday</div>
                                        ) : (
                                            <div className={styles.days}>
                                                {group.ago} {setPlural(group.interval, group.ago !== "1")} ago
                                            </div>
                                        )}
                                    </>
                                ) : queryState.sortBy === "name" ? (
                                    <div className={styles.days}>{group.first}</div>
                                ) : queryState.sortBy === "publisher" ? (
                                    <div className={styles.days}>{group.first}</div>
                                ) : queryState.sortBy === "lastReviewed" ? (
                                    <div className={styles.days}>
                                        {group.hidden ? (
                                            <></>
                                        ) : group.interval === "today" ? (
                                            <div className={styles.days}>Today</div>
                                        ) : group.interval === "yesterday" ? (
                                            <div className={styles.days}>yesterday</div>
                                        ) : (
                                            <div className={styles.days}>
                                                {group.ago} {group.interval} ago
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    _throw("Unimplemented")
                                )}
                            </div>
                            <div className={styles.right}>
                                {group.items.map(
                                    (item) =>
                                        item?.media?.id && (
                                            <ListCard
                                                media={item.media}
                                                review={item.review}
                                                key={item.media.id}
                                                selected={false}
                                                color={item.color}
                                            />
                                        )
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {mode === "loading-more" && <Spinner animation="border" variant="primary" style={{ margin: "auto" }} />}
        </div>
    );
};

const useOnScrollToButton = (onScroll: () => void, threshold: number = 0.9) => {
    useEffect(() => {
        const listener = () => {
            const scrollingElement = document.scrollingElement;
            if (!scrollingElement) {
                return;
            }
            const bottom = scrollingElement.scrollTop + scrollingElement.clientHeight;
            const scrolled = bottom / scrollingElement.scrollHeight;

            if (scrolled > threshold) {
                onScroll();
            }
        };

        document.addEventListener("scroll", listener);
        return () => {
            document.removeEventListener("scroll", listener);
        };
    }, [onScroll, threshold]);
};

const setPlural = (interval: string, plural: boolean) => {
    switch (interval) {
        case "days":
            return plural ? "days" : "day";
        case "weeks":
            return plural ? "weeks" : "week";
        case "months":
            return plural ? "months" : "month";
        case "years":
            return plural ? "years" : "year";
        default:
            return interval;
    }
};
