import { AppConfigContext, getLevel, QuizModal, useColourSample, useStarsAnimation, useTitle } from "@retainit/shared";
import classNames from "classnames";
import classnames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import article from "../../assets/article.svg";
import emptyHead from "../../assets/empty-head.svg";
import speechBubble from "../../assets/speech-bubble.svg";
import { VERSION } from "../../config";
import { deleteReview, getReviews, requestReminder, ReviewItem } from "../../store/requests";
import ReviewCard from "./ReviewCard";
import styles from "./ReviewPage.module.scss";

export const ReviewPage = () => {
    useTitle("Retainit - Review");

    const [reviewData, setReviewData] = useState<ReviewItem[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { refreshStats, stats } = useContext(AppConfigContext);
    const [activeIndex, setActiveIndex] = useState(0);

    const [mode, setMode] = useState<"loading" | "working" | "empty" | "done">("loading");
    const [launchQuiz, setLaunchQuiz] = useState(false);

    useEffect(() => {
        getReviews().then((data) => {
            const items = data ?? [];
            setReviewData(items);
            setMode(items ? "working" : "empty");
            setIsLoading(false);
        });
    }, []);

    const handleLaunch = () => {
        setLaunchQuiz(true);
    };
    const handleDelete = async () => {
        const mediaId = reviewData[activeIndex]?.media.id;
        const response = await deleteReview(mediaId);
        if (!response) {
            return;
        }
        if (reviewData.length === 1) {
            setMode("done");
        }
        setReviewData(reviewData.slice(1));
        refreshStats();
    };

    const handleCancel = async () => {
        await handleQuizClose({ skipped: true });
        refreshStats();
    };

    const handleQuizClose = async (quizStatus: { completed?: boolean; skipped?: boolean; openNext?: any }) => {
        refreshStats();
        const mediaId = reviewData[activeIndex]?.media.id;

        if (quizStatus.completed || quizStatus.skipped) {
            const isSkipped = await requestReminder(null, mediaId, true);
            if (isSkipped) {
                if (reviewData.length === 1) {
                    setMode("done");
                }
                // Remove element at index 5 from reviewData
                setReviewData([...reviewData.slice(0, activeIndex), ...reviewData.slice(activeIndex + 1)]);
            }
        }
        if (!quizStatus.openNext) {
            setLaunchQuiz(false);
        }
    };

    const handleRevChange = (index: number) => {
        setActiveIndex(index);
    };

    const [{ characterImage: CharacterImage }] = getLevel(stats.xp);

    const colors = useColourSample();

    if (isLoading) {
        return <div className={styles.outer}>{isLoading && <Spinner animation="border" variant="primary" className={styles.spinner} />}</div>;
    }

    return (
        <>
            <div className={classnames(styles.outer)}>
                <div className={styles.title}>Weekly Review</div>
                <div className={styles.inner}>
                    {mode === "loading" && <Spinner animation="border" variant="primary" className={styles.spinner} />}
                    {reviewData.length === 0 && mode !== "done" && <EmptyPage />}
                    {reviewData.length === 0 && mode === "done" && <CompletedPage />}
                    {reviewData.length !== 0 && (
                        <>
                            <div className={styles.review}>
                                <div className={styles.left}>
                                    <ReviewCard
                                        key={reviewData[activeIndex].media.id}
                                        media={reviewData[activeIndex].media}
                                        handleSkip={handleCancel}
                                        handleLaunch={handleLaunch}
                                        handleDelete={handleDelete}
                                    />
                                </div>
                                <div className={styles.right}>
                                    <div className={styles.top}>Next Up ({reviewData.length - 1})</div>
                                    <div className={styles.items}>
                                        {reviewData.map((it, i) => (
                                            <div className={styles.item} style={{ backgroundColor: colors[i % colors.length] }} key={i}>
                                                {it.media.title}
                                                {it.media.type === "video" ? (
                                                    <img className={styles.image} src={`https://img.youtube.com/vi/${it.media.ytbId}/0.jpg`} alt="" />
                                                ) : it.media.image ? (
                                                    <img className={styles.image} src={it.media.image} alt={"article"} />
                                                ) : (
                                                    <img className={styles.image} src={article} alt={"article"} />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className={classNames(styles.character, styles.small)}>
                                <div className={styles.sheachBubble}>
                                    <span>Some motivational line or fact about Spaced Repetition.</span>
                                    <img src={speechBubble} alt={"speech bubble"} />
                                </div>
                                <CharacterImage />
                            </div>
                        </>
                    )}
                </div>
            </div>

            {launchQuiz && (
                <QuizModal
                    quizId={reviewData[activeIndex]?.quiz}
                    meta={{
                        title: reviewData[activeIndex]?.media?.title,
                        pubLogo: reviewData[activeIndex]?.media?.publisher?.logo,
                    }}
                    handleClose={handleQuizClose}
                    origin={`dashboard/review@${VERSION}`}
                    nextUp={reviewData.slice(1)}
                />
            )}
        </>
    );
};

const CompletedPage = () => {
    const { stars, play } = useStarsAnimation();
    const { stats } = useContext(AppConfigContext);

    useEffect(() => {
        play();
    }, [play]);

    const [{ characterImage: CharacterImage }] = getLevel(stats.xp);

    return (
        <>
            <div className={classnames(styles.done)}>
                <div className={styles.text}>Bravo! {stars}</div>
                <div className={styles.subtext}>You’ve completed this week’s review.</div>
            </div>
            <div className={styles.character}>
                <div className={styles.sheachBubble}>
                    <span>Keep saving quizzes to review next week!</span>
                    <img src={speechBubble} alt={"speech bubble"} />
                </div>
                <CharacterImage />
            </div>
        </>
    );
};
const EmptyPage = () => (
    <div className={classnames(styles.empty)}>
        <img src={emptyHead} alt={"finished"} />
        <div className={styles.text}>
            Just because your review’s empty <br /> doesn’t mean your head is too.
        </div>
        <div className={styles.subtext}>Save a quiz to start Retaining!</div>
    </div>
);
