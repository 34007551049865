import classnames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import article from "../../../assets/article.svg";
import { convertHMS } from "../../../utils";
import styles from "./FreeCarousel.module.scss";

type LightCardProps = {
    onClick?: () => void;
    media: any;
    className?: any;
    size?: "normal" | "large";
};

function LightCard(props: LightCardProps) {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(`/watch/${props.media.id}`, { replace: false });
        if (props.onClick) {
            props.onClick();
        }
    };

    return (
        <div
            className={classnames(styles["light-card"], {
                [props.className]: props.className,
                [styles.large]: (props.size ?? "normal") === "large",
            })}
            onClick={handleClick}
        >
            {props.media.type === "video" ? (
                <div className={styles["image"]}>
                    <img src={`https://img.youtube.com/vi/${props.media.ytbId}/0.jpg`} alt="" />
                    {props.media.mediaLength && <div className={styles["timing"]}>{convertHMS(props.media.mediaLength)}</div>}
                </div>
            ) : props.media.image ? (
                <div className={styles["image"]}>
                    <img src={props.media.image} alt={"article"} />
                </div>
            ) : (
                <div className={styles["article"]}>
                    <img src={article} alt={"article"} />
                </div>
            )}
            <div className={styles["title-container"]}>
                <img src={props.media.publisher.logo} width="28" height="28" />
                <div className={styles["text"]}>{`${props.media.title} | ${props.media.publisher.name}`}</div>
            </div>
        </div>
    );
}

export default LightCard;
