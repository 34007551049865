import React from "react";
import styles from "./Policy.module.scss";

function Policy(props: any) {
    return (
        <div className={styles["policy"]}>
            <h1>PRIVACY POLICY</h1>
            <p>This page sets out when and how we use the personal data that you provide to us.</p>
            <ol className={styles["top-level"]}>
                <li>
                    <h2>Who we are</h2>
                    <ol>
                        <li>
                            We are Retainit Holdings Ltd. ( <b>“we”</b>, <b>“us”</b>, <b>“our”</b>) a company registered in England and Wales with
                            company number 13121308 with its registered office at 34 Cloncurry St, Fulham, London, SW6 6DU.
                        </li>
                        <li>
                            We respect your privacy and are committed to protecting your personal data. This page (the <b>“Privacy Policy”</b>) sets
                            out how we collect, process and look after your personal data when you visit our website, or use our browser extension or
                            application (together, our <b>“Site”</b>) (regardless of where you visit it from) and tell you about your privacy rights
                            and how the law protects you. This includes any data you may provide through our Site when you create or update an
                            account.
                        </li>
                        <li>
                            Please read this Privacy Policy carefully and make sure you agree with the terms, before you provide us with any of your
                            personal data, including creating an account with us.
                        </li>
                        <li>Our Site is not intended for use by children and we do not knowingly collect data relating to children.</li>
                        <li>
                            It is important that the personal data we hold about you is accurate and current. Please keep us informed if any of your
                            personal data that we process changes at any time.
                        </li>
                    </ol>
                </li>
                <li>
                    <h2>OTHER DOCUMENTS WHICH MAY APPLY</h2>
                    <ol>
                        <li>
                            It is important that you read this Privacy Policy, together with our <a href="/beta-policy">Beta Terms and Conditions</a>{" "}
                            (the <b>“Terms”</b>), so that you are fully aware of how and why we are using your data. This Privacy Policy is in
                            addition to the Terms and is not intended to override them.
                        </li>
                        <li>
                            Unless we say otherwise, all capitalised terms in this Privacy Policy shall have the meaning given to them in the Terms.
                        </li>
                    </ol>
                </li>
                <li>
                    <h2>CHANGES TO THE PRIVACY POLICY</h2>
                    <ol>
                        <li>
                            We may make changes to this Privacy Policy at any time by amending this page, including in order to: (i) comply with any
                            changes to the law or regulations that affect us; or (ii) to reflect any technical changes, such as to respond to security
                            breaches. We will try to give you reasonable notice if we do this (including, if appropriate, by email).
                        </li>
                        <li>
                            When amendments are made, we will update the “last updated” date at the top of this Privacy Policy. Every time you wish to
                            use our Site or any of Our Content, please check this page to ensure you understand the rules that apply at that time.
                        </li>
                        <li>
                            If you do not agree to any changes to the Privacy Policy, you may close your account and/or exercise any of your rights
                            detailed in section 12 below, by contacting us at admin@retainit.app
                        </li>
                    </ol>
                </li>
                <li>
                    <h2>Who is responsible for my personal data?</h2>
                    <ol>
                        <li>
                            We, Retainit Holdings Ltd., are a company that creates educational materials (<b>“Our Content”</b>), based on selected
                            Third-Party Content, such as podcasts, videos and online articles, and we make Our Content available to our registered
                            users to enhance their media experience and supplement the Third-Party Content. We are the party responsible for making
                            decisions about how your personal data is used (the data controller) in connection with your use of our Site.
                        </li>
                    </ol>
                </li>
                <li>
                    <h2>WHAT IS PERSONAL DATA?</h2>
                    <ol>
                        <li>
                            Personal data, or personal information, is information that we can use to identify you, for example, your name or your
                            email address.
                        </li>
                    </ol>
                </li>
                <li>
                    <h2>How do WE use YOUR PERSONAL DATA?</h2>
                    <ol>
                        <li>
                            When we process personal data about you, we are required to have a legal basis for doing so. Please see below for
                            information about how we collect and use your personal data.
                        </li>
                        <br />
                        <p>
                            <i>Information you give us:</i>
                        </p>
                        <li>
                            <b>When you create an account with us</b>
                            <ol>
                                <li>
                                    When you create an account with us, we will use the personal data that you give to us to complete this process. We
                                    will ask you to provide your name and email address.
                                </li>
                                <li>We need to process your personal data in this way to enter into and perform the contract with you.</li>
                            </ol>
                        </li>
                        <li>
                            <b>When you sign up for a Premium Account</b>
                            <ol>
                                <li>
                                    Where you upgrade to a Premium Account in accordance with the Terms, we will also ask you to provide your name,
                                    billing address, and payment details. We will use this personal data to complete the upgrade and process payment
                                    for the Premium Account.
                                </li>
                                <li>
                                    To complete the upgrade and process the payment for a Premium Account, we will share your personal data with our
                                    subcontractors who are involved in the upgrade process, such as payment providers, as well as credit reference
                                    agencies who we use to assess fraud, credit and/or security risks.
                                </li>
                                <li>
                                    We need to process your personal data in this way to enter into and perform the contract for the Premium Account
                                    that you are purchasing from us.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <b>If you agree to access our Site as part of a trial we offer</b>
                            <ol>
                                <li>
                                    We may provide a limited number of users with access to our Site as part of a trial to allow us to test user
                                    experience and better understand how our Site functions, and/or allow us to improve our Site and Our Content. If
                                    you agree to take part in - and we provide you with access for - the trial, we will ask you to provide your name
                                    and email address.
                                </li>
                                <li>
                                    As part of the trial, we may also ask you to provide additional data in order to analyse users’ preferences, for
                                    example, we may ask you to provide data such as your age and occupation.
                                </li>
                                <li>
                                    We need to process your personal data in this way to enter into a contract with you and for our legitimate
                                    interest to provide you with the best experience we can and improve our products and services, including Our
                                    Content.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <b>When you have opted-in to receive our marketing emails or have expressed an interest in Our Content</b>
                            <ol>
                                <li>
                                    If you have opted-in to receiving marketing emails via our Site, we will handle your personal data (such as your
                                    name and email address) to provide you with marketing communications in line with any preferences you have told us
                                    about.
                                </li>
                                <li>
                                    When we send you marketing emails, if you opted-in to receive these emails via our Site, we rely on your consent
                                    to contact you for marketing purposes. If you are a business and did not opt in, we rely on our legitimate
                                    interest to promote our business to send you marketing emails. Every email we send to you for marketing purposes
                                    will also contain instructions on how to unsubscribe from receiving them.
                                </li>
                                <li>You are not under any obligation to provide us with your personal data for marketing purposes.</li>
                                <li>
                                    You can tell us that you do not want your personal data to be processed in this way at any time by contacting us
                                    at admin@retainit.app or, where relevant, by following the unsubscribe link shown in the marketing communications
                                    you receive from us.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <b>When you contact us</b>
                            <ol>
                                <li>
                                    When you phone, email or otherwise contact us, we may also handle your personal data (your name, contact details
                                    and the other details you provide to us during the interaction) in order to provide the customer services you have
                                    asked us to. This could be when you make an enquiry about, or report a problem with, our Site, our accounts or Our
                                    Content.
                                </li>
                                <li>
                                    We rely on your consent to handle your personal data in this way. If you do not provide us with the personal data
                                    we request from you for customer services purposes, we may not be able to fully deal with your enquiry or problem.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <b>To make our Site better</b>
                            <ol>
                                <li>
                                    We will also use your personal data to provide you with a more effective user experience (such as by displaying
                                    items of Our Content we think you will be interested in, based on your browsing habits or items of Our Content
                                    that you have previously shown an interest in).
                                </li>
                                <li>
                                    Our use of your personal data in this way means that your experience of our Site will be more tailored to you, and
                                    that the items of Our Content that you see on our Site may differ from someone accessing our Site with a different
                                    download history or browsing habits.
                                </li>
                                <li>
                                    We also share your aggregated, anonymous data with third-party analytics and search engine providers that assist
                                    us in the improvement and optimisation of our Site.
                                </li>
                                <li>
                                    We will also use your personal data for the purposes of making our Site more secure, and to administer our Site
                                    and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey
                                    purposes.
                                </li>
                                <li>
                                    We process your data for this reason because we have a legitimate interest to provide you with the best experience
                                    we can, and to ensure that our Site is kept secure.
                                </li>
                                <li>
                                    You can prevent us from using your personal data in this way by using the 'do not track' functionality in your
                                    internet browser. If you enable “do not track functionality”, our Site may be less tailored to your needs and
                                    preferences.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <b>If our business is sold</b>
                            <ol>
                                <li>
                                    We will transfer your personal data to a third party:
                                    <ol className={styles["lettered"]}>
                                        <li>
                                            if we sell any of our business or assets, we will provide your personal data to the buyer (but only to the
                                            extent we need to, and always in accordance with applicable data protection legislation); or
                                        </li>
                                        <li>
                                            if we, or the majority of our assets, are acquired by somebody else, in which case the personal data held
                                            by us will be transferred to the buyer.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    We process your personal data for this purpose because we have a legitimate interest to ensure our business can be
                                    continued by the buyer. If you object to our use of your personal data in this way, the relevant buyer of our
                                    business may not be able to provide services to you or perform the contract with you.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <b>When required by law</b>
                            <ol>
                                <li>
                                    We may disclose your personal data if we consider it is reasonably necessary: (i) to comply with a legal process,
                                    such as a court order, subpoena or search warrant, government or law enforcement investigation or other legal
                                    requirements; (ii) to assist in the prevention or detection of crime (subject in each case to applicable law); or
                                    (iii) to protect the safety of any person.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <b>To enforce legal rights</b>
                            <ol>
                                <li>
                                    We may also share information: (i) if disclosure would mitigate our liability in an actual or threatened lawsuit;
                                    (ii) as necessary to protect our legal rights and legal rights of our users, business partners or other interested
                                    parties; (iii) to enforce our agreements with you; and (iv) to investigate, prevent, or take other action
                                    regarding illegal activity, suspected fraud or other wrongdoing.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <b>With your consent or at your request</b>
                            <ol>
                                <li>
                                    We may ask for your consent to share your personal data with third parties in addition to those already set out in
                                    this Privacy Policy. In any such case, we will make it clear why we want to share the information.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <h2>what about technical information and analytics</h2>
                    <ol>
                        <li>
                            <b>Information we collect about you:</b>
                            <ol>
                                <li>
                                    When you visit our Site we will automatically collect the following information:
                                    <ol className={styles["lettered"]}>
                                        <li>
                                            technical information, including the Internet protocol (IP) address used to connect your device to the
                                            internet, your login information, browser type and version, time zone setting, browser plug-in types and
                                            versions, screen resolution, operating system and platform;
                                        </li>
                                        <li>
                                            information about your visit, including the full Uniform Resource Locators, clickstream to, through and
                                            from our Site (including date and time), page response times, download errors, length of visits to certain
                                            pages, page interaction information (such as scrolling, clicks, and mouse-overs) and methods used to
                                            browse away from the page)
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <h2>Cookies</h2>
                    <ol>
                        <li>
                            Like most websites, Our Site uses cookies to distinguish you from other users of our Site. This helps us to provide you
                            with a good experience when you use our Site and also allows us to improve our Site. By continuing to browse our Site, you
                            are agreeing to our use of cookies
                        </li>
                        <li>
                            A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer. We only
                            use (and store) non-essential cookies on your computer's browser or hard drive if you provide your consent.
                        </li>
                        <li>
                            We use cookies and similar technologies in some or all of the following ways when you use our Site:
                            <table>
                                <thead>
                                    <th>Cookie Type</th>
                                    <th>What does it do?</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Strictly necessary cookies</td>
                                        <td>These cookies are necessary to allow us to operate the Retainit service as you have requested.</td>
                                    </tr>
                                    <tr>
                                        <td>Session cookies</td>
                                        <td>
                                            These cookies allow us to recognise users and the information provided when they are using the website.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Persistent cookies</td>
                                        <td>
                                            These cookies are stored on your browser after you leave our Site. We use them to store information about
                                            your preferences or actions between browser sessions. They remain on your browser until they expire or
                                            until you delete them.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Performance cookies</td>
                                        <td>
                                            We use these cookies to monitor Site performance in order to ensure a high-quality experience on our Site.
                                            For example, we use performance cookies to track error messages and to identify possible improvements to
                                            our Site.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Functionality cookies</td>
                                        <td>
                                            We use these cookies to improve how efficiently our Site works and to improve and optimise your experience
                                            of using our Site. For example, we identify which device you use as well as the software version to ensure
                                            that our Site functions correctly.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Analytics cookies</td>
                                        <td>
                                            These cookies help us understand our users’ engagement with our Content and third-party content. For
                                            example, we use analytics cookies to recommend specific content based on your wider interests.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </li>
                        <li>
                            Please note that third parties (including, for example, advertising networks and providers of external services like web
                            traffic analysis services) may also use cookies, which we do not have any control over. These cookies are likely to be
                            analytical or performance cookies or targeting cookies.
                        </li>
                        <li>
                            You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some
                            cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able
                            to access all or parts of our Site.
                        </li>
                        <li>Except for essential cookies, all cookies will expire after 2 years.</li>
                    </ol>
                </li>
                <li>
                    <h2>where is my data stored?</h2>
                    <ol>
                        <li>The data that we collect from you will be stored in a location [within the United Kingdom].</li>
                        <li>All information you provide to us is stored on our secure servers or those of our third-party service providers.</li>
                    </ol>
                </li>
                <li>
                    <h2>how long do we retain your data for?</h2>
                    <ol>
                        <li>
                            We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for.
                            This includes satisfying any legal, regulatory and reporting requirements.
                        </li>
                    </ol>
                </li>
                <li>
                    <h2>Your right to object under data protection laws</h2>
                    <ol>
                        <li>
                            You have the right to object to us handling your personal data when:
                            <ol>
                                <li>
                                    we are handling your personal data based on our legitimate interests (as described in the “How do you use my data”
                                    section above). If you ask us to stop handling your personal data in this way, we will stop unless we can show you
                                    that we have compelling grounds as to why our use of your personal data should continue; or
                                </li>
                                <li>
                                    we rely on your consent to handle your personal data in that way and you have withdrawn your consent for us to do
                                    so; or
                                </li>
                                <li>for marketing purposes. If you ask us to stop handling your personal data on this basis, we will stop.</li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <h2>What are my rights under data protection laws?</h2>
                    <ol>
                        <li>
                            You have various rights under the data protection laws, which you can exercise by contacting us. The easiest way to do
                            this is by email at admin@retainit.app.
                        </li>
                        <li>
                            In certain circumstances, if you are a UK or EEA resident, you may exercise the rights available to you under applicable
                            data protection laws as follows:
                            <ol>
                                <li>
                                    if you wish to <b>access</b>, <b>correct</b>, <b>update</b> or <b>request deletion</b> of your personal data;
                                </li>
                                <li>
                                    you can <b>object to the processing</b> of your personal data, ask us to <b>restrict processing</b> of your
                                    personal data or request <b>portability</b> of your personal data;
                                </li>
                                <li>
                                    if we have collected and processed your personal data with your consent, then you can withdraw your consent at any
                                    time. This may mean your access to certain parts of our Site, Our Content or other services or content is
                                    restricted or denied as a result. Please note that <b>withdrawing your consent</b> will not affect the lawfulness
                                    of any processing we conducted before you withdrew your consent, nor will it affect us processing your personal
                                    data conducted where we rely on another lawful processing grounds (other than consent).
                                </li>
                                <li>
                                    you have the right to complain to a data protection authority about our collection and use of your personal data.
                                    For more information, please contact your local data protection authority (in the UK this is the{" "}
                                    <a href="https://ico.org.uk/global/contact-us/">ICO</a>).
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <h2>SECURITY - HOW DO WE PROTECT YOUR PERSONAL DATA?</h2>
                    <ol>
                        <li>
                            We have implemented, and will maintain current, reasonable physical, technical, and organisational security measures to
                            protect your personal data from loss, misuse, and unauthorized access, disclosure, alteration, or destruction.
                        </li>
                        <li>
                            However, unfortunately, the transmission of information via the internet is never completely secure. Although we have
                            security measures in place to protect your personal data, we cannot guarantee the security of your data transmitted to our
                            Site; any transmission is at your own risk.
                        </li>
                    </ol>
                </li>
                <li>
                    <h2>what about websites we link to and third-party content?</h2>
                    <ol>
                        <li>
                            Our Site contains links to and from the websites of third parties and provides access to Third-Party Content from various
                            sources.
                        </li>
                        <li>
                            Our Site connects you to different websites and Third-Party Content. If you access Third-Party Content, please note that
                            the providers of such Third-Party Content may have their own privacy policies.
                        </li>
                        <li>
                            We do not accept any responsibility or liability for these policies or websites. Please check the relevant website’s or
                            provider’s policy before you submit any personal data to these websites.
                        </li>
                    </ol>
                </li>
                <li>
                    <h2>how do i contact you with feedback?</h2>
                    <ol>
                        <li>
                            If you have any questions about this Privacy Policy, including any requests to exercise your legal rights, please contact
                            us using the details set out below.
                            <p>Email address: admin@retainit.app</p>
                            <p>Postal address: 34 Cloncurry St, Fulham, London, SW6 6DU</p>
                            <p>Telephone number: +44 7908 998343</p>
                        </li>
                    </ol>
                </li>
            </ol>
        </div>
    );
}

export default Policy;
