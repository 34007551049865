import {
    createChapter,
    createMediaForLink,
    createQuestion,
    deleteChapter,
    getMedia,
    getQuiz,
    getTranscript,
    saveChapter,
    updateQuestion,
    updateQuestionsForChapter,
} from "@retainit/shared";
import mutate from "immutability-helper";
import groupBy from "lodash/groupBy";
import isEqual from "lodash/isEqual";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { getMediaForLink } from "../../store/requests";
import { notNullish } from "../../utils";
import {
    ABQuestion,
    ArticleMedia,
    BaseMedia,
    Blank,
    BlankChoiceQuestion,
    Chapter,
    EditorState,
    InflationQuestion,
    Inputting,
    Line,
    MatchingQuestion,
    Media,
    MultipleChoiceQuestion,
    Pair,
    Question,
    SingleWrittenQuestion,
    SortCategoriesQuestion,
    SpotifyMedia,
    SupplyDemandQuestion,
    Takeaway,
    Transcript,
    YoutubeMedia,
} from "./editorTypes";

export const _throw = (message: string | Error): never => {
    if (typeof message === "string") {
        throw new Error(message);
    } else {
        throw message;
    }
};

export const parseYoutubeLinkId = (link: string): string | null => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = link.match(regExp);
    return match && match[7].length === 11 ? match[7] : null;
};

export const parseSpotifyLinkId = (link: string): string | null => {
    try {
        // valid protocol and host
        const url = new URL(link);
        if (url.origin === "https://open.spotify.com") {
            const regExp = /^\/(embed-podcast\/)?episode\/([a-zA-Z0-9]*)\/?$/;
            const match = url.pathname.match(regExp);

            return match && match[2].length === 22 ? match[2] : null;
        } else {
            return null;
        }
    } catch (e) {
        return null;
    }
};

export const parseUrl = (
    link: string
): { type: "youtube"; id: string } | { type: "spotify"; id: string } | { type: "article"; url: string } | { type: "webtext" } | null => {
    const url = castURL(link);
    const youtubeId = parseYoutubeLinkId(link);
    const spotifyId = parseSpotifyLinkId(link);

    if (youtubeId) {
        return { type: "youtube", id: youtubeId };
    } else if (spotifyId) {
        return { type: "spotify", id: spotifyId };
    } else if (url) {
        return { type: "article", url: link };
    } else {
        return { type: "webtext" };
    }
};

export const getDefaultChapter = (title: string) => [
    {
        title: title,
        start: 0,
    },
];

type LoadMediaArgs = { url: string; create?: boolean } | { mediaId: string };
export const load = async (args: LoadMediaArgs): Promise<Inputting<Media> | null> => {
    // const { url, mediaId } = args;

    const media = await (async () => {
        if ("mediaId" in args) {
            return await getMedia(args.mediaId);
        } else {
            const existing = await getMediaForLink(args.url);

            console.log(existing);

            if (existing.id && !args.create) {
                return existing;
            }
            // try to create media
            return createMediaForLink(args.url);
        }
    })();

    if (media === null) {
        // this should really be becking for a 404
        return null;
    }

    const [quiz, loadedChapters] = await Promise.all([getQuiz(media.id), getTranscript(media.id)]);

    const existingChapters: Chapter[] | null = !quiz
        ? null
        : (() =>
              quiz.quizContent.map((chapter: any): Chapter => {
                  const loadedTakeaways: (Takeaway | null)[] = chapter.questions.map(importQuestion);

                  if (loadedTakeaways.some((it) => it === null)) {
                      alert("Failed to import a question, saving will remove it");
                  }

                  return {
                      id: chapter.id,
                      title: chapter.name,
                      start: 0,
                      thumbnail: "",
                      transcript: [],
                      takeaways: loadedTakeaways.filter(notNullish),
                  };
              }))();

    const { transcript, chapters } = !loadedChapters
        ? { transcript: null, chapters: null }
        : (() => {
              const { transcript, chapters: newChapters } = parseChapters(loadedChapters, media.type === "article");

              if (existingChapters) {
                  return { transcript, chapters: existingChapters };
              } else {
                  return { transcript, chapters: newChapters };
              }
          })();

    const shared: BaseMedia = {
        title: media.title,
        link: media.fullLink,

        chapters: chapters ?? [],
        transcript,

        publisher: {
            id: media.publisher.id,
            logo: media.publisher.logo,
            name: media.publisher.name,
        },

        id: media.id,
        quizId: quiz?.id,
    };

    if (media.type === "video") {
        return {
            type: "youtube",
            state: "complete",

            description: "",
            length: media.length,
            thumbnail: `https://img.youtube.com/vi/${media.ytbId}/0.jpg`, // this line is used in LightCard.tsx, if reused again extract

            ...shared,
        };
    } else if (media.type === "article") {
        return {
            type: "article",
            state: "complete",

            ...shared,
        };
    } else if (["sound", "image", "document", "text"].includes(media.type)) {
        return {
            type: "upload",
            state: "complete",

            fileType: media.type,
            extraLinks: media.extraLinks,

            ...shared,
        };
    } else if (media.type === "spotify") {
        return {
            thumbnail: "",
            description: "",
            length: 0,
            type: "spotify",
            state: "complete",

            ...shared,
        };
    } else {
        return _throw("Unimplemented media type");
    }
};

const defaultContentMap: {
    youtube: Inputting<YoutubeMedia>;
    spotify: Inputting<SpotifyMedia>;
    article: Inputting<ArticleMedia>;
    upload: undefined; // Inputting<UploadMedia>;
    // text: Inputting<TextContent>;
} = {
    youtube: {
        type: "youtube",
        link: "",
        state: "prepopulated",
        title: "",
        isLoading: false,
        id: undefined,
        quizId: undefined,
    },
    spotify: {
        type: "spotify",
        link: "",
        state: "prepopulated",
        title: "",
        isLoading: false,
        id: undefined,
        quizId: undefined,
    },
    article: {
        type: "article",
        link: "",
        title: "",
        state: "prepopulated",
        isLoading: false,
        id: undefined,
        quizId: undefined,
    },
    upload: undefined,
    // sound: {
    //     type: "sound",
    //     link: "",
    //     state: "prepopulated",
    //     isLoading: false,
    // },
    // text: {
    //     type: "text",
    //     state: "populating",
    //     title: "",
    //     body: "",
    // },
};

export const isContentDefault = (content: Media) => [defaultContentMap.article, defaultContentMap.youtube].some((q) => isEqual(q, content));

export const getNewContentByType = (type: Media["type"]): Inputting<Media> => defaultContentMap[type] ?? _throw("Not implemented");

export const castURL = (text: string): URL | null => {
    try {
        return new URL(text);
    } catch (e) {
        return null;
    }
};

export const useQuery = <T extends Object>(): T => {
    const locationStateLocation = useLocation();
    return useMemo(
        () =>
            Array.from(new URLSearchParams(locationStateLocation.search).entries()).reduce(
                (acc, [key, val]) => ({
                    ...acc,
                    [key]: val,
                }),
                {} as T
            ),
        [locationStateLocation]
    );
};

export const defaultQuestionsMap: {
    ABQuestion: (origin: string) => ABQuestion;
    MatchingQuestion: (origin: string) => MatchingQuestion;
    BlankChoice: (origin: string) => BlankChoiceQuestion;
    SingleWritten: (origin: string) => SingleWrittenQuestion;
    MultipleChoice: (origin: string) => MultipleChoiceQuestion;
    SupplyDemandQuestion: (origin: string) => SupplyDemandQuestion;
    InflationQuestion: (origin: string) => InflationQuestion;
    SortCategoriesQuestion: (origin: string) => SortCategoriesQuestion;
} = {
    ABQuestion: (origin) => ({
        id: undefined,
        createdBy: "user",
        type: "AB",
        text: "",
        answerA: { text: "", id: undefined },
        answerB: { text: "", id: undefined },
        attachment: null,
        origin,
        heading: undefined,
    }),
    MatchingQuestion: (origin) => ({
        id: undefined,
        createdBy: "user",
        type: "Matching",
        text: "",
        pairs: [],
        attachment: null,
        origin,
        heading: undefined,
    }),
    BlankChoice: (origin) => ({
        id: undefined,
        createdBy: "user",
        type: "BlankChoice",
        blanks: [],
        text: "",
        attachment: null,
        origin,
        heading: undefined,
    }),
    SingleWritten: (origin) => ({
        id: undefined,
        createdBy: "user",
        type: "SingleWritten",
        text: "",
        answer: { text: "", id: undefined },
        attachment: null,
        origin,
        heading: undefined,
    }),
    MultipleChoice: (origin) => ({
        id: undefined,
        createdBy: "user",
        type: "MultipleChoice",
        text: "",
        correct: [{ text: "", id: undefined }],
        incorrect: [{ text: "", id: undefined }],
        attachment: null,
        origin,
        heading: undefined,
    }),
    SupplyDemandQuestion: () => ({
        id: undefined,
        createdBy: "user",
        type: "S&D",
        text: "",
        move: "demand",
        constant: "decrease",
        attachment: null,
        origin,
        heading: undefined,
    }),
    InflationQuestion: () => ({
        id: undefined,
        createdBy: "user",
        type: "Inflation",
        text: "",
        answers: [],
        attachment: null,
        origin,
        heading: undefined,
    }),
    SortCategoriesQuestion: () => ({
        id: undefined,
        createdBy: "user",
        type: "SC",
        text: "",
        categories: [],
        attachment: null,
        origin,
        heading: undefined,
    }),
};

export const isQuestionDefault = (question: Question) =>
    [defaultQuestionsMap.SingleWritten, defaultQuestionsMap.BlankChoice].some((q) => isEqual(q, question));

export const questionIsComplete = (question: Question): boolean => {
    if (question.type === "AB") {
        return question.text !== "" && question.answerA.text !== "" && question.answerB.text !== "";
    } else if (question.type === "Matching") {
        return question.text !== "" && question.pairs.length !== 0 && question.pairs.every(({ textA, textB }) => textA !== "" && textB !== "");
    } else if (question.type === "BlankChoice") {
        return question.text !== "" && question.blanks.flatMap((ans) => [ans.correct, ...ans.incorrect]).every(({ text }) => text !== "");
    } else if (question.type === "SingleWritten") {
        return question.text !== "" && question.answer.text !== "";
    } else if (question.type === "MultipleChoice") {
        return question.text !== "" && [...question.correct, ...question.incorrect].every((text) => text.text !== "");
    } else if (question.type === "S&D") {
        return question.text !== "";
    } else if (question.type === "SC") {
        return (
            question.text !== "" &&
            question.categories.length !== 0 &&
            question.categories.every(({ text, items }) => text !== "" && items.length !== 0 && items.every(({ text }) => text !== ""))
        );
    } else if (question.type === "Inflation") {
        return (
            question.text !== "" &&
            question.answers.every((text) => text.text !== "" && text.cp !== "" && text.image !== "" && text.price !== "") &&
            question.answers.length >= 2
        );
    } else {
        return _throw("Unimplemented");
    }
};

export const exportQuestions = (question: Question): any => {
    const common = {
        id: question.id,
        origin: question.origin,
        createdBy: question.createdBy,
        attachmentType: question.attachment?.type ?? undefined,
        attachmentURL: question.attachment?.url ?? undefined,
        heading: question.heading ?? undefined,
    };

    if (question.type === "BlankChoice") {
        type IndexedBlank = Blank & { ordinalIndex: number };
        const indexedBlanks = [...question.blanks] // copy because this is an inplace sort
            .sort((a, b) => b.index - a.index)
            .map(
                (it, index): IndexedBlank => ({
                    ...it,
                    ordinalIndex: question.blanks.length - index - 1,
                })
            );

        // index is in descending order, while ordina index is ascending
        type Content = { type: "question"; text: string } | { type: "blank"; answerId: number };
        const textWithoutBrackets = question.text.replaceAll("[", "").replaceAll("]", "");
        return {
            ...common,
            type: "BC",
            question: {
                // this reduce constructs a list of Content (matching the required question type),
                // at each step, it splits the first {type:question} into 2 question objects and one blank,
                // until all blanks are in the resulting sequence
                content: indexedBlanks.reduce<Content[]>(
                    ([leftMostString, ...remaining], cur) => {
                        if (leftMostString.type !== "question") {
                            return _throw("This is impossible");
                        }
                        const left = leftMostString.text.slice(0, cur.index);
                        const right = leftMostString.text.slice(cur.index + cur.correct.text.length);

                        return [
                            { type: "question", text: left },
                            { type: "blank", answerId: cur.ordinalIndex },
                            { type: "question", text: right },
                            ...remaining,
                        ];
                    },
                    [{ type: "question", text: textWithoutBrackets }]
                ),
                tag: "p",
            },
            answers: {
                ...indexedBlanks.reverse().map((blank) => [
                    {
                        text: blank.correct.text,
                        id: blank.correct.id,
                        isCorrect: true,
                    },
                    ...blank.incorrect.map((it) => ({
                        text: it.text,
                        id: it.id,
                        isCorrect: false,
                    })),
                ]),
            },
        };
    }

    const questionText = question.text.split(/\n{2,}/).map((p) => ({
        content: [
            {
                type: "question",
                text: p,
            },
        ],
        tag: "p",
    }));

    if (question.type === "AB") {
        return {
            ...common,
            type: "AB",
            question: questionText,
            answers: {
                "0": [
                    {
                        text: question.answerA.text,
                        isCorrect: true,
                        id: question.answerA.id,
                    },
                    {
                        text: question.answerB.text,
                        id: question.answerB.id,
                    },
                ],
            },
        };
    } else if (question.type === "Matching") {
        return {
            ...common,
            type: "MQ",
            question: questionText,
            answers: {
                "0": question.pairs,
            },
        };
    } else if (question.type === "SingleWritten") {
        return {
            ...common,
            type: "SW",
            question: questionText,
            answers: {
                "0": [
                    {
                        text: question.answer.text,
                        isCorrect: true,
                        id: question.answer.id,
                    },
                ],
            },
        };
    } else if (question.type === "MultipleChoice") {
        return {
            ...common,
            type: question.correct.length === 0 ? "MCS" : "MCM",
            question: questionText,
            answers: {
                "0": [
                    ...question.correct.map((answer) => ({
                        text: answer.text,
                        id: answer.id,
                        isCorrect: true,
                    })),
                    ...question.incorrect.map((answer) => ({
                        text: answer.text,
                        id: answer.id,
                        isCorrect: false,
                    })),
                ],
            },
        };
    } else if (question.type === "S&D") {
        return {
            ...common,
            type: "S&D",
            question: questionText,
            answers: {
                "0": [
                    {
                        move: question.move,
                        constant: question.constant,
                    },
                ],
            },
        };
    } else if (question.type === "SC") {
        return {
            ...common,
            type: "SC",
            question: questionText,
            answers: {
                "0": question.categories.map((it) => ({
                    text: it.text,
                })),
                "1": question.categories.flatMap((cat, categoryIndex) =>
                    cat.items.map((item) => ({
                        text: item.text,
                        image: item.image,
                        categoryIndex,
                    }))
                ),
            },
        };
    } else if (question.type === "Inflation") {
        return {
            ...common,
            type: "Inflation",
            question: questionText,
            answers: {
                "0": question.answers.map((answer, i) => ({
                    text: answer.text,
                    id: answer.id,
                    cp: answer.cp,
                    image: answer.image,
                    price: answer.price,
                    isCorrect: i === 0,
                })),
            },
        };
    } else {
        return _throw("Unimplemented");
    }
};

// todo add the type here
export const importQuestion = (data: any): Takeaway | null => {
    const attachment = (() => {
        if (!data.attachmentType) {
            return null;
        } else if (data.attachmentType === "video") {
            return {
                type: "video",
                url: data.attachmentURL,
            } as const;
        } else if (data.attachmentType === "image") {
            return {
                type: "image",
                url: data.attachmentURL,
            } as const;
        } else {
            return _throw("Unimplemented Attachment");
        }
    })();

    if (data.type === "BC") {
        const blanks: Blank[] = Object.getOwnPropertyNames(data.answers).map((index) => ({
            correct: (() => {
                const value = data.answers[index].find((a: any) => a.isCorrect);
                return {
                    text: value.text,
                    id: value.id,
                };
            })(),
            incorrect: data.answers[index]
                .filter((a: any) => !a.isCorrect)
                .map((value: any) => ({
                    text: value.text,
                    id: value.id,
                })),
            isLoading: false,
            index: 0,
            id: data.answers[index].id,
        }));

        const text = (data.question as Object[])
            .map((it: any): string =>
                (it.content as Object[]).reduce<string>((acc: string, cur: any): string => {
                    if (cur.type !== "question") {
                        // fixme this like is nasty, there must be a better way to decode this
                        blanks[cur.answerId].index = acc.replaceAll("[", "").replaceAll("]", "").length;
                        return `${acc}[${blanks[cur.answerId].correct.text}]`;
                    } else if (acc.length === 0) {
                        return cur.text;
                    } else {
                        return acc + cur.text;
                    }
                }, "")
            )
            .reduce((acc: string, cur: string) => {
                return `${acc}\n\n${cur}`;
            });

        return {
            keyword: null,
            state: "edit",
            line: { text, start: 0, duration: 0 },
            questions: [
                {
                    id: data.id,
                    createdBy: data.createdBy,
                    type: "BlankChoice",
                    text,
                    blanks,
                    attachment,
                    origin: data.origin,
                    heading: data.heading,
                },
            ],
            hint: false,
            keypoint: text,
        };
    } else {
        // const parseText
        const parseQuestionText = (question: Record<any, any>): string => {
            if (Array.isArray(question)) {
                return (question as Object[])
                    .map(parseQuestionText)
                    .reduce<string>((acc, cur) => `${acc.trimEnd()}\n\n${cur.trimStart()}`, "")
                    .trim()
                    .trim();
            }
            if (question?.tag === "p") {
                return (question.content as Object[])
                    .map(parseQuestionText)
                    .reduce<string>((acc, cur) => `${acc.trimEnd()}\n\n${cur.trimStart()}`, "")
                    .trim()
                    .trim();
            } else if (question?.type === "question") {
                return question.text;
            } else {
                console.log(question);
                return _throw("Failed to parse question text");
            }
        };

        const text = parseQuestionText(data.question);

        const answerNames = Object.getOwnPropertyNames(data.answers[0]).filter((it) => it !== "length");

        if (data.type === "AB") {
            const correctIndex = answerNames.find((index) => data.answers[0][index].isCorrect);
            const incorrectIndex = answerNames.find((index) => !data.answers[0][index].isCorrect);

            if (correctIndex === undefined || incorrectIndex === undefined) {
                alert("Failed to load question");
                _throw("");
                return null;
            }

            const question: ABQuestion = {
                id: data.id,
                createdBy: data.createdBy,
                type: "AB",
                text,
                answerA: data.answers[0][correctIndex],
                answerB: data.answers[0][incorrectIndex],
                attachment,
                origin: data.origin,
                heading: data.heading,
            };

            return {
                keyword: null,
                state: "edit",
                line: { text, start: 0, duration: 0 },
                questions: [question],
                hint: false,
                keypoint: text,
            };
        } else if (data.type === "MQ") {
            const pairs: Pair[] = answerNames.map((index) => data.answers[0][index]);

            const question: MatchingQuestion = {
                id: data.id,
                createdBy: data.createdBy,
                type: "Matching",
                text,
                pairs,
                attachment,
                origin: data.origin,
                heading: data.heading,
            };

            return {
                keyword: null,
                state: "edit",
                line: { text, start: 0, duration: 0 },
                questions: [question],
                hint: false,
                keypoint: text,
            };
        } else if (data.type === "MCS" || data.type === "MCM") {
            const correctAnswers = answerNames.filter((index) => data.answers[0][index].isCorrect).map((it) => data.answers[0][it]);
            const incorrectAnswers = answerNames.filter((index) => !data.answers[0][index].isCorrect).map((it) => data.answers[0][it]);

            if (incorrectAnswers.length === 0 || correctAnswers.length === 0) {
                alert("Failed to load question");
                return _throw("Failed to load question");
            }

            const question: MultipleChoiceQuestion = {
                id: data.id,
                createdBy: data.createdBy,
                type: "MultipleChoice",
                text,
                correct: correctAnswers,
                incorrect: incorrectAnswers,
                attachment,
                origin: data.origin,
                heading: data.heading,
            };
            return {
                keyword: null,
                state: "edit",
                line: { text, start: 0, duration: 0 },
                questions: [question],
                hint: false,
                keypoint: text,
            };
        } else if (data.type === "SW") {
            const question: SingleWrittenQuestion = {
                id: data.id,
                createdBy: data.createdBy,
                type: "SingleWritten",
                text,
                answer: data.answers["0"][0],
                attachment,
                origin: data.origin,
                heading: data.heading,
            };
            return {
                keyword: null,
                state: "edit",
                line: { text, start: 0, duration: 0 },
                questions: [question],
                hint: false,
                keypoint: text,
            };
        } else if (data.type === "S&D") {
            const answer = data.answers[0][0];

            return {
                keyword: null,
                state: "edit",
                line: { text, start: 0, duration: 0 },
                questions: [
                    {
                        id: data.id,
                        createdBy: data.createdBy,
                        type: "S&D",
                        text,
                        attachment,
                        origin: data.origin,
                        move: answer.move,
                        constant: answer.constant,
                        heading: data.heading,
                    },
                ],
                hint: false,
                keypoint: text,
            };
        } else if (data.type === "SC") {
            const { "0": categories, "1": items } = data.answers as {
                "0": {
                    id: string;
                    text: string;
                }[];
                "1": {
                    id: string;
                    image: string;
                    text: string;
                    categoryIndex: number;
                }[];
            };

            const grouped = Object.entries(groupBy(items, (it) => it.categoryIndex)).map(([categoryIndex, items]) => ({
                text: categories[parseInt(categoryIndex)].text,
                items: items.map((it) => ({ text: it.text, image: it.image })),
            }));

            return {
                keyword: null,
                state: "edit",
                line: { text, start: 0, duration: 0 },
                questions: [
                    {
                        id: data.id,
                        createdBy: data.createdBy,
                        type: "SC",
                        text,
                        attachment,
                        origin: data.origin,
                        categories: grouped,
                        heading: data.heading,
                    },
                ],
                hint: false,
                keypoint: text,
            };
        } else if (data.type === "Inflation") {
            const answers = data.answers[0];
            const correctAnswers = answerNames.filter((index) => answers[index].isCorrect).map((it) => answers[it]);
            const incorrectAnswers = answerNames.filter((index) => !answers[index].isCorrect).map((it) => answers[it]);

            return {
                keyword: null,
                state: "edit",
                line: { text, start: 0, duration: 0 },
                questions: [
                    {
                        id: data.id,
                        createdBy: data.createdBy,
                        type: "Inflation",
                        text,
                        attachment,
                        origin: data.origin,
                        answers: [...correctAnswers, ...incorrectAnswers],
                        heading: data.heading,
                    },
                ],
                hint: false,
                keypoint: text,
            };
        } else {
            alert(`One of the questions can't be imported, type=${data.type}`);
            return null;
        }
    }
};

export const lineToTakeaway = (line: Line): Takeaway => ({
    line,
    keyword: null,
    keypoint: line.text,
    questions: [],
    hint: false,
    state: `confirm`,
});

// /**
//  * Merges two lists of chapters, oportunistically, taking the transcript from one, and the questions from another.
//  */
// export const joinChapters = (transcriptChapters: Chapter[], questionedChapters: Chapter[]): Chapter[] =>
//     _.zip(transcriptChapters, questionedChapters)
//         .map(([t, q]): Chapter | null => {
//             if (t && q) {
//                 // join them
//                 return {
//                     ...t,
//                     id: q.id,
//                     takeaways: q.takeaways,
//                 };
//             } else {
//                 return t ?? q ?? null;
//             }
//         })
//         .filter(notNullish);

// todo this method should be removed when transcripts are stored in backend1
export const parseChapters = (
    inputChapters: { name: string; lines: string[]; summary?: string | undefined }[],
    splitParagraphs: boolean = false
): { transcript: Transcript; chapters: Chapter[] } => {
    const processedChapters = inputChapters.map((it) =>
        mutate(it, {
            lines: (lines) =>
                lines
                    // .flatMap((it) => it.match(/.*?[.?!]|\.+\s*/gm))
                    .filter(notNullish)
                    .map((it) => it.trim()),
        })
    );

    const transcript: Transcript = processedChapters
        .flatMap((it) => it.lines)
        .map((line, index) => ({
            start: index,
            duration: 1,
            text: line,
        }));

    const chapters: Chapter[] = processedChapters.map(
        (chapter): Chapter => ({
            id: undefined,
            title: chapter.name ?? "",
            start: 0,
            summary: chapter.summary,
            transcript: splitParagraphs
                ? chapter.lines
                      .map((para) => ({
                          speakerName: undefined,
                          lines:
                              para.match(/.*?[.?!]|\.+\s*/gm)?.map((line) => ({
                                  text: line,
                                  duration: 1,
                                  start: 1,
                                  highlighted: false,
                              })) ?? [],
                      }))
                      .filter((it) => it.lines.length !== 0)
                : [
                      {
                          speakerName: undefined,
                          lines:
                              chapter.lines.map((line) => ({
                                  text: line,
                                  duration: 1,
                                  start: 1,
                                  highlighted: false,
                              })) ?? [],
                      },
                  ],
            thumbnail: undefined,
            takeaways: [],
        })
    );

    return { chapters, transcript };
};
/**
 * Returns a list of reasons why this state can't be saved
 * @param state
 */
export const canSave = (state: EditorState): string[] | null => {
    if (state.mode === "Media") {
        return ["Can't save in the media state"];
    }
    const issues = state.media.chapters.flatMap((it, chapterIndex): string[] => {
        if (it.takeaways.length === 0) {
            return [`Chapter ${chapterIndex + 1} must have at least one takeaway`];
        }
        return it.takeaways.flatMap((it, takeawayIndex): string[] => {
            if (it.state !== "edit") {
                return [`Chapter ${chapterIndex + 1} Takeaway ${takeawayIndex + 1} is not valid`];
            }
            if (it.questions.length === 0) {
                return [`Chapter ${chapterIndex + 1} Takeaway ${takeawayIndex + 1} has no questions`];
            }
            return it.questions.flatMap((question, questionId): string[] => {
                if (questionIsComplete(question)) {
                    return [];
                } else {
                    return [`Chapter ${chapterIndex + 1} Takeaway ${takeawayIndex + 1}  Question ${questionId + 1} is not valid`];
                }
            });
        });
    });

    return issues.length === 0 ? null : issues;
};
export type Result<T, E = Error> = { ok: true; value: T } | { ok: false; error: E };
export const save = async (state: EditorState): Promise<Result<string, string>> => {
    console.log("Trying to save");
    if (state.mode === "Media") {
        return { ok: false, error: "Can't save while in the media state" };
    }
    const stateValidation = canSave(state);

    if (stateValidation !== null) {
        return { ok: false, error: `There are issues with the quiz, \n${stateValidation.join("\n")}` };
    }

    const media = getMedia(state.media.id);
    const quiz = await getQuiz(state.media.id);

    console.log({ quiz });

    if (quiz) {
        const chaptersToDelete =
            quiz.quizContent.filter((existingChapter: any) => state.media.chapters.every((chapter) => chapter.id !== existingChapter.id)) ?? [];

        console.log({ chaptersToDelete });

        for (const toDelete of chaptersToDelete) {
            await deleteChapter(quiz.id, toDelete.id);
        }
    }

    for (const chapter of state.media.chapters) {
        console.log(chapter);

        const newQuiz = await (async () => {
            if (quiz && chapter.id !== undefined) {
                return await saveChapter(quiz.id, chapter.id, {
                    name: chapter.title,
                    image: chapter.thumbnail,
                });
            } else {
                return await createChapter(state.media.id, {
                    name: chapter.title,
                });
            }
        })();

        const newChapter = chapter.id
            ? newQuiz.quizContent.find((ch: any) => ch.name === chapter.title) // why can't I compare by id?
            : newQuiz.quizContent[newQuiz.quizContent.length - 1];

        const questions = chapter.takeaways.map((takeaway) => takeaway.questions).flat();

        const questionsKeep = newChapter.questions.filter((eq: any) => questions.some((nq) => nq.id === eq.id));
        await updateQuestionsForChapter(
            newQuiz.id,
            newChapter.id,
            questionsKeep.map((it: any) => it.id)
        );

        for (const question of questions) {
            const questionContent = exportQuestions(question);
            if (quiz && question.id) {
                const q = await updateQuestion(questionContent, newQuiz.id);
                console.log(q, "Updated");
            } else {
                const q = await createQuestion(questionContent, newQuiz.id, newChapter.id);
                console.log(q);
            }
        }
    }

    return { ok: true, value: state.media.id };
};
