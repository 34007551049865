import classnames from "classnames";
import React, { useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logo_black from "../../assets/retainit-logo-white.svg";
import { requestPassChange } from "../../store/requests";
import { Footer } from "./Footer";
import styles from "./Login.module.scss";

export const ResetPasswordPage = () => {
    const [showMessage, setShowMessage] = useState(false);
    const [isExistingUser, setIsExistingUser] = useState("");
    const navigate = useNavigate();

    const handlePassReset = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.target as HTMLFormElement);
        const formDataObj = Object.fromEntries(formData.entries());
        const response = await requestPassChange(formDataObj);
        setIsExistingUser(response);
        setShowMessage(true);
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                <div className={styles.logosCotainer}>
                    <img className={styles.logo} src={logo_black} height={35} alt="retainit" />
                </div>

                <div className={styles.title}>Reset your password</div>

                <div className={styles["content"]}>
                    <div className={styles.instructions}>
                        If you're one of our much-loved beta testers, enter your email and we'll send you a password reset link.
                    </div>
                    <Form onSubmit={handlePassReset}>
                        <Form.Group className={classnames("mb-3", styles["control-group"])} controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" name="email" autoComplete="new-password" />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            <span>Reset</span>
                            <span className={styles["arrow"]}></span>
                        </Button>
                    </Form>
                    {showMessage && (
                        <Alert variant="success" dismissible={false}>
                            <p>
                                {isExistingUser ? (
                                    <>We've sent you an email. We look forward to seeing you soon.</>
                                ) : (
                                    <>
                                        You're not on our list of beta users. Want to sign up? Register your interest <a href="/sign-up">here</a>
                                    </>
                                )}
                            </p>
                        </Alert>
                    )}
                    <div className={styles["go-back"]} onClick={handleGoBack}>
                        &#8592; Go back
                    </div>
                </div>
            </div>
            <Footer small={true} />
        </div>
    );
};
