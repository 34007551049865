import { AppConfigContext, ErrorBoundary, GamificationPanel, IUser, IUserStats, LayoutContext, ThemeContext } from "@retainit/shared";

import correctSound from "@retainit/shared/src/quiz/assets/correct.mp3";
import incorrectSound from "@retainit/shared/src/quiz/assets/incorrect.mp3";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./App.module.scss";
import AppRoutes from "./components/AppRoutes";
import { userBadgesPath } from "./components/AppRoutes/routes";
import Assistant from "./components/Assistant";
import { MIDDLE_BREAKPOINT, MOBILE_BREAKPOINT } from "./config";
import { getUserStats, updateTutorial } from "./store/requests";

declare global {
    interface Window {
        google: any;
        dataLayer: any;
    }
}

const defaultUser = {
    firstName: "",
    lastName: "",
    id: "",
    email: "",
    role: "user",
    settings: {
        theme: "light",
        layout: "full",
    },
    training: "",
};

const defaultStats: IUserStats = {
    answers: {
        total: 0,
        correct: 0,
    },
    reviews: 0,
    library: 0,
    xp: 0,
};

const queryClient = new QueryClient();

function App() {
    const [user, setUser] = useState<IUser>(defaultUser);
    const [stats, setStats] = useState<IUserStats>(defaultStats);
    const [ping, setPing] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE_BREAKPOINT);

    const [isMidpoint, setIsMidpoint] = useState(window.innerWidth >= MOBILE_BREAKPOINT && window.innerWidth < MIDDLE_BREAKPOINT);

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    });

    function handleWindowSizeChange() {
        if (isMobile && window.innerWidth >= MOBILE_BREAKPOINT) {
            setIsMobile(false);
            return;
        }
        if (!isMobile && window.innerWidth < MOBILE_BREAKPOINT) {
            setIsMobile(true);
            return;
        }
    }

    const resetUser = useCallback(() => {
        setUser(defaultUser);
    }, []);

    const handleTutorialCheckpoint = () => {
        updateTutorial(setUser);
    };

    const refreshStats = useCallback(() => {
        getUserStats(setStats);
    }, []);
    const sendPing = useCallback(() => {
        setPing(ping + 1);
    }, [ping]);

    return (
        <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <AppConfigContext.Provider
                    value={{
                        user,
                        setUser,
                        resetUser,
                        stats,
                        refreshStats,
                        ping,
                        sendPing,
                    }}
                >
                    <ThemeContext.Provider
                        value={{
                            mode: "light",
                            layout: "full",
                            sounds: {
                                correct: correctSound,
                                incorrect: incorrectSound,
                            },
                        }}
                    >
                        <LayoutContext.Provider value={{ isMobile, isMidpoint }}>
                            <SideMenusWrapper>
                                <AppRoutes />
                            </SideMenusWrapper>

                            {/*
                        {user?.tutorial === TUTORIAL_STAGES.NOT_STARTED && user?.training && (
                            <div className={styles["onboarding"]}>
                                <div className={styles["onb-card"]}>
                                    <div className={styles["onb-close"]} onClick={handleTutorialCheckpoint}>
                                        X
                                    </div>
                                    <div className={styles["message-container"]}>
                                        <div className={styles["greeting"]}>{`Hey ${user.firstName}!`}</div>
                                        <div className={styles["message"]}>
                                            <div>
                                                Retainit makes it simple and rewarding to remember what you learn from YouTube. Watch the video below
                                                to see how it works.
                                            </div>
                                            <div>Thanks for choosing to learn with us :)</div>
                                            <div>Michael</div>
                                        </div>
                                    </div>
                                    <div className={styles["video"]}>
                                        <iframe
                                            src={user?.training}
                                            width={isMobile ? 350 : 480}
                                            height={isMobile ? 180 : 245}
                                            allowFullScreen={true}
                                        />
                                    </div>
                                    <div className={styles["btn"]} onClick={handleTutorialCheckpoint}>
                                        Launch my Retainit
                                    </div>
                                </div>
                            </div>
                        )}
*/}
                        </LayoutContext.Provider>
                    </ThemeContext.Provider>
                </AppConfigContext.Provider>
            </QueryClientProvider>
        </ErrorBoundary>
    );
}

export default App;

const SideMenusWrapper = (props: React.PropsWithChildren<{}>) => {
    const { user } = useContext(AppConfigContext);

    const location = useLocation();
    const navigate = useNavigate();
    const [showLayout, setShowLayout] = useState(true);
    useEffect(() => {
        setShowLayout(
            !(
                location.pathname === "/" ||
                [
                    "/editor",
                    "/admin",
                    "/login",
                    "/sign-up",
                    "/forgot-password",
                    "/beta-policy",
                    "/extension/",
                    "/reset",
                    "/policy",
                    "/verify",
                    "/verified",
                    "/recover",
                    "/join",
                    "/course/",
                    "/quiz/",
                    // "/review",
                ].some((value: string) => location.pathname.includes(value))
            )
        );
    }, [location]);

    return (
        <div className={styles.container}>
            {user?.firstName && showLayout && (
                <div className={styles.leftContainer}>
                    <Assistant />
                </div>
            )}
            <div className={styles.inner}>{props.children}</div>

            {user?.firstName && showLayout && (
                <div className={styles.rightContainer}>
                    <GamificationPanel
                        openBadges={() => {
                            navigate(userBadgesPath);
                        }}
                    />
                </div>
            )}
        </div>
    );
};
