import React from "react";
import styles from "./Library.module.scss";

export const WelcomeCard = () => (
    <div className={styles.empty}>
        <div className={styles.welcomeTitle}>Welcome to your library!</div>
        <div className={styles.title}>This is where all of your Retainable tests will be stored.</div>
    </div>
);

export const NoResultsCard = () => (
    <div className={styles.empty}>
        <div className={styles.welcomeTitle}>No results</div>
        <div className={styles.title}></div>
    </div>
);
