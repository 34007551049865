import { AppConfigContext, IUserContext } from "@retainit/shared";
import React, { MutableRefObject, useContext, useEffect, useState } from "react";
import { GOOGLE_CLIENT_ID, REPORTING_ENABLED } from "../config";

import { refreshToken } from "../store/requests";

export const useAuth = (): [IUserContext, Boolean] => {
    const [finished, setFinished] = useState(false);
    const userData = useContext(AppConfigContext);
    useEffect(() => {
        if (!userData.user?.id) {
            REPORTING_ENABLED && window.dataLayer.push({ user_id: null, retainit_id: null });
            handleRefresh(userData, setFinished);
        } else {
            REPORTING_ENABLED &&
                window.dataLayer.push({
                    user_id: userData.user.id,
                    retainit_id: userData.user.id,
                });
        }
    }, []);
    return [userData, finished];
};

const handleRefresh = async (userData: any, setFinished: Function) => {
    const newUser = await refreshToken();
    const override = {
        ...newUser,
        settings: { layout: "full", theme: "light" },
    };
    userData.setUser(override);
    setFinished(true);
};

export const useGoogle = (cb: Function, myRef: MutableRefObject<HTMLElement | null>, options: any = {}) => {
    const [gReady, setGReady] = useState(false);
    const [isValid, setIsValid] = useState(true);

    const interval = setInterval(() => {
        if (window.google && window.google.accounts) {
            setGReady(true);
            clearInterval(interval);
        }
    }, 500);
    useEffect(() => {
        if (!gReady) {
            return;
        }
        window.google?.accounts.id.initialize({
            client_id: GOOGLE_CLIENT_ID,
            callback: cb,
        });
        if (isValid) {
            window.google?.accounts.id.renderButton(myRef.current, {
                theme: "outline",
                size: "medium",
                shape: "pill",
                logo_alignment: "center",
                ...options,
            });
        }
    }, [gReady]);
    useEffect(() => {
        if (!isValid) {
            clearInterval(interval);
        }
    }, [isValid]);

    return setIsValid;
};
