import { getHomeFeed, useTitle } from "@retainit/shared";
import Spinner from "react-bootstrap/Spinner";
import Stack from "react-bootstrap/Stack";
import InfiniteScroll from "react-infinite-scroller";
import { useInfiniteQuery } from "react-query";
import { FeedItem } from "./FeedItem";

export const FeedPage = () => {
    useTitle("Retainit - Feed");

    const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery("feed", ({ pageParam }) => getHomeFeed({ offset: pageParam }), {
        getNextPageParam: (lastPage) => lastPage.offset,
    });

    if (isLoading) {
        return (
            <Stack className="align-items-center p-5">
                <Spinner animation="border" variant="primary" />
            </Stack>
        );
    }

    const items = data?.pages.flatMap((page) => page.items) ?? [];

    return (
        <Stack className="align-items-center py-5 px-3">
            <InfiniteScroll loadMore={() => fetchNextPage()} hasMore={hasNextPage} loader={<Spinner animation="border" variant="primary" />}>
                {items.map((item) => (
                    <FeedItem key={item.id} item={item} />
                ))}
            </InfiniteScroll>
        </Stack>
    );
};
