import { AppConfigContext, IUser } from "@retainit/shared";
import classnames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo_black from "../../assets/retainit-logo-white.svg";
import { getUserData } from "../../store/requests";
import { useAuth } from "../../utils/hooks";
import { joinWaitingListPathFormat, reviewPagePathFormat } from "../AppRoutes/routes";
import { Footer } from "./Footer";
import styles from "./Login.module.scss";

export const LoginPage = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const appConfig: { user: IUser; setUser: Function } = useContext(AppConfigContext);
    const [auth, refreshFinished] = useAuth();

    const navigate = useNavigate();

    const location: any = useLocation();

    // const gSignInBtn: MutableRefObject<HTMLDivElement | null> = useRef(null);
    // const setIsValid = useGoogle(handleLoginWithGoogle, gSignInBtn);
    useEffect(() => {
        return () => {
            // setIsValid(false);
        };
    }, []);

    useEffect(() => {
        if (auth?.user?.id) {
            if (location.state?.url) {
                navigate(location.state.url);
            } else {
                navigate(reviewPagePathFormat);
            }
        }
    }, [auth, location, navigate, refreshFinished]);

    // async function handleLoginWithGoogle(response: any) {
    //     const userData = await getGUserData(response.credential, appConfig.setUser);
    //     if (userData) {
    //         if (location.state?.url) {
    //             navigate(location.state.url);
    //         } else {
    //             navigate(reviewPagePathFormat);
    //         }
    //     } else {
    //         setErrorMessage("Your access to the app was denied. Possible reason: you do not have an account or we lost access to the server.");
    //         setShowAlert(true);
    //     }
    // }

    const handleManualSingnIn = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.target as HTMLFormElement);
        const formDataObj = Object.fromEntries(formData.entries());
        const renamedPassword = {
            email: formDataObj["email"].toString(),
            password: formDataObj["current-password"].toString(),
        };
        const userData = await getUserData(renamedPassword, appConfig.setUser);
        if (userData) {
            if (location.state.url) {
                navigate(location.state.url);
            } else {
                navigate(reviewPagePathFormat);
            }
        } else {
            setErrorMessage(
                "Your access to the app was denied. Possible reason: email/password did not match, you do not have an account or we lost access to the server."
            );
            setShowAlert(true);
        }
    };
    const handleForgotPass = () => {
        navigate("/reset");
    };
    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                <div className={styles.logosCotainer}>
                    <img className={styles.logo} src={logo_black} height={35} alt="retainit" />
                </div>

                <div className={styles.subtitle}>Sign in</div>

                <div className={styles.content}>
                    {showAlert && (
                        <Alert
                            variant="danger"
                            onClose={() => {
                                setErrorMessage("");
                                setShowAlert(false);
                            }}
                            dismissible
                        >
                            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                            <p>{errorMessage}</p>
                        </Alert>
                    )}
                    <Form onSubmit={handleManualSingnIn} className={styles.form}>
                        <Form.Group className={classnames("mb-3", styles["control-group"])} controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" name="email" />
                        </Form.Group>

                        <Form.Group className={classnames("mb-3", styles["control-group"])} controlId="formBasicPassword">
                            <div className={styles["pass-label"]}>
                                <Form.Label>Password</Form.Label>
                            </div>
                            <Form.Control type="password" name="current-password" id="current-password" />
                        </Form.Group>

                        <Button variant="primary" type="submit" style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <span>Sign in &#10140;</span>
                            <span className={styles["arrow"]}></span>
                        </Button>
                    </Form>

                    <div className={styles["forgot-pass"]} onClick={handleForgotPass}>
                        Forgotten password?
                    </div>

                    {/*<div className={styles["with-google"]}>
                    <div id={styles["signin"]} ref={gSignInBtn!}></div>
                    <div className={styles["or"]}>
                        <hr className={styles["line-left"]} />
                        <span>OR</span>
                        <hr className={styles["line-right"]} />
                    </div>
                </div>*/}

                    <div className={styles["no-account"]}>
                        Want to join our closed beta? Register your interest &nbsp;
                        <Link to={joinWaitingListPathFormat}>here</Link>.
                    </div>
                </div>
            </div>
            <Footer small={true} />
        </div>
    );
};
