import { animated, config, useSpring } from "@react-spring/web";
import { AppConfigContext, getLevel, LayoutContext } from "@retainit/shared";
import classnames from "classnames";
import React, { PropsWithChildren, useContext, useEffect, useState } from "react";

import { Download, Home } from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
import big_X from "../../assets/big-X.png";
import { Courses } from "../../assets/courses";
import down_arrow from "../../assets/down_arrow.png";
import burger from "../../assets/icon_burger_menu.png";
import { Library } from "../../assets/library";
import log_out from "../../assets/log_out.png";
import { PlayQuiz } from "../../assets/play-quiz";
import FeedIcon from "../../assets/feed.svg";
import retainit_logo from "../../assets/retainit-logo-white.svg";
import trial from "../../assets/trial.png";
import white_play from "../../assets/white_play.svg";
import white_thunder from "../../assets/white_thunder.svg";
import { EXTENSION_PATH } from "../../config";
import { signUserOut } from "../../store/requests";
import { useAuth } from "../../utils/hooks";
import { feedPagePathFormat, libraryPagePathFormat, reviewPagePathFormat, userCoursesPath } from "../AppRoutes/routes";
import styles from "./Assistant.module.scss";

type AssistantProps = {
    // user: IUser;
    // handleTutorialCheckpoint: () => void;
};

const Assistant = (_: AssistantProps) => {
    const location = useLocation();

    const navigate: any = useNavigate();

    const { isMobile } = useContext(LayoutContext);
    const [hideAssistant, setHideAssistant] = useState(false);

    const [isCollapsed, setIsCollapsed] = useState(false);
    const { refreshStats } = useContext(AppConfigContext);

    useEffect(() => {
        if (isMobile) {
            setIsCollapsed(true);
        }
    }, [isMobile, location]);

    useEffect(() => {
        setHideAssistant(location.pathname.includes("/admin"));
    }, [location]);

    useEffect(() => {
        refreshStats();
    }, [refreshStats]);

    const handleCollapse = (ev: any) => {
        ev.stopPropagation();
        setIsCollapsed(!isCollapsed);
    };

    const { user } = useContext(AppConfigContext);
    const [auth] = useAuth();

    const handleSignOut = async () => {
        window.google.accounts.id.disableAutoSelect();
        const response = await signUserOut();
        if (response) {
            auth?.resetUser();
            navigate("/");
        }
    };

    const downloadExtensionButtonSpring = useSpring({
        // from: { scale: 1 },
        // to: [{ scale: 1.05 }, { scale: 1.0 }],
        to: [
            {
                scale: 1.05,
                fontWeight: 800,
            },
            {
                scale: 1,
                fontWeight: 700,
            },
        ],
        from: {
            scale: 1,
            fontWeight: 700,
        },

        config: config.slow,
        loop: true,
    });

    return (
        <>
            {isCollapsed && isMobile && (
                <>
                    <div className={styles["assistant-collapsed"]}>
                        <div className={styles["menu-btn"]}>
                            <Link to={reviewPagePathFormat}>
                                <Home />
                            </Link>
                        </div>
                        <Link to="/review" className={styles["menu-btn-large"]}>
                            <div className={styles["review-small"]}>
                                <img src={white_thunder} width={30} height={30} alt={"thunder"} />
                                <span className={styles["text"]}>
                                    <div className={styles["primary_text"]}>Retainit Review</div>
                                    <div className={styles["secondary_text"]}>Boost your retention</div>
                                </span>
                            </div>
                        </Link>
                        <Link to="/library" className={styles["menu-btn-large"]}>
                            <div className={styles["library-small"]}>
                                <img src={white_play} width={30} height={30} alt={"play"} />
                                <span className={styles["text"]}>
                                    <div className={styles["primary_text"]}>My Library</div>
                                    <div className={styles["secondary_text"]}>All of my videos</div>
                                </span>
                            </div>
                        </Link>

                        <div className={styles["menu-btn"]} onClick={handleCollapse}>
                            <div>
                                <img src={burger} width={13} height={10} alt={"menu"} />
                            </div>
                        </div>
                    </div>
                </>
            )}

            {!(isCollapsed && isMobile) && !hideAssistant && (
                <div className={styles["assistant-container"]}>
                    <div className={styles.header}>
                        <div className={styles.text}>
                            <Link to={reviewPagePathFormat}>
                                <img src={retainit_logo} width={100} />
                            </Link>
                        </div>
                        <div className={styles.right_btns}>
                            {isMobile && (
                                <img src={big_X} width={40} height={40} onClick={handleCollapse} className={styles["close-btn"]} alt={"close"} />
                            )}
                        </div>
                    </div>

                    <div className={styles.content}>
                        <div className={styles.section}>
                            <div className={styles.section_content}>
                                <div className={styles.menu_actions}>
                                    <Link
                                        to={feedPagePathFormat}
                                        className={classnames(styles.action, styles.review, {
                                            [styles.active]: location.pathname.includes("feed"),
                                        })}
                                    >
                                        <span className={styles.iconContainer}>
                                            <img src={FeedIcon} width={24} height={24} alt={"thunder"} />
                                        </span>
                                        <span className={styles.actionText}>Feed</span>
                                    </Link>

                                    <Link
                                        to={reviewPagePathFormat}
                                        className={classnames(styles.action, styles.review, {
                                            [styles.active]: location.pathname.includes("review"),
                                        })}
                                    >
                                        <span className={styles.iconContainer}>
                                            <PlayQuiz height={19} />
                                            {/*<img src={playQuiz}  />*/}
                                        </span>
                                        <span className={styles.actionText}>Review</span>
                                    </Link>

                                    <Link
                                        to={libraryPagePathFormat}
                                        className={classnames(styles.action, styles.library, {
                                            [styles.active]: location.pathname.includes("library"),
                                        })}
                                    >
                                        <span className={styles.iconContainer}>
                                            <Library height={23} />
                                            {/*<Save height={23} />*/}
                                        </span>
                                        <span className={styles.actionText}>Library</span>
                                    </Link>

                                    {/*<Link
                                        to={userBadgesPath}
                                        className={classnames(styles.action, styles.badges, {
                                            [styles["active"]]: location.pathname.includes("/user/badges"),
                                        })}
                                    >
                                        <span className={styles.iconContainer}>
                                            <Badge height={32} />
                                        </span>
                                        <span className={styles.actionText}>Badges</span>
                                    </Link>*/}
                                    <Link
                                        to={userCoursesPath}
                                        className={classnames(styles.action, styles.courses, {
                                            [styles["active"]]: location.pathname.includes(userCoursesPath),
                                        })}
                                    >
                                        <span className={styles.iconContainer}>
                                            <Courses />
                                        </span>
                                        <span className={styles.actionText}>Courses</span>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className={classnames(styles.section, styles.bottom)}>
                            {!user?.onboarding?.downloadExtension && (
                                <animated.a style={downloadExtensionButtonSpring} href={EXTENSION_PATH} download className={styles.extension}>
                                    Get Extension
                                </animated.a>
                            )}
                            <div className={styles["section_content"]}>
                                <div className={styles["burger-menu-container"]}>
                                    <CustomBurger>
                                        <div className={styles["drops"]}>
                                            <Link to="/beta-policy">
                                                <div className={styles["btns"]}>
                                                    <img src={trial} width={16} height={16} alt={"info"} />
                                                    <div>Trial info</div>
                                                </div>
                                            </Link>
                                            {user.role === "admin" && (
                                                <Link to="/admin">
                                                    <div className={styles["btns"]}>
                                                        <div>Admin</div>
                                                    </div>
                                                </Link>
                                            )}

                                            <a href={EXTENSION_PATH} download className={styles["btns"]} onClick={() => {}}>
                                                <Download width={16} height={16} color={"grey"} />
                                                <div>Download extension</div>
                                            </a>

                                            <div className={styles["btns"]} onClick={handleSignOut}>
                                                <img src={log_out} width={16} height={12} alt={"logout"} />
                                                <div>Log Out</div>
                                            </div>
                                        </div>
                                    </CustomBurger>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Assistant;

const CustomBurger = (props: PropsWithChildren<{}>) => {
    const { user, stats } = useContext(AppConfigContext);
    const { isMobile } = useContext(LayoutContext);
    const [show, setShow] = useState(false);

    const [level] = getLevel(stats.xp);

    return (
        <div
            className={styles["burger-menu"]}
            onClick={() => {
                setShow(!show);
            }}
        >
            <div className={styles["menu-comp"]}>
                <div className={styles["user_initials"]}>{user?.firstName[0] + user?.lastName[0]}</div>
                {!isMobile && (
                    <div className={styles.userNameContainer}>
                        <div className={styles["user_name"]}>{`${user?.firstName} ${user?.lastName}`}</div>
                        <div className={styles.level}>
                            {level.label} <span>{stats.xp} XP</span>
                        </div>
                    </div>
                )}
                <img src={down_arrow} style={{ transform: "rotateZ(180deg)" }} width={7} height={4} alt={"toggle open"} />
            </div>
            {show && <div className={styles["menu-content"]}>{props.children}</div>}
        </div>
    );
};
