import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import logo_black from "../../assets/retainit-logo-white.svg";
import styles from "./Login.module.scss";

export const Footer = ({ small }: { small: boolean }) => (
    <div className={classNames(styles.footer, { [styles.small]: small })}>
        {!small && <img src={logo_black} width={120} alt={"retainit"} />}
        <div className={styles.bottom}>
            <div className={styles.left}>Ⓒ 2022 Retainit. All rights reserved.</div>
            {!small && (
                <Link className={styles.right} to={"/policy"}>
                    Privacy Policy
                </Link>
            )}
        </div>
    </div>
);
