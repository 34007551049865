import React from "react";
import { Link } from "react-router-dom";

function AccessDenied(props: any) {
    return (
        <div>
            <p>Sorry, you do not have access to this page.</p>
            <Link to="/"> Back to main page</Link>
        </div>
    );
}

export default AccessDenied;
