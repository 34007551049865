import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import Calendar from "react-calendar";
import styles from "./Reminder.module.scss";
import "./DateTimePicker.scss";
import classnames from "classnames";

function DateTimePicker(props: any) {
    const [selection, setSelection] = useState(props.default || new Date());
    const today = new Date();
    const handleChange = (value: any) => {
        const updatedDate = new Date(value);
        updatedDate.setHours(selection.getHours());
        updatedDate.setMinutes(selection.getMinutes());
        setSelection(updatedDate);
    };

    useEffect(() => {
        props.onPick(selection);
    }, [selection]);

    const predefinedReminders = [
        {
            text: "Tomorrow",
            delay: 24 * 60 * 60,
        },
        {
            text: "1 week",
            delay: 7 * 24 * 60 * 60,
        },
        {
            text: "4 weeks",
            delay: 4 * 7 * 24 * 60 * 60,
        },
        {
            text: "3 months",
            delay: 12 * 7 * 24 * 60 * 60,
        },
    ];

    const curateDate = (d?: Date) => {
        const curated = d ? new Date(d) : new Date();
        curated.setHours(0);
        curated.setMinutes(0);
        curated.setSeconds(0);
        curated.setMilliseconds(0);
        return curated;
    };

    const handlePredef = (curatedDate: any) => {
        const calculated = new Date(curatedDate);
        calculated.setHours(selection.getHours());
        calculated.setMinutes(selection.getMinutes());

        setSelection(calculated);
    };

    const padLeft = (num: number) => {
        // TODO: extract to common or replace with native js
        return num < 10 ? "0" + num : num;
    };

    const getTime = (d?: Date) => {
        if (!d) {
            return "";
        }
        const hours = padLeft(d.getHours());
        const minutes = padLeft(d.getMinutes());
        return `${hours}:${minutes}`;
    };

    const handleTimePick = (time: any, event: any) => {
        const calculated = new Date(selection);

        calculated.setHours(parseInt(time.split(":")[0]));
        calculated.setMinutes(parseInt(time.split(":")[1]));

        setSelection(calculated);
    };

    const handleMonthPick = (eventKey: any, event: any) => {
        setSelection(new Date(selection.setMonth(eventKey)));
    };

    const handleYearPick = (eventKey: any, event: any) => {
        setSelection(new Date(selection.setYear(eventKey)));
    };

    const timeItems = [];
    for (let i = 0; i < 24; i++) {
        const firstItemTime = `${padLeft(i)}:00`;
        const secondItemTime = `${padLeft(i)}:30`;
        timeItems.push(
            <Dropdown.Item key={firstItemTime} eventKey={firstItemTime} active={firstItemTime === getTime(selection)}>
                {firstItemTime}
            </Dropdown.Item>
        );
        timeItems.push(
            <Dropdown.Item key={secondItemTime} eventKey={secondItemTime} active={secondItemTime === getTime(selection)}>
                {secondItemTime}
            </Dropdown.Item>
        );
    }
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const monthsItems = monthNames.map((month, index) => {
        return (
            <Dropdown.Item key={index} eventKey={index} active={index === selection.getMonth()}>
                {month}
            </Dropdown.Item>
        );
    });
    const years = [];
    for (let i = today.getFullYear(); i < today.getFullYear() + 10; i++) {
        years.push(
            <Dropdown.Item key={i} eventKey={i} active={i === selection.getFullYear()}>
                {i}
            </Dropdown.Item>
        );
    }
    return (
        <div className={styles["date-picker"]}>
            <div className={styles["predefined-dates"]}>
                <div className={styles["title"]}>Suggested</div>
                <div className={styles["predefined-dates-list"]}>
                    {predefinedReminders.map((item: any, index: number) => {
                        const curatedDate = curateDate(new Date(new Date().getTime() + item.delay * 1000));
                        return (
                            <div
                                className={classnames(styles["predef-date"], {
                                    [styles["selected"]]: curateDate(selection).getTime() == curatedDate.getTime(),
                                })}
                                onClick={() => handlePredef(curatedDate)}
                            >
                                <div className={styles["predef-date-text"]}>{item.text}</div>
                                <div className={styles["predef-date-value"]}>{curatedDate.toDateString()}</div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className={styles["calendar-area"]}>
                <div className={styles["calendar-title"]}>Or pick your own date and time</div>
                <div className={styles["calendar-container"]}>
                    <div className={styles["calendar"]}>
                        <div className={styles["calendar-header"]}>
                            <Dropdown onSelect={handleTimePick} key="time-selector" autoClose={true} className={classnames(styles["dropdown"])}>
                                <Dropdown.Toggle id="dropdown-basic">{getTime(selection)}</Dropdown.Toggle>
                                <Dropdown.Menu className={styles["dropdown-menu"]}>{timeItems}</Dropdown.Menu>
                            </Dropdown>
                            <Dropdown className={classnames(styles["dropdown"])} autoClose={true} key="month-selector" onSelect={handleMonthPick}>
                                <Dropdown.Toggle id="dropdown-basic">{monthNames[selection.getMonth()]}</Dropdown.Toggle>
                                <Dropdown.Menu className={styles["dropdown-menu"]}>{monthsItems}</Dropdown.Menu>
                            </Dropdown>
                            <Dropdown className={classnames(styles["dropdown"])} autoClose={true} key="year-selector" onSelect={handleYearPick}>
                                <Dropdown.Toggle id="dropdown-basic">{selection.getFullYear()}</Dropdown.Toggle>
                                <Dropdown.Menu className={styles["dropdown-menu"]}>{years}</Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <Calendar
                            className={styles["calendar-body"]}
                            onChange={handleChange}
                            value={selection}
                            activeStartDate={selection}
                            minDate={today}
                            formatShortWeekday={(locale, date) =>
                                date
                                    .toLocaleString(locale, {
                                        weekday: "short",
                                    })
                                    .substring(0, 2)
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DateTimePicker;
