import React from "react";
import styles from "./Policy.module.scss";

function BetaPolicy(props: any) {
    return (
        <div className={styles["policy"]}>
            <h1>
                RETAINIT
                <br />
                Beta testing T&Cs
            </h1>
            <p>
                Thank you for your interest in taking part in our Beta testing programme (the <b>“Programme”</b>) in relation to{" "}
                <a href="https://www.retainit.app">www.retainit.app</a> and Retainit (together, our <b>“Site”</b>). This agreement applies between us,
                Retainit Holdings Ltd., a company registered in England and Wales with company number 13121308 with its registered office at 34
                Cloncurry St, Fulham, London, SW6 6DU (also <b>“we”</b> and <b>“our”</b>) and you for the purposes of the Programme.
            </p>
            <p>
                Please read these this document and our privacy policy (found at <a href="/policy">www.retainit.app/policy</a> carefully (together,
                the <b>“Terms”</b>). By registering for, accessing and/or using our Site, you accept the Terms in full. If you do not agree to the
                Terms, you should not access our Site.
            </p>
            <ol>
                <li>
                    <b>Access to the Site.</b> You agree to access and use the Site, the educational materials and other content provided by us via
                    the Site (<b>“Our Content”</b>) and the third party content with which you may engage through the Site (
                    <b>“Third Party Content”</b>; together with Our Content, the <b>“Content”</b>) solely for the purpose of the Programme (that is,
                    to be given early access to the Site in order to test the Site and to provide feedback to us, should you wish to do so). We
                    reserve the right to modify or terminate your access to the Site at any time, without notice and for any reason, without any
                    liability to you.
                </li>
                <li>
                    <b>Charges.</b> We will not charge you for accessing or using the Site during and for the purposes of the Programme. We reserve
                    the right to introduce subscription fees or other charges for access to the Site and/or similar or related services when the Site
                    is made available to the general public at the conclusion of the Programme (please note that we will not impose any such fees or
                    charges on you without giving reasonable notice).
                </li>
                <li>
                    <b>Confidentiality.</b> You undertake to treat as confidential and keep confidential the Site and the services offered by way of
                    the Site until the conclusion of the Programme, and (except where otherwise required by law or where the relevant information is
                    already generally available to the public) not to disclose to or otherwise share such information with anyone except us, other
                    participants in the Programme and anyone bound by an obligation of confidentiality in relation to the Site that is no less strict
                    than this one.
                </li>
                <li>
                    <b>No warranties.</b> The Site is in a pre-release form and may not be at the level of functionality or performance of a final,
                    generally released product. The Site and the Content are provided on an “as-is” basis, accessed and used entirely at your own risk
                    and without any warranty of any kind. Under no circumstances shall we be liable for any damage of any kind arising from or in
                    connection with your access to, use of or other interactions with the Site and the Content under or in connection with the
                    Programme, even if you have advised us of the possibility of such damage. However, these Terms do not purport to exclude any
                    liability that cannot be excluded by law.
                </li>
                <li>
                    <b>Feedback.</b> We may ask you to provide feedback on the Site during or after the Programme. You are under no obligation to do
                    so but we would greatly appreciate your considered feedback. If you choose to provide feedback (whether written, oral or
                    otherwise, via whatever channel of communication and in whatever form), you grant us a royalty-free, irrevocable, perpetual
                    licence with no licensing restrictions to use the feedback and to incorporate this into the Site (although we are under no
                    obligation to do so). Provided that such feedback does not contain your personal data or is anonymised by us, you agree that the
                    feedback will be treated as non-confidential.{" "}
                </li>
                <li>
                    <b>Our intellectual property.</b> We and our licensors own all intellectual property rights in Our Content. Except as expressly
                    set out above, nothing in these Terms grants you any licence in the Content.
                </li>
                <li>
                    <b>General.</b> These Terms are governed by and construed in accordance with English law. We both agree to the exclusive
                    jurisdiction of the English courts to resolve any dispute (including non-contractual disputes) arising out of or in connection
                    with the Programme and these Terms. We reserve the right to update these Terms without prior notice and you agree that we may
                    notify you of the updated Terms by posting them on the Site, and that your use of the Site after the effective date of the updated
                    Terms constitutes your agreement to the updated Terms.
                </li>
            </ol>
        </div>
    );
}

export default BetaPolicy;
