import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import classnames from "classnames";
import styles from "./SuggestionsPage.module.scss";
import { generateQuiz, requestQuiz } from "../../store/requests";

function SuggestionsPage(props: any) {
    const inputControl = useRef<HTMLInputElement>(null);
    const [url, setUrl] = useState("");
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        if (inputControl.current) {
            inputControl.current?.focus();
        }
    }, []);

    const handleChange = (e: any) => {
        setUrl(e.target.value);
    };

    const handleConfirm = async () => {
        generateQuiz(url, { length: "medium" }, new Date().toISOString());

        setShowMessage(true);
        setUrl("");
    };

    const handleFocus = () => {
        setShowMessage(false);
    };

    const canSubmit = url && url.length;
    return (
        <div className={styles["page"]}>
            <div className={styles["page-title"]}>Add a quiz</div>
            {/*<div className={styles["page-subtitle"]}>Put the YouTube URL in the box below.</div>*/}
            <div className={styles["page-content"]}>
                <Form.Group className={classnames("mb-3", styles["control-group"])} controlId="request">
                    <Form.Label>Add URL</Form.Label>
                    <Form.Control name="request" type="text" ref={inputControl} value={url} onChange={handleChange} onFocus={handleFocus} />
                </Form.Group>
                {showMessage && <div className={styles["message"]}>Saved! You will receive a notification when your quiz is ready.</div>}
                <div className={styles["actions"]}>
                    <div
                        className={classnames(styles["actions-button"], styles["confirm"], { [styles.disabled]: !canSubmit })}
                        onClick={canSubmit ? handleConfirm : undefined}
                    >
                        Confirm
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SuggestionsPage;
