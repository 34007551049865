import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import retainit_logo from "../../assets/retainit-logo-white.svg";

import bottomImage from "./image.svg";

import sp_logo from "../../assets/partners/sp.png";
import steve_logo from "../../assets/partners/steve.png";
import deloitte_logo from "../../assets/partners/deloitte.png";
import mru_logo from "../../assets/partners/mru.png";
import rte_logo from "../../assets/partners/rte.png";

import { useAuth } from "../../utils/hooks";
import { joinWaitingListPathFormat, loginPagePathFormat, reviewPagePathFormat } from "../AppRoutes/routes";
import styles from "./LandingPage.module.scss";

export const LandingPage = () => {
    const [{ user }] = useAuth();

    return (
        <div className={styles.outer}>
            <div className={styles["header"]}>
                <div className={styles.middleButtonsContainer}>
                    <img className={styles.retainitLogo} src={retainit_logo} height={21} alt="retainit" />
                </div>
                <div className={styles.rightButtonsContainer}>
                    <div className={styles["burger-menu-container"]}>
                        {!user.email && (
                            <a className={styles.loginButton} href={loginPagePathFormat}>
                                Log in
                            </a>
                        )}
                        {user.email && (
                            <a className={styles.loginButton} href={reviewPagePathFormat}>
                                Go to Workspace
                            </a>
                        )}
                    </div>
                </div>
            </div>

            <div className={styles.body}>
                <div className={styles.left}>
                    <h1>Learning technology for the Intelligence Age.</h1>
                    <h2>AI-first tools and infrastructure for leading education teams. Expanding private beta in Spring '23.</h2>
                    <div className={styles.icons}>
                        <img className={classNames(styles.logo)} src={mru_logo} height={45} alt="" />
                        <img className={classNames(styles.logo)} src={rte_logo} height={31} alt="" />
                        {/*<img className={classNames(styles.logo)} src={deloitte_logo} height={25} alt="" />*/}
                        <img className={classNames(styles.logo)} src={steve_logo} height={40} alt="" />
                        {/*<img className={classNames(styles.logo)} src={sp_logo} height={40} alt="" />*/}
                    </div>
                    <div className={styles.getInTouch}>
                        <a href={"mailto:michael@retainit.app"}>Get in touch</a>
                        <svg
                            className={styles.ellipses}
                            width="1024"
                            height="608"
                            viewBox="0 0 1024 608"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="506" cy="-187" r="794.5" stroke="url(#paint0_linear_0_1)" />
                            <circle cx="506" cy="-227" r="794.5" stroke="url(#paint1_linear_0_1)" />
                            <circle cx="506" cy="-267" r="794.5" stroke="url(#paint2_linear_0_1)" />
                            <defs>
                                <linearGradient id="paint0_linear_0_1" x1="506" y1="-982" x2="506" y2="608" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#5269ED" stopOpacity="0" />
                                    <stop offset="0.796875" stopColor="#5269ED" stopOpacity="0" />
                                    <stop offset="1" stopColor="#5269ED" stopOpacity="0.45" />
                                </linearGradient>
                                <linearGradient id="paint1_linear_0_1" x1="506" y1="-1022" x2="506" y2="568" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#5269ED" stopOpacity="0" />
                                    <stop offset="0.796875" stopColor="#5269ED" stopOpacity="0" />
                                    <stop offset="1" stopColor="#5269ED" stopOpacity="0.45" />
                                </linearGradient>
                                <linearGradient id="paint2_linear_0_1" x1="506" y1="-1062" x2="506" y2="528" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#5269ED" stopOpacity="0" />
                                    <stop offset="0.796875" stopColor="#5269ED" stopOpacity="0" />
                                    <stop offset="1" stopColor="#5269ED" stopOpacity="0.45" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                </div>
                <div className={styles.right}>
                    <img className={styles.bottomImage} src={bottomImage} alt="" />
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.left}>© 2023 Retainit. All rights reserved.</div>
                <Link to="/policy" className={styles.right}>
                    Privacy Policy
                </Link>
            </div>
        </div>
    );
};
