import classnames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import article from "../../../assets/article.svg";
import { ILibraryItem } from "../../../store/librarySlice";
import styles from "./ListCard.module.scss";

type ListCardProps = {
    onClick?: () => void;
    media: any;
    review?: ILibraryItem["review"];
    onMouseOver?: React.HTMLAttributes<HTMLDivElement>["onMouseOver"];
    selected: boolean;
    color: string;
};

export const ListCard = (props: ListCardProps) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(`/watch/${props.media.id}`, { replace: false });
        props.onClick?.();
    };

    const quizDue = props.review ? Math.round((new Date(props.review.scheduledAt).getTime() - Date.now()) / (1000 * 60 * 60 * 24)) : 0;

    return (
        <div
            onMouseOver={props.onMouseOver}
            className={classnames(styles.listCard, {
                [styles.selected]: props.selected,
            })}
            style={{ backgroundColor: props.color }}
            onClick={handleClick}
        >
            <div className={styles.title}>{`${props.media.publisher.name} - ${props.media.title}`}</div>
            <div className={styles.reviewData}>
                {props.review ? (
                    <span className={styles.comingUp}>{quizDue >= 0 ? ` Quiz in ${quizDue} days` : " Quiz due"}</span>
                ) : (
                    <span className={styles.notIn}>⭐️ Not yet reviewed</span>
                )}
            </div>

            {props.media.type === "video" ? (
                <img className={styles.image} src={`https://img.youtube.com/vi/${props.media.ytbId}/0.jpg`} alt="" />
            ) : props.media.image ? (
                <img className={styles.image} src={props.media.image} alt={"article"} />
            ) : (
                <img className={styles.image} src={article} alt={"article"} />
            )}
        </div>
    );
};
