import classnames from "classnames";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "./Extension.module.scss";
import { BASE_URL } from "../../config";

function Extension(props: any) {
    const [acceptPolicy, setAcceptPolicy] = useState(false);
    const navigate = useNavigate();
    const { uniqueKey } = useParams();

    const handleAcceptPolicy = (event: any) => {
        setAcceptPolicy(event.target.checked);
    };
    const policyLink = (
        <Link to={"/beta-policy"} target="_blank" rel="noreferrer">
            Terms & conditions
        </Link>
    );
    const handleDownload = (ev: any) => {
        ev.preventDefault();
        if (acceptPolicy) {
            // navigate(`https://localhost:4000/api/download/${uniqueKey}`)
        }
    };
    return (
        <div className={styles["extension"]}>
            <h2>Extension download</h2>
            <div>To use the extension, please accept our Terms & Conditions. Once you agree, the extension will be available for download.</div>
            <Form onSubmit={handleDownload}>
                <Form.Group className={classnames("mb-3", styles["control-group"], styles["checkbox"])} controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" checked={acceptPolicy} label={"I agree to "} onChange={handleAcceptPolicy} />
                    &nbsp; {policyLink}.
                </Form.Group>
                <Button type="submit" disabled={!acceptPolicy}>
                    <a href={`${BASE_URL}/download/${uniqueKey}`} target="_blank" download="Retainit-extension.zip" rel="noreferrer">
                        Download extension
                    </a>
                </Button>
            </Form>
        </div>
    );
}

export default Extension;
