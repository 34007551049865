import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import logo_black from "../../assets/retainit-logo-white.svg";
import jumpUpTheWaitlist from "../../assets/jump-up-the-waitlist.gif";
import { requestAccountValidation } from "../../store/requests";
import { Footer } from "./Footer";
import styles from "./Login.module.scss";
import classNames from "classnames";
import { animated, config, useSpring } from "@react-spring/web";

export type VerifyEmailPageProps = {
    verified?: boolean;
};
export const VerifyEmailPage = (props: VerifyEmailPageProps) => {
    const [showAlert, setShowAlert] = useState(false);

    const handleVerifyEmail = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.target as HTMLFormElement);
        const formDataObj = Object.fromEntries(formData.entries());
        const response = await requestAccountValidation(formDataObj);
        if (response) {
            setShowAlert(true);
        }
    };

    const checkSpring = useSpring({
        strokeDashoffset: showAlert || props.verified ? 47 : 100,
        from: { strokeDashoffset: 100 },
        config: config.molasses,
        // strokeDashoffset: checkLength,
    });

    return (
        <div className={styles.container}>
            <div className={classNames(styles.inner, styles.verify)}>
                <div className={styles.logosCotainer}>
                    <img className={styles.logo} src={logo_black} height={35} alt={"retainit"} />
                </div>

                {!showAlert &&
                    (props.verified ? (
                        <div className={styles.content}>
                            <div className={styles.successContainer}>
                                <div className={styles.top}>
                                    <svg
                                        width="121"
                                        height="121"
                                        viewBox="0 0 121 121"
                                        fill="none"
                                        version="1.1"
                                        id="svg6"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M60.5151 26.9717C42.0243 26.9717 26.9719 42.0241 26.9719 60.5149C26.9719 79.0057 42.0263 94.0289 60.5151 94.0289C79.0039 94.0289 94.0291 79.0037 94.0291 60.5149C94.0291 42.0261 79.006 26.9717 60.5151 26.9717ZM60.5151 31.1255C76.7584 31.1255 89.8748 44.2701 89.8748 60.5144C89.8748 76.7597 76.7604 89.874 60.5151 89.874C44.2699 89.874 31.1263 76.7566 31.1263 60.5144C31.1263 44.2712 44.2729 31.1255 60.5151 31.1255Z"
                                            fill="#5AC7A8"
                                        />
                                        <animated.path
                                            style={checkSpring}
                                            stroke={"#5ac7a8"}
                                            strokeWidth={4}
                                            strokeLinecap={"round"}
                                            strokeLinejoin={"round"}
                                            strokeDasharray={46 * 2}
                                            paintOrder={"markers fill stroke"}
                                            d="M 44.564217,59.620254 55.719791,70.882958 76.366007,50.172875"
                                        />
                                    </svg>
                                    <div className={styles.text}> Email verified</div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={styles.title}>Account validation</div>
                    ))}

                <div className={styles.content}>
                    {props.verified && (
                        <>
                            <img src={jumpUpTheWaitlist} className={styles.gif} />

                            <div className={styles.instructions}>
                                We’re onboarding people into our private beta in batches, and our goal is to recruit a representative sample of
                                participants. You can jump up the waitlist by completing our short{" "}
                                <a href={"https://fss42vbbcu4.typeform.com/to/NseixZqe"}>questionnaire</a>.
                                <br />
                                <br />
                                We look forward to welcoming you into the product in the weeks or months ahead.
                            </div>
                        </>
                    )}
                    {!props.verified && (
                        <>
                            {!showAlert && (
                                <div className={styles["instructions"]}>
                                    Your email validation token has expired. Enter your email below, and we'll send you a new account validation link
                                </div>
                            )}
                            {showAlert && (
                                <div className={styles.successContainer}>
                                    <div className={styles.top}>
                                        <svg
                                            width="121"
                                            height="121"
                                            viewBox="0 0 121 121"
                                            fill="none"
                                            version="1.1"
                                            id="svg6"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M60.5151 26.9717C42.0243 26.9717 26.9719 42.0241 26.9719 60.5149C26.9719 79.0057 42.0263 94.0289 60.5151 94.0289C79.0039 94.0289 94.0291 79.0037 94.0291 60.5149C94.0291 42.0261 79.006 26.9717 60.5151 26.9717ZM60.5151 31.1255C76.7584 31.1255 89.8748 44.2701 89.8748 60.5144C89.8748 76.7597 76.7604 89.874 60.5151 89.874C44.2699 89.874 31.1263 76.7566 31.1263 60.5144C31.1263 44.2712 44.2729 31.1255 60.5151 31.1255Z"
                                                fill="#5AC7A8"
                                            />
                                            <animated.path
                                                style={checkSpring}
                                                stroke={"#5ac7a8"}
                                                strokeWidth={4}
                                                strokeLinecap={"round"}
                                                strokeLinejoin={"round"}
                                                strokeDasharray={46 * 2}
                                                paintOrder={"markers fill stroke"}
                                                d="M 44.564217,59.620254 55.719791,70.882958 76.366007,50.172875"
                                            />
                                        </svg>
                                        <div className={styles.text}>We've sent you an email</div>
                                    </div>
                                </div>
                            )}
                            {!showAlert && (
                                <Form onSubmit={handleVerifyEmail}>
                                    <Form.Group
                                        className={classnames("mb-3", styles["control-group"])}
                                        controlId="formBasicEmail"
                                        style={{
                                            width: 384,
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                        }}
                                    >
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" name="email" />
                                    </Form.Group>

                                    <Button variant="primary" type="submit">
                                        <span>Send</span>
                                        <span className={styles["arrow"]}></span>
                                    </Button>
                                </Form>
                            )}
                        </>
                    )}
                </div>
            </div>

            <Footer small={true} />
        </div>
    );
};
