import classnames from "classnames";
import React, { useState } from "react";
import { requestReminder } from "../../../store/requests";
import DateTimePicker from "./DateTimePicker";
import styles from "./Reminder.module.scss";

function Reminder(props: any) {
    const [selection, setSelection] = useState<Date>(props.default || new Date(Date.now() + 7 * 24 * 60 * 60 * 1000));
    // const [message, setMessage] = useState("");

    const handleSave = async (calculated?: any) => {
        const result = await requestReminder(calculated, props.media.id);
        // const msg = result
        //     ? "Great! It will be added to your quiz review when the time comes."
        //     : "The quiz has already been scheduled.";
        // setMessage(msg);
    };
    const handleClose = () => {
        props.handleScheduleClose();
    };

    const handleDateTimePick = (pickedDateTime: Date) => {
        setSelection(pickedDateTime);
    };

    const handleConfirm = async () => {
        await handleSave(selection);
        handleClose();
    };

    return (
        <div className={styles["backdrop"]} onClick={(ev: any) => ev.stopPropagation()}>
            <div className={styles["reminder-container"]} onClick={(ev: any) => ev.stopPropagation()}>
                <div className={styles["close-btn"]} onClick={handleClose}>
                    X
                </div>

                <div className={styles["reminder-instructions"]}> When do you want to take the quiz?</div>
                <DateTimePicker default={selection} onPick={handleDateTimePick} />
                <div className={styles["actions"]}>
                    <div className={classnames(styles["cancel"], styles["btn"])} onClick={handleClose}>
                        Cancel
                    </div>
                    <div className={classnames(styles["confirm"], styles["btn"])} onClick={handleConfirm}>
                        Confirm
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Reminder;
