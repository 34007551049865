import { HomeFeedItem } from "@retainit/shared";
import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";
import { MoreHorizontal } from "react-feather";
import article from "../../assets/article.svg";
import { relativeDateTime } from "../../utils";
import styles from "./FeedPage.module.scss";

type FeedItemProps = {
    item: HomeFeedItem;
};

export function FeedItem({ item }: FeedItemProps) {
    return (
        <Card style={{ maxWidth: 512 }} className="rounded rounded-4 p-3 my-4 shadow-sm">
            <Stack direction="horizontal">
                <span className={styles.userProfilePic}>
                    {item.user.firstName[0]}
                    {item.user.lastName[0]}
                </span>
                <span className="ms-2 ">
                    {item.user.firstName} {item.user.lastName}
                    <span className="text-muted">{` · ${relativeDateTime(item.createdAt)}`}</span>
                </span>

                <MoreHorizontal className="ms-auto" />
            </Stack>

            <span className="mt-2 mb-4">{item.media.title}</span>

            {item.media.type === "video" ? (
                <img className="rounded rounded-3" src={`https://img.youtube.com/vi/${item.media.ytbId}/0.jpg`} alt="" />
            ) : item.media.image ? (
                <img className="rounded rounded-3" src={item.media.image} alt={"article"} />
            ) : (
                <img className="rounded rounded-3" src={article} alt={"article"} />
            )}
        </Card>
    );
}
