import { AppConfigContext, getMedia, getUserMediaTags, LayoutContext, QuizModal, useTitle } from "@retainit/shared";
import classnames from "classnames";
import React, { useContext, useEffect, useRef, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import article from "../../assets/article.svg";
import blue_arrow from "../../assets/blue_arrow.png";
import blue_rocket from "../../assets/blue_rocket.png";
import off_bulb from "../../assets/off_bulb.png";
import on_bulb from "../../assets/on_bulb.png";
import upChevron from "../../assets/up-chevron.svg";
import white_down_arrow from "../../assets/white_down_arrow.png";
import { VERSION } from "../../config";
import { getRecommendationsForMedia, getReviewStatus } from "../../store/requests";
import Reminder from "../Assistant/Reminder";
import WStyles from "../Workspace/Workspace.module.scss";
import styles from "./MediaPage.module.scss";

export const MediaPage = () => {
    const { isMobile } = useContext(LayoutContext);
    const { mediaId } = useParams();
    const navigate = useNavigate();
    const [media, setMedia] = useState<any>({});
    const [recommendations, setRecommendations] = useState<any>([]);
    const [showScheduler, setShowScheduler] = useState(false);
    const [reviewScheduled, setReviewScheduled] = useState<Date | null>(null);
    const { ping, refreshStats, sendPing } = useContext(AppConfigContext);

    const carouselElement = useRef<HTMLDivElement>(null);

    const [launchQuiz, setLaunchQuiz] = useState(false);
    const handlePubClick = () => {
        navigate(`/pubs/${media?.publisher?.id}`, { replace: false });
    };
    useEffect(() => {
        if (mediaId) {
            getMedia(mediaId).then(setMedia);
            getRecommendationsForMedia(mediaId, {}, setRecommendations);
            // getUserMediaTags(mediaId, {}, setRecommendations);
            refreshReviewStatus(mediaId);
        }
    }, [mediaId]);

    // const handleVideoClick = (media: any) => {
    //     navigate(`/watch/${media.id}`, { replace: false });
    // };

    const handleQuizClose = () => {
        setLaunchQuiz(false);
        refreshStats();
    };

    const handleSchedule = async () => {
        setShowScheduler(!showScheduler);
        // const response = await requestReminder(null, mediaId);
        // if (response) {
        //     setShowAlert(true);
        // }
    };

    useEffect(() => {
        refreshReviewStatus(mediaId);
    }, [mediaId, ping]);

    const handleLaunch = async () => {
        await getMedia(mediaId || "").then(setMedia);
        await getRecommendationsForMedia(mediaId || "", {}, setRecommendations);
        setLaunchQuiz(true);
    };

    function refreshReviewStatus(_mediaId: any) {
        getReviewStatus(_mediaId, (data: any) => {
            if (data.scheduledAt) {
                setReviewScheduled(new Date(data.scheduledAt));
            } else {
                setReviewScheduled(null);
            }
        });
    }

    const quizDue = reviewScheduled ? Math.round((reviewScheduled.getTime() - Date.now()) / (1000 * 60 * 60 * 24)) : 0;

    useTitle(`Retainit - ${media?.title ?? ""}`);

    return (
        <>
            <div className={styles["video-container"]}>
                {media.type === "video" ? (
                    <div className={styles["video"]}>
                        <iframe
                            src={`https://www.youtube.com/embed/${media.ytbId}/?autoplay='1'&&enablejsapi=1&modestbranding=1&showinfo=0`}
                            allow="autoplay"
                            // @ts-expect-error
                            allowFullScreen="allowfullscreen"
                            mozallowfullscreen="mozallowfullscreen"
                            msallowfullscreen="msallowfullscreen"
                            oallowfullscreen="oallowfullscreen"
                            webkitallowfullscreen="webkitallowfullscreen"
                        />
                    </div>
                ) : media.image ? (
                    <div
                        className={styles["image"]}
                        onClick={() => {
                            window.open(media.fullLink, "_blank", "noopener,noreferrer");
                        }}
                    >
                        <img src={media.image} alt={"article"} />
                    </div>
                ) : (
                    <div className={styles["article"]}>
                        <img src={article} alt={"article"} />
                        <div
                            className={styles["article-button"]}
                            onClick={() => {
                                window.open(media.fullLink, "_blank", "noopener,noreferrer");
                            }}
                        >
                            Open article
                            <img src={upChevron} alt={"Go"} />
                        </div>
                    </div>
                )}

                <div className={styles["video-data"]}>
                    {media?.quiz && !isMobile && (
                        <div className={styles.left}>
                            <div className={styles["retain"]} onClick={handleSchedule}>
                                <span className={styles["dice"]}>
                                    <img src={reviewScheduled ? on_bulb : off_bulb} />
                                </span>
                                <span className={styles["text"]}>
                                    <div className={styles["primary_text"]}> Retain it later</div>
                                    <div className={styles["secondary_text"]}>
                                        {!reviewScheduled && "Add to review"}
                                        {reviewScheduled && `Quiz on ${reviewScheduled?.toDateString()}`}
                                        {reviewScheduled && (quizDue >= 0 ? ` Quiz in ${quizDue} days` : " Quiz due")}
                                    </div>
                                </span>
                                {reviewScheduled && (
                                    <span className={styles["right_arrow"]}>
                                        <img src={white_down_arrow} />
                                    </span>
                                )}
                            </div>
                            <div className={styles["launch"]} onClick={handleLaunch}>
                                <span className={styles["dice"]}>
                                    <img src={blue_rocket} />
                                </span>
                                <span className={styles["text"]}>
                                    <div className={styles["primary_text"]}> Launch quiz</div>
                                    <div className={styles["secondary_text"]}> Retain it now</div>
                                </span>
                                <span className={styles["right_arrow"]}>
                                    <img src={blue_arrow} />
                                </span>
                            </div>
                        </div>
                    )}

                    <div className={styles.right}>
                        <div className={styles["title"]}>{`${media?.title}`}</div>
                        <div className={styles["publisher"]} onClick={handlePubClick}>
                            <span className={styles["pub-name"]}>{media?.publisher?.name}</span>
                            <img src={media?.publisher?.logo} />
                        </div>
                    </div>
                </div>
            </div>
            {isMobile && (
                <div className={styles["actions-container"]}>
                    <>
                        <div className={styles["retain"]} onClick={handleSchedule}>
                            <span className={styles["dice"]}>
                                <img src={reviewScheduled ? on_bulb : off_bulb} />
                            </span>
                            <span className={styles["text"]}>
                                <div className={styles["primary_text"]}>Retain it later</div>
                                <div className={styles["secondary_text"]}>
                                    {!reviewScheduled && "Add to review"}
                                    {/* {reviewScheduled && `Quiz on ${reviewScheduled?.toDateString()}`} */}
                                    {reviewScheduled && (quizDue >= 0 ? `Quiz in ${quizDue} days` : "Quiz due")}
                                </div>
                            </span>
                            {reviewScheduled && (
                                <span className={styles["right_arrow"]}>
                                    <img src={white_down_arrow} />
                                </span>
                            )}
                        </div>

                        <div className={styles["launch"]} onClick={handleLaunch}>
                            <span className={styles["dice"]}>
                                <img src={blue_rocket} />
                            </span>
                            <span className={styles["text"]}>
                                <div className={styles["primary_text"]}>Launch quiz</div>
                                <div className={styles["secondary_text"]}>Retain it now</div>
                            </span>
                            <span className={styles["right_arrow"]}>
                                <img src={blue_arrow} />
                            </span>
                        </div>
                    </>
                </div>
            )}
            {!isMobile && (
                <div className={WStyles["rec-container"]}>
                    {/* <div className={WStyles["rec-header"]}>Recommended videos</div> */}
                    <div className={classnames(WStyles["rec-item"], WStyles["topic-list"])} ref={carouselElement}>
                        <div className={WStyles["rec-carousel"]}>
                            {/*<FreeCarousel
                                items={recommendations}
                                slidesPerView={slidesPerView}
                                hasNavigation={true}
                                freeMode={true}
                                handleSlideClick={handleVideoClick}
                                newCard={true}
                                spaceBetween={8}
                                skipBorder={isMobile}
                                headerMessage={"Recommended videos"}
                            />*/}
                        </div>
                    </div>
                </div>
            )}
            {showScheduler && (
                <Reminder
                    media={{
                        id: mediaId,
                    }}
                    handleScheduleClose={() => {
                        refreshStats();
                        refreshReviewStatus(mediaId);
                        setShowScheduler(false);
                        sendPing();
                    }}
                />
            )}
            {launchQuiz && (
                <QuizModal
                    quizId={media?.quiz}
                    meta={{
                        title: media?.title,
                        pubLogo: media?.publisher?.logo,
                        recommendations: recommendations.slice(0, 5),
                        // cardType: LightCard,
                        // carousel: FreeCarousel,
                    }}
                    handleClose={handleQuizClose}
                    origin={`dashboard/watch@${VERSION}`}
                />
            )}
        </>
    );
};
