import React, { useEffect, useState } from "react";
import styles from "./Publishers.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { getPublishers, followPublisher } from "../../store/requests";
import PubCard from "./PubCard";

function Publishers(props: any) {
    const [data, setData] = useState<any>([]);
    let navigate = useNavigate();

    useEffect(() => {
        getPublishers({}, setData);
    }, []);

    const handleFollowClick = async (id: string, toFollow: boolean) => {
        await followPublisher(id, toFollow);
        setData(await getPublishers());
    };
    const pubCards = data?.map((pub: any) => {
        return <PubCard key={pub.id} {...pub} handleFollowClick={handleFollowClick} />;
    });
    return (
        <>
            <div className={styles["page"]}>
                <div className={styles["header"]}>
                    <span>{"Channels"}</span>
                </div>
                <div className={styles["container"]}>{pubCards}</div>
            </div>
        </>
    );
}

export default Publishers;
