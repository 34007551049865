import isEqual from "lodash/isEqual";
import { useCallback, useEffect, useState } from "react";
import { fetchData } from "./store/requests";

type FetchStatus = "loading" | "success" | "failed";
type UseFetchResult<BodyType, QueryType, ResultType> = {
    result: ResultType | null;
    error: string | null;
    status: FetchStatus;
    forceReload: (params?: Params<BodyType, QueryType>) => Promise<null | ResultType>;
};
type UseFetchOptions = { autoLoad?: boolean };

type Params<BodyType, QueryType> = (BodyType extends undefined ? { body?: BodyType } : { body: BodyType }) &
    (QueryType extends undefined ? { quary?: QueryType } : { quary: QueryType });
// makes body and quary

export const buildUseFetch =
    <BodyType, QueryType, ResultType>(url: string, method: "POST" | "GET") =>
    (params?: Params<BodyType, QueryType>, options?: UseFetchOptions): UseFetchResult<BodyType, QueryType, ResultType> => {
        const [result, setResult] = useState<ResultType | null>(null);
        const [status, setStatus] = useState<FetchStatus>("loading");
        const [error, setError] = useState<string | null>(null);

        const [cachedBody, setCachedBody] = useState(params?.body);
        useEffect(() => {
            if (!isEqual(cachedBody, params?.body)) {
                // deep equals
                setCachedBody(params?.body);
            }
        }, [cachedBody, params?.body]);

        const [cachedQuary, setCachedQuary] = useState(params?.quary);
        useEffect(() => {
            if (!isEqual(cachedQuary, params?.quary)) {
                // deep equals
                setCachedQuary(params?.quary);
            }
        }, [cachedQuary, params?.quary]);

        const load = useCallback(
            async (params?: Params<BodyType, QueryType>, previewData?: ResultType): Promise<ResultType | null> => {
                if (previewData) {
                    setResult(previewData);
                    setStatus("success");
                } else {
                    setStatus("loading");
                }

                const result = await fetchData(url, method, params?.body ?? cachedBody, params?.quary ?? cachedQuary);
                if (result === null) {
                    setError(`${result}`);
                    setResult(null);
                    setStatus("failed");
                    return null;
                } else {
                    setResult(result);
                    setStatus("success");
                    return result;
                }
            },
            [method, cachedBody, cachedQuary, url]
        );

        useEffect(() => {
            if (options?.autoLoad ?? true) {
                load();
            }
        }, [load, options?.autoLoad, url]);

        return {
            result,
            status,
            error,
            forceReload: load,
        };
    };
