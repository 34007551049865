import classnames from "classnames";
import update, { Spec } from "immutability-helper";
import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import logo_black from "../../assets/retainit-logo-white.svg";
import { signUserUp } from "../../store/requests";
import { loginPagePathFormat, reviewPagePathFormat } from "../AppRoutes/routes";
import { Footer } from "./Footer";
import styles from "./Login.module.scss";

// import { signGUserUp } from "../../store/requests";
// import { useGoogle } from "../../utils/hooks";

const EMPTY_FORM_STATE: FormState = {
    email: "",

    firstName: "",
    lastName: "",

    password: "",

    acceptPolicy: false,
};
type FormState = {
    email: string;

    firstName: string;
    lastName: string;

    password: string;

    acceptPolicy: boolean;
};

type QuaryData = Pick<FormState, "email" | "firstName" | "lastName">;

const isQueryData = (data: any): data is QuaryData => "email" in data;

export const SignUpPage = () => {
    const [showAlert, setShowAlert] = useState(false);

    const [isValid, setIsValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState<any>({
        message: "",
        type: "",
    });
    const formRef = useRef(null);
    // const gSignInBtn = useRef(null);
    const navigate = useNavigate();

    // const handleGoogleSignup = (ev: any) => {
    //     if (!formState.acceptPolicy) {
    //         ev.stopPropagation();
    //         ev.preventDefault();
    //     }
    // };

    // const setGIsValid = useGoogle(handleSignupWithGoogle, gSignInBtn, {
    //     text: "signup_with",
    // });

    const [searchParameters] = useSearchParams();
    const data = searchParameters.get("data");

    const [formState, setFormState] = useState<FormState>(() => {
        if (data) {
            console.log(data);
            const parsed = JSON.parse(window.atob(data));
            console.log(parsed, isQueryData(parsed));
            if (isQueryData(parsed)) {
                const state = { ...EMPTY_FORM_STATE, ...parsed };
                console.log({ state });
                return state;
            } else {
                return EMPTY_FORM_STATE;
            }
        } else {
            return EMPTY_FORM_STATE;
        }
    });
    const updateFormState = (spec: Spec<FormState>) =>
        setFormState((state) => {
            const newState = update(state, spec);
            setIsValid(newState.email !== "" && newState.password !== "" && newState.acceptPolicy);
            return newState;
        });

    useEffect(() => {
        return () => {
            // setGIsValid(false);
        };
    }, []);

    // async function handleSignupWithGoogle(response: any) {
    //     if (!formState.acceptPolicy) {
    //         return;
    //     }
    //     const userSignedUp = await signGUserUp(response.credential);
    //     if (userSignedUp) {
    //         // navigate("/");
    //         setErrorMessage({
    //             message:
    //                 "Thanks for signing up! Our beta users are enormously important to us, and we really appreciate your interest. We'll be in touch by email when we're welcoming more beta users.",
    //             type: "success",
    //         });
    //     } else {
    //         setErrorMessage({
    //             message: "We could not sign you up. Possible reason: you already have an account or we lost access to the server",
    //             type: "danger",
    //         });
    //         setShowAlert(true);
    //     }
    // }

    const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const userSignedUp = await signUserUp({
            email: formState.email,
            firstName: formState.firstName,
            lastName: formState.lastName,
            picture: "",
            password: formState.password,
        });

        if (userSignedUp === true) {
            setErrorMessage({
                message: "Please check your email to validate your account. If in doubt, check your spam folder. The link will expire in 10 mins.",
                type: "success",
            });
        } else if (userSignedUp?.id !== undefined) {
            navigate(reviewPagePathFormat, { replace: true });
        } else {
            setErrorMessage({
                message: "We could not sign you up. Possible reason: you already have an account or we lost access to the server.",
                type: "danger",
            });
        }
        setShowAlert(true);
    };
    const policyLink = (
        <Link to={"/beta-policy"} target="_blank">
            Terms & conditions
        </Link>
    );

    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                <div className={styles.logosCotainer}>
                    <img className={styles.logo} src={logo_black} height={35} alt={"retainit"} />
                </div>

                <div className={styles.title}>Sign up</div>

                <div className={styles["content"]}>
                    {showAlert && (
                        <Alert
                            variant={errorMessage.type}
                            onClose={() => {
                                setErrorMessage({ message: "", type: "" });
                                setShowAlert(false);
                            }}
                            dismissible={errorMessage.type === "danger"}
                        >
                            {errorMessage.type === "danger" && <Alert.Heading>Oh snap! You got an error!</Alert.Heading>}
                            <p>{errorMessage.message}</p>
                        </Alert>
                    )}
                    <Form onSubmit={handleSignUp} ref={formRef} autoComplete="on">
                        <Form.Group className={classnames("mb-3", styles["control-group"])}>
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" readOnly value={formState.email} />
                        </Form.Group>
                        <Form.Group className={classnames("mb-3", styles["control-group"])}>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={formState.firstName}
                                onChange={(event) =>
                                    updateFormState({
                                        firstName: { $set: event.target.value },
                                    })
                                }
                            />
                        </Form.Group>
                        <Form.Group className={classnames("mb-3", styles["control-group"])}>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={formState.lastName}
                                onChange={(event) =>
                                    updateFormState({
                                        lastName: { $set: event.target.value },
                                    })
                                }
                            />
                        </Form.Group>

                        <Form.Group className={classnames("mb-3", styles["control-group"])}>
                            <div className={styles["pass-label"]}>
                                <Form.Label>Password</Form.Label>
                            </div>
                            <Form.Control
                                name="new-password"
                                type="password"
                                id="new-password"
                                autoComplete="new-password"
                                value={formState.password}
                                onChange={(event) =>
                                    updateFormState({
                                        password: { $set: event.target.value },
                                    })
                                }
                            />
                        </Form.Group>

                        <Form.Group className={classnames("mb-3", styles["control-group"], styles["checkbox"])}>
                            <Form.Check
                                type="checkbox"
                                checked={formState.acceptPolicy}
                                label={"I agree to "}
                                onChange={(event) =>
                                    updateFormState({
                                        acceptPolicy: { $set: event.target.checked },
                                    })
                                }
                            />
                            &nbsp; {policyLink}.
                        </Form.Group>

                        <Button variant="primary" type="submit" disabled={!formState.acceptPolicy || !isValid}>
                            <span>Sign up</span>
                            <span className={styles["arrow"]}></span>
                        </Button>
                    </Form>

                    {/* <div className={styles["with-google"]}>
                            <div
                                className={classnames(styles["wrapper"], {
                                    [styles["disabled"]]: !acceptPolicy,
                                })}
                                onClick={handleGoogleSignup}
                            >
                                <div
                                    id={styles["signin"]}
                                    ref={gSignInBtn!}
                                ></div>
                                <div className={styles["tooltip"]}>
                                    Please accept our T&Cs to sign up.
                                </div>
                            </div>
                    </div> */}

                    {/*<div className={styles["or"]}>
                    <hr className={styles["line-left"]} />
                    <span>OR</span>
                    <hr className={styles["line-right"]} />
                </div>*/}
                    <div className={styles["no-account"]}>
                        Already taking part in the beta trial? &nbsp;
                        <Link to={loginPagePathFormat}>Sign in here</Link>
                    </div>
                </div>
            </div>
            <Footer small={true} />
        </div>
    );
};
