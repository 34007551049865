import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import App from "./App";

import reportWebVitals from "./reportWebVitals";
import { setupReporting } from "./sentry";
import { store } from "./store";

setupReporting();

const resizeWindowMeasure = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
};

window.addEventListener("orientationchange", resizeWindowMeasure);
window.addEventListener("resize", resizeWindowMeasure);

resizeWindowMeasure();

const container = document.getElementById("retainit-app");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

const Root = () => (
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);

root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
