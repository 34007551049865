import { animated, config, useSpring, useSprings } from "@react-spring/web";
import { LayoutContext, useButtonBopAnimation } from "@retainit/shared";
import classNames from "classnames";
import classnames from "classnames";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import other13 from "../../assets/other-13.png";
import logo_black from "../../assets/retainit-logo-white.svg";
import sally2 from "../../assets/saly-2.png";
import sally22 from "../../assets/saly-22.png";
import { getPartnerByReferral, joinUserUp, Partner } from "../../store/requests";
import { Footer } from "./Footer";
import styles from "./Login.module.scss";

export const JoinWaitinglistPage = () => {
    const { isMobile } = useContext(LayoutContext);

    const [userJoined, setUserJoined] = useState<boolean | null>(false);
    const [isValid, setIsValid] = useState(false);

    const search = useLocation().search;
    const partnerRef = new URLSearchParams(search).get("ref") ?? undefined;

    const [partner, setPartner] = useState<"none" | "loading" | Partner>("none");

    useEffect(() => {
        if (partnerRef) {
            getPartnerByReferral(partnerRef).then(setPartner);
        }
    }, [partnerRef]);

    const [errorMessage, setErrorMessage] = useState<null | string>("");

    const bop = useButtonBopAnimation();

    const handleJoin = async (e: React.FormEvent<HTMLFormElement>) => {
        setUserJoined(null);
        e.preventDefault();
        const formData = new FormData(e.target as HTMLFormElement);
        const formDataObj = Object.fromEntries(formData.entries());
        const userJoined = await joinUserUp(formDataObj, partnerRef);
        if (!userJoined) {
            setErrorMessage("Oops! Something went wrong.");
            setUserJoined(false);
        } else {
            setUserJoined(true);
        }
    };

    const handleFormChange = (e: React.FormEvent<HTMLFormElement>) => {
        const formData = new FormData((e.target as any).form as HTMLFormElement);
        const data = Object.fromEntries(formData.entries());
        let valid = true;
        Object.keys(data).forEach((key: string) => {
            if (!data[key]) {
                valid = false;
            }
        });
        setIsValid(valid);
    };

    const checkSpring = useSpring({
        strokeDashoffset: !!userJoined ? 47 : 100,
        config: config.molasses,
        // strokeDashoffset: checkLength,
    });
    const [imagePop, api] = useSprings(3, () => ({
        scale: 1,
        headlineFontWeight: 600,
        descriptionFontWeight: 200,
        config: config.default,
    }));

    const jumpButtonSpring = useSpring({
        from: { scale: 1 },
        to: [{ scale: 1.05 }, { scale: 1.0 }],
        config: config.slow,
        loop: true,
    });

    const onMouseEnter = useCallback(
        (index: number) => () =>
            api.start((i) =>
                i === index
                    ? {
                          scale: isMobile ? 1.07 : 1.04,
                          headlineFontWeight: 700,
                          descriptionFontWeight: 300,
                      }
                    : {
                          scale: 1,
                          headlineFontWeight: 600,
                          descriptionFontWeight: 200,
                      }
            ),
        [api, isMobile]
    );

    const onMouseLeave = useCallback(
        (index: number) => () =>
            api.start(() => ({
                scale: 1,
                headlineFontWeight: 600,
                descriptionFontWeight: 200,
            })),
        [api]
    );

    const ref1 = useRef<HTMLDivElement>(null);
    const ref2 = useRef<HTMLDivElement>(null);
    const ref3 = useRef<HTMLDivElement>(null);
    //
    // const another = useScroll([ref1, ref2, ref3]);

    // useEffect(() => {
    //     onMouseEnter(another)();
    //     return () => {
    //         onMouseLeave(another)();
    //     };
    // }, [another, onMouseEnter, onMouseLeave]);

    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                <div className={styles.logosCotainer}>
                    <img className={styles.retainitLogo} src={logo_black} height={35} alt="retainit" />
                    {/*{partner !== "none" && partner !== "loading" && partner.logo && (*/}
                    <>
                        <svg className={styles.plus} width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18.4999 29.5996C18.2055 29.5996 17.9232 29.4826 17.715 29.2745C17.5068 29.0662 17.3899 28.7839 17.3899 28.4896V8.87957C17.3899 8.48296 17.6015 8.11657 17.9449 7.91831C18.2883 7.71994 18.7115 7.71994 19.0549 7.91831C19.3983 8.11656 19.6099 8.48293 19.6099 8.87957V28.4896C19.6099 28.7839 19.4929 29.0662 19.2848 29.2745C19.0766 29.4826 18.7943 29.5996 18.4999 29.5996Z"
                                fill="white"
                            />
                            <path
                                d="M28.4899 19.9798H8.50994C8.11333 19.9798 7.74694 19.7681 7.54868 19.4248C7.35031 19.0814 7.35031 18.6582 7.54868 18.3148C7.74693 17.9714 8.1133 17.7598 8.50994 17.7598H28.4899C28.8866 17.7598 29.2529 17.9714 29.4512 18.3148C29.6496 18.6582 29.6496 19.0814 29.4512 19.4248C29.253 19.7682 28.8866 19.9798 28.4899 19.9798Z"
                                fill="white"
                            />
                        </svg>
                        <img
                            className={styles.otherLogo}
                            src={"https://storage.googleapis.com/retainit-uploads/mru.svg"}
                            height={50}
                            alt="retainit"
                        />
                    </>
                    {/*)}*/}
                </div>

                {!userJoined && (
                    <>
                        <div className={styles.title}>Learn Economics, Understand Your World.</div>
                        <div className={styles.subtitle}>
                            Join The (Marginal) Revolution.
                            <br />
                            Join the waitlist.
                        </div>
                    </>
                )}
                <div className={styles.content}>
                    {/*{transition((style, userJoined) => (*/}
                    {/*    <>*/}
                    {!userJoined && (
                        <form onSubmit={handleJoin} onChange={handleFormChange} className={styles.horizontalForm}>
                            <Form.Group className={classnames(styles["control-group"])} controlId="formBasicEmail">
                                <Form.Control
                                    name="email"
                                    type="email"
                                    autoComplete="on"
                                    placeholder="Email"
                                    disabled={userJoined === null}
                                    className={classNames({
                                        [styles.invalid]: errorMessage,
                                    })}
                                />
                                {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
                            </Form.Group>
                            <animated.button type="submit" disabled={!isValid} {...bop}>
                                <span className={classNames({ [styles.hidden]: userJoined === null })}>Join Waitlist</span>
                                {userJoined === null && (
                                    <div className={styles.spinner}>
                                        <Spinner
                                            animation="border"
                                            variant="primary"
                                            style={{
                                                borderColor: "#2c2b5e",
                                                borderRightColor: "transparent",
                                            }}
                                        />
                                    </div>
                                )}
                            </animated.button>
                        </form>
                    )}

                    {userJoined && (
                        <div className={styles.successContainer}>
                            <div className={styles.top}>
                                <svg
                                    width="121"
                                    height="121"
                                    viewBox="0 0 121 121"
                                    fill="none"
                                    version="1.1"
                                    id="svg6"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M60.5151 26.9717C42.0243 26.9717 26.9719 42.0241 26.9719 60.5149C26.9719 79.0057 42.0263 94.0289 60.5151 94.0289C79.0039 94.0289 94.0291 79.0037 94.0291 60.5149C94.0291 42.0261 79.006 26.9717 60.5151 26.9717ZM60.5151 31.1255C76.7584 31.1255 89.8748 44.2701 89.8748 60.5144C89.8748 76.7597 76.7604 89.874 60.5151 89.874C44.2699 89.874 31.1263 76.7566 31.1263 60.5144C31.1263 44.2712 44.2729 31.1255 60.5151 31.1255Z"
                                        fill="#5AC7A8"
                                    />
                                    <animated.path
                                        style={checkSpring}
                                        stroke={"#5ac7a8"}
                                        strokeWidth={4}
                                        strokeLinecap={"round"}
                                        strokeLinejoin={"round"}
                                        strokeDasharray={46 * 2}
                                        paintOrder={"markers fill stroke"}
                                        d="M 44.564217,59.620254 55.719791,70.882958 76.366007,50.172875"
                                    />
                                </svg>
                                <div className={styles.text}>
                                    <span className={styles.welcome}>Welcome! </span>
                                    <span>You’ll receive an email with next steps soon.</span>
                                </div>
                            </div>
                            <div className={styles.bottom}>
                                <span className={styles.first}>👀 Want to jump the line?</span>
                                <span className={styles.second}>Take our quick questionnaire and you’ll get access sooner!</span>
                            </div>
                            <animated.button
                                type="submit"
                                style={jumpButtonSpring}
                                className={styles.dangerButton}
                                onClick={() => {
                                    window.location.href = "https://fss42vbbcu4.typeform.com/to/NseixZqe";
                                }}
                            >
                                Jump!
                            </animated.button>
                        </div>
                    )}
                    {/*</>*/}
                    {/*))}*/}

                    {/*<div className={styles["no-account"]}>*/}
                    {/*    Already taking part in the beta trial? &nbsp;*/}
                    {/*    <Link to={loginPagePathFormat}>Sign in here</Link>*/}
                    {/*</div>*/}

                    <div className={styles.messages}>
                        {!isMobile && (
                            <svg
                                className={styles.path}
                                width="487"
                                height="632"
                                viewBox="0 0 487 632"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M50.8167 45.0967C-84.1832 481.097 354.489 193.174 470.817 303.597C597.234 423.597 -155.683 356.597 32.3167 587.097"
                                    stroke="url(#paint0_linear_0_1)"
                                    strokeWidth="3"
                                    strokeDasharray="20 20"
                                />
                                <circle cx="60.3169" cy="13.0967" r="9" stroke="#D8E6F4" strokeWidth="8" />
                                <path d="M34.3169 610.923L60.9864 617.94" stroke="#356DFF" strokeWidth="8" strokeLinecap="round" />
                                <path d="M51.1602 601.097L44.1431 627.766" stroke="#356DFF" strokeWidth="8" strokeLinecap="round" />
                                <defs>
                                    <linearGradient
                                        id="paint0_linear_0_1"
                                        x1="243.606"
                                        y1="45.0967"
                                        x2="243.606"
                                        y2="587.097"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop stopColor="#D8E6F4" />
                                        <stop offset="1" stopColor="#356DFF" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        )}
                        {isMobile && (
                            <svg
                                className={styles.mobilePath}
                                width="507"
                                height="819"
                                viewBox="0 0 507 819"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M130.085 28C-77.4392 121.589 -5.60341 274.534 130.085 324.5C332.141 398.906 493.389 323.028 503.691 448.5C531.691 789.5 86.1912 555.5 119.135 770"
                                    stroke="url(#paint0_linear_0_1)"
                                    strokeWidth="3"
                                    strokeDasharray="20 20"
                                />
                                <circle cx="163.191" cy="13" r="9" stroke="#D8E6F4" strokeWidth="8" />
                                <path d="M113.191 797.826L139.861 804.843" stroke="#356DFF" strokeWidth="8" strokeLinecap="round" />
                                <path d="M130.034 788L123.017 814.669" stroke="#356DFF" strokeWidth="8" strokeLinecap="round" />
                                <defs>
                                    <linearGradient id="paint0_linear_0_1" x1="244.191" y1="28" x2="244.191" y2="805" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#D8E6F4" />
                                        <stop offset="1" stopColor="#356DFF" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        )}

                        <div className={styles.row} onMouseEnter={onMouseEnter(0)} onMouseLeave={onMouseLeave(0)} ref={ref1}>
                            <div className={styles.text}>
                                <animated.div className={styles.headline} style={{ fontWeight: imagePop[0].headlineFontWeight }}>
                                    Master the fundamentals
                                </animated.div>
                                <animated.div className={styles.description} style={{ fontWeight: imagePop[0].descriptionFontWeight }}>
                                    Whizz through the basics of micro, conquer the core <br /> of macro, or brush up on key econ’ terminology with
                                    <br /> fun, bite-sized courses.
                                </animated.div>
                            </div>
                            <animated.img style={{ scale: imagePop[0].scale }} src={sally2} alt={"caracter"} />
                        </div>
                        <div
                            className={classNames(styles.row, styles.right)}
                            onMouseEnter={onMouseEnter(1)}
                            onMouseLeave={onMouseLeave(1)}
                            ref={ref2}
                        >
                            <animated.img style={{ scale: imagePop[1].scale }} src={other13} alt={"insights"} />
                            <div className={styles.text}>
                                <animated.div className={styles.headline} style={{ fontWeight: imagePop[1].headlineFontWeight }}>
                                    Learn to apply the theory
                                </animated.div>
                                <animated.div className={styles.description} style={{ fontWeight: imagePop[1].descriptionFontWeight }}>
                                    Get into the habit of applying the theory to real-world issues with our game-like challenges and quizzes.
                                </animated.div>
                            </div>
                        </div>
                        <div className={classNames(styles.row, styles.last)} onMouseEnter={onMouseEnter(2)} onMouseLeave={onMouseLeave(2)} ref={ref3}>
                            <div className={styles.text}>
                                <animated.div className={styles.headline} style={{ fontWeight: imagePop[2].headlineFontWeight }}>
                                    See the world in a new way
                                </animated.div>
                                <animated.div className={styles.description} style={{ fontWeight: imagePop[2].descriptionFontWeight }}>
                                    Confused by current affairs? Baffled by humans behaving in <i>apparently </i> strange ways? Look at the <br />{" "}
                                    world through a fresh pair of eyes and see just how much economics explains.
                                </animated.div>
                            </div>

                            <animated.img style={{ scale: imagePop[2].scale }} src={sally22} alt={"caracter"} />
                        </div>
                    </div>
                </div>
            </div>
            <Footer small={false} />
        </div>
    );
};

// open pilot
// a hook that takes a list of references to div, and returns a number indicating how far from the center of the screen each div is
const useScroll = (refs: React.MutableRefObject<HTMLDivElement | null>[]) => {
    const [scroll, setScroll] = React.useState(0);
    React.useEffect(() => {
        const onScroll = () => {
            const center = window.innerHeight / 2;
            let min = Infinity;
            let minIndex = -1;
            for (let i = 0; i < refs.length; i++) {
                const ref = refs[i];
                if (ref.current) {
                    const rect = ref.current.getBoundingClientRect();
                    const dist = Math.abs(rect.top + rect.height / 2 - center);
                    if (dist < min) {
                        min = dist;
                        minIndex = i;
                    }
                }
            }
            setScroll(minIndex);
        };
        onScroll();
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [refs]);
    return scroll;
};

const useScrollLocation = () => {
    const [scrollLocation, setScrollLocation] = useState(0);
    const onScroll = useCallback(() => {
        const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
        const scrollTop = document.documentElement.scrollTop;
        setScrollLocation(scrollTop / scrollHeight);
    }, []);
    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [onScroll]);
    return scrollLocation;
};

// a hook that takes a ref to an element and returns the vertical location of the element on the screen, between 0 and 1
const useElementScrollLocation = (ref: React.RefObject<HTMLDivElement>) => {
    const [scrollLocation, setScrollLocation] = useState(0);
    const onScroll = useCallback(() => {
        if (ref.current) {
            const rect = ref.current.getBoundingClientRect();
            const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
            const scrollTop = document.documentElement.scrollTop;
            setScrollLocation(ref.current.clientTop / scrollHeight);
        }
    }, [ref]);
    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [onScroll]);
    return scrollLocation;
};
