import React from "react";
import { Button, Modal } from "react-bootstrap";

const AlertModal = (props: any) => {
    const handleAbort = () => {
        props.getResult(false);
    };
    const handleAccept = () => {
        props.getResult(true);
    };
    return (
        <>
            <Modal show={props.show}>
                <Modal.Header>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{props.question}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleAbort}>
                        {props.abort}
                    </Button>
                    <Button variant={props.type || "primary"} onClick={handleAccept}>
                        {props.accept}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AlertModal;
