import classnames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Publishers.module.scss";

function PubCard(props: any) {
    let navigate = useNavigate();

    const handlePubClick = () => {
        navigate(`/pubs/${props.id}`, { replace: false });
    };

    const handleFollowClick = (ev: any) => {
        ev.stopPropagation();
        props.handleFollowClick(props.id, !props.followed);
    };

    return (
        <div className={classnames(styles["card-item"])} onClick={handlePubClick}>
            <div className={styles["logo"]}>
                {" "}
                <img src={props.logo} width="100" height="auto" />
            </div>
            <div className={styles["title"]}> {props.name}</div>
            <div
                className={classnames(styles["follow"], {
                    [styles["following"]]: props.followed,
                })}
                onClick={handleFollowClick}
            >
                {" "}
                {props.followed ? "Following" : "Follow"}{" "}
            </div>
        </div>
    );
}

export default PubCard;
