import { generatePath, useParams } from "react-router-dom";

// userBadgesPath route

export const userBadgesPath = `/user/badges`;

// userBadgesPath route

export const userCoursesPath = `/user/courses`;

// joinWaitingList route

export const joinWaitingListPathFormat = `/join`;

// workspace route

export const workspacePathFormat = `/workspace`;

// review route

export const reviewPagePathFormat = `/review`;

// feed route

export const feedPagePathFormat = `/feed`;

// login route

export type LoginPageParams = {};

export const loginPagePathFormat = `/login`;

export function loginPagePath(params: LoginPageParams): string;
export function loginPagePath(params: Partial<LoginPageParams>): string | null;

export function loginPagePath({}: Partial<LoginPageParams>) {
    return generatePath(loginPagePathFormat, {});
}

export const useLoginPageParams = useParams<LoginPageParams>;
// login route

export type CoursePageParams = {
    courseId: string;
    blockId: string;
};

export const coursePathFormat = `/course/:courseId/`;
export const courseBlockPathFormat = `:blockId`;

export function coursePath(params: CoursePageParams): string;
export function coursePath(params: Partial<CoursePageParams>): string | null;

export function coursePath({ courseId, blockId }: Partial<CoursePageParams>) {
    if (courseId === undefined) {
        return null;
    }
    if (blockId === undefined) {
        return generatePath(coursePathFormat, { courseId });
    }
    return generatePath(coursePathFormat, { courseId }) + generatePath(courseBlockPathFormat, { blockId });
}

export const useCoursePageParams = useParams<CoursePageParams>;

// signUp route

export type SignUpPageParams = {};

export const signUpPagePathFormat = `/sign-up`;

export function signUpPagePath(params: SignUpPageParams): string;
export function signUpPagePath(params: Partial<SignUpPageParams>): string | null;

export function signUpPagePath({}: Partial<SignUpPageParams>) {
    return generatePath(signUpPagePathFormat, {});
}

export const useSignUpPageParams = useParams<SignUpPageParams>;

// landingPage route

export type LandingPageParams = {};

export const landingPagePathFormat = `/`;

export function landingPagePath(params: LandingPageParams): string;
export function landingPagePath(params: Partial<LandingPageParams>): string | null;

export function landingPagePath({}: Partial<LandingPageParams>) {
    return generatePath(landingPagePathFormat, {});
}

export const useLandingPageParams = useParams<LandingPageParams>;

// library page

export const libraryPagePathFormat = "/library";

// watchPage route

export type WatchPageParams = { mediaId: string };

export const watchPagePathFormat = `/watch/:mediaId`;

export function watchPagePath(params: WatchPageParams): string;
export function watchPagePath(params: Partial<WatchPageParams>): string | null;

export function watchPagePath({ mediaId }: Partial<WatchPageParams>) {
    if (!mediaId) {
        return null;
    }
    return generatePath(watchPagePathFormat, { mediaId });
}

export type QuizPageParams = { quizId: string };

export const useWatchPageParams = useParams<QuizPageParams>;

export const quizPagePathFormat = `/quiz/:quizId`;

export function quizPagePath(params: QuizPageParams): string;
export function quizPagePath(params: Partial<QuizPageParams>): string | null;

export function quizPagePath({ quizId }: Partial<QuizPageParams>) {
    if (!quizId) {
        return null;
    }
    return generatePath(quizPagePathFormat, { quizId });
}

export const useQuizPageParams = useParams<QuizPageParams>;

// embedded quiz route

export type EmbeddedQuizPageParams = {};

export const embeddedQuizPagePathFormat = `/embed/quiz/`;

// http://localhost:3000

export const publisherPagePathFormat = `/pubs/:publisherId`;

type PublisherPageParams = {
    publisherId: string;
};

export function publisherPagePath(params: PublisherPageParams): string;
export function publisherPagePath(params: Partial<PublisherPageParams>): string | null;

export function publisherPagePath({ publisherId }: Partial<PublisherPageParams>) {
    if (!publisherId) {
        return null;
    }
    return generatePath(publisherPagePathFormat, { publisherId });
}

export const usePublisherPageParams = useParams<PublisherPageParams>;
